import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Form, FormElement, Panel, DefaultButton, PrimaryButton
} from '../../../../components';

const DetailRetreats = ({
  t, documentType, document,
  person: {
    user, email, id
  },
  onCancel, onConfirm
}) => {
  return (
    <Panel headingText={t('confirmDataRetreats')}>
      <Form
        submitText={t('confirm')}
        secondButton={t('cancel')}
        onSubmit={(e) => onConfirm(e, id)}
        handleSecondButton={(e) => onCancel(e, id)}
        className={'form-elements'}
        >
        <FormElement
          name='documentType'
          value={documentType}
          label={t('documentType')}
          typeElement={'text'}
        />
        <FormElement
          name='document'
          label={t('document')}
          value={document}
          typeElement={'text'}
        />
        <FormElement
          name='nameLastName'
          label={t('nameLastName')}
          value={`${user}`}
          typeElement={'text'}
        />
        <FormElement
          name='email'
          label={t('email')}
          value={email}
          typeElement={'text'}
        />
      </Form>
    </Panel>
  )
}

export default withTranslation()(DetailRetreats);
