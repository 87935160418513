import React, { Component }    from 'react';
import { withTranslation }     from 'react-i18next';
import { connect }             from 'react-redux';
import { toast }               from 'react-toastify';
import PropTypes               from 'prop-types';
import { compose }             from 'redux';
import {
  AmountFormat,
  ContentHeader,
  Filter,
  Loading,
  Panel,
  PrimaryButton,
  TableList,
  TransactionRow
}                              from '../../components';
import { authActions }         from '../../state/ducks/auth';
import {
  initTimer,
  stopTimer
}                              from '../../util/initTimer';
import { usersActions }        from '../../state/ducks/users';
import { transactionsActions } from '../../state/ducks/transactions';
import { TOAST_CONFIG }        from '../../config/constants';
import './style.scss';
import ReactTooltip                    from "react-tooltip";

class ContactBook extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
    };
  }

  componentDidMount() {
    const { checkBalance } = this.props;
    checkBalance();
    stopTimer();
    initTimer(this.props);
    this.callApiList();
  }

  callApiList = () => {
    const { t, fetchContactBook, showMessage } = this.props;
    const { page } = this.state;

    fetchContactBook({
      data: {
        page,
      }, callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  deleteContact = (e, id_contact, id_person) => {
    e.preventDefault();
    const { t, showMessage, fetchDeleteContact } = this.props;
    fetchDeleteContact({
      data: {
        id_contact: id_contact,
        id_person: id_person,
      }, callback: (response) => {
        if (response) {
          if (!response.success) {
            toast.dismiss();
            showMessage({
              message: t('deleteContact'),
              config: TOAST_CONFIG.SUCCESS,
            });
            return false;
          }
        } else {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    })
    setTimeout(() => {
      window.location.reload();
    }, 2000)
  }

  render() {
    const {
      t, auth: { loading },
      users: { listContact: { list, totalElements } },
      transactions: { balanceData: { balance, currency } },
      handleReturn,
      handleInputChange,
    } = this.props;
    const { page } = this.state;
    return (
      <div>
        {loading && <Loading />}
        <Filter title={t('funcAdmin')} />
        <ContentHeader
          headingRightText={t('availableBalance')}
          headingRightSubText={
            <AmountFormat
              name='balance'
              value={balance}
              currency={currency}
              displayType='text'
            />
          }
        >
          <Panel headingText={t('contactBook')}>
            <TableList
              totalElements={parseFloat(totalElements)}
              onChangePage={this.onChangePage}
              forcePage={page}
              head={(
                <tr>
                  <th>{t('contact')}</th>
                  <th>{t('name')}/{t('lastName')}</th>
                  <th>{t('CUIT/CUIL')}</th>
                  <th> {t('accountDestiny')}</th>
                  <th>{t('CBU/CVU')}</th>
                  <th>{t('alias')}</th>
                  <th>{t('actions')}</th>
                </tr>
              )}
              colNumber={7}
              lengthData={totalElements !== undefined ? totalElements : 0}
            >
              {list && (list.map((contact, i) => (
                <TransactionRow key={i}>
                  <td>{contact.contact}</td>
                  <td>{`${contact.name} ${contact.lastname}`}</td>
                  <td>{contact.cuit}</td>
                  <td>{contact.cuenta_destino}</td>
                  <td>{contact.cbu !== null ? contact.cbu : contact.number_account}</td>
                  <td>{contact.alias}</td>
                  <td>
                    <div className='columns'>
                      <div className='column'
                        aria-hidden="true"
                        data-for={`select-${i}`}
                        data-tip="Test"
                        >
                        <i
                          className='fas fa-check-double add-contact'
                          onClick={() => {
                            const value = contact.cbu ?? contact.alias;
                            const validate = contact.cbu ? 'cbu' : 'alias';
                            handleInputChange({ target: { name: 'account', value } });
                            handleInputChange({ target: { name: 'validate', value: validate } });
                            handleReturn();
                          }}
                        />
                        <ReactTooltip
                          id={`select-${i}`}
                          effect="solid"
                          className='text-toltip'
                        >
                          {t('toSelect')}
                        </ReactTooltip>
                      </div>
                      <div className='column'
                        aria-hidden="true"
                        data-for={`delete-${i}`}
                        data-tip="Test"
                        >
                        <i
                          className='far fa-trash-alt delete-contact'
                          onClick={(e) => this.deleteContact(e, contact.id_contact, contact.id_person)}
                        />
                        <ReactTooltip
                          id={`delete-${i}`}
                          effect="solid"
                          className='text-toltip'
                        >
                          {t('toDelete')}
                        </ReactTooltip>
                      </div>
                    </div>
                  </td>
                </TransactionRow>
              )))}
            </TableList>
            <div className='has-text-centered'>
              <PrimaryButton name='Volver' onClick={handleReturn} />
            </div>
          </Panel>
        </ContentHeader>
      </div>
    );
  }
}

ContactBook.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  checkBalance: PropTypes.func.isRequired,
  fetchContactBook: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users, auth, transactions }) => ({
  users, auth, transactions
});

export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
    ...transactionsActions
  }),
  withTranslation(),
)(ContactBook);
