import React, { Fragment } from 'react';
import { Link }            from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { IMG }             from '../../../../config/constants';
import { Toggle }          from '../../../../components';
import { OptionLogout }    from '../index';
import './styles.scss';

const Dropdown = (props) => {
  const { t, name, logout, onClick } = props;
  return (
    <div className="Dropdown">
      <Toggle>
        {
          ({ toggledDrop, exitToggle, onToggledDrop, exitToggleMenu }) => (
            <Fragment>
              <div className="ocult-small">
                <nav className="navbar">
                  <div className="logoHeaderMin">
                    <Link
                      className="navbarLogo"
                      to="/dashboard"
                    />
                  </div>
                </nav>
              </div>
              <div className="ocult-big">
                <nav className="header">
                  <div className="rectangle">
                    <div className="containerLogo">
                      <div className="logoHeader">
                        <Link
                          className="navbarLogo"
                          to="/dashboard"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="containerHeader">
                    <div className="user verticalCenter">
                      <div className="avatar">
                        <li className="dropdown">

                            <img
                              src={IMG.avatar}
                              alt="Avatar minihboardfied"
                            />

                        </li>
                      </div>
                      <div className="profile">
                        <div className="welcome">{t('welcome')}</div>
                        <div className="username">{`${t('user')}: ${name}`}</div>
                      </div>
                    </div>
                    <div className="logout verticalCenter">
                      <div className="logout-arrow">
                        <li className="dropdown">
                          <button
                            tabIndex={-1}
                            className="dropdown-toggle text-decoration-none"
                            onClick={(event) => {
                              onClick(event);
                              exitToggle();
                              exitToggleMenu();
                              onToggledDrop();
                            }}
                            onBlur={() => toggledDrop && setTimeout(onToggledDrop, 500)}
                          >
                            <img
                              src={IMG.arrowDownOutline}
                              alt="Avatar minified"
                            />
                          </button>
                        </li>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
              <OptionLogout name={name} logout={logout} toggled={toggledDrop} tclass={'boxSetDrop'} />
            </Fragment>
          )
        }
      </Toggle>
    </div>
  );
};

export default withTranslation()(Dropdown);
