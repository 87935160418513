import { authActions, authTypes } from '../ducks/auth';
import { API_URL, HEADERS } from '../../config/constants';

const signIn = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.SIGN_IN) {
    return;
  }

  const { data } = action.payload;

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'pos/login',
        data,
      },
      authorization: false,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          dispatch(authActions.updateUser(response.data.data));
          dispatch(authActions.login());
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const changePassword = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.CHANGE_PASSWORD) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload || {};
  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'api/change-password',
        data,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        callback(response.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: authActions.endFetch,
    },
  });
};


const logout = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.LOGOUT) {
    return;
  }

  dispatch(authActions.clear());
};

const downloadQr = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.DOWNLOAD_QR) {
    return;
  }
  const { data, token, expToken } = action.payload || {};
  fetch(new Request(`${API_URL}api/generate-qr-pdf-web`, {
    method: 'POST',
    body: JSON.stringify(data),
    responseType: 'blob',
    headers: HEADERS({
      token,
      expToken,
      contentType: 'application/json; charset=UTF-8',
    }),
  })).then(response => response.blob()).then((blob) => {
    //https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
    const newBlob = new Blob([blob], { type: "application/pdf" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    const data = window.URL.createObjectURL(newBlob);
    window.open(data);
  }).catch(error => console.log(error));
};

export default [
    signIn,
  changePassword,
  logout,
  downloadQr,
];
