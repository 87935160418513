import React,  { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link} from 'react-router-dom';
import {
  Form, FormElement, Panel, AmountFormat, TableList, PrimaryButton
} from '../../../components';
import { initTimer, stopTimer } from '../../../util/initTimer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { transactionsActions } from '../../../state/ducks/transactions';
import { TOAST_CONFIG } from '../../../config/constants';

class AccountDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction: '',
    }
  }
  componentDidMount() {
    const { location } = this.props;
    stopTimer();
    initTimer(this.props);
    if (location !== undefined && location.state.transaction !== undefined) {
      this.setState({ transaction: location.state.transaction });
    }
  }
  render () {
    const { transaction } = this.state;
    const {t} = this.props;
    if (!transaction.detail) return null;
    return (
      <Fragment>
        <Panel headingText={t('detailtransaction')}>
          <TableList
            key={transaction.id}
            colNumber={1}
            lengthData={transaction.detail !== undefined ? transaction.detail.length : 0}
            >
              <tr>
                <th>{transaction.detail[0].text}</th>
                <td>
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {transaction.detail[0].value}
                  </div>
                </td>
              </tr>
              {transaction.transaction_type === 'Retiro de dinero' ?
                <tr>
                  <th>{transaction.detail[1].text}</th>
                  <td>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      {transaction.detail[1].value}
                    </div>
                  </td>
                </tr>
                :
                ''
              }
              <tr>
                <th>{`${t('typeOfTransaction')}:`}</th>
                <td>
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {transaction.transaction_type}
                  </div>
                </td>
              </tr>
              {transaction.transaction_type === 'Retiro de dinero'
              || transaction.transaction_type === 'Cobro con QR' ?
                ''
                :
                <tr>
                  <th>{`${t('documentPassengers')}:`}</th>
                  <td>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      {transaction.detail ? transaction.detail[6].value : null}
                    </div>
                  </td>
                </tr>
              }
              {transaction.transaction_type === 'Retiro de dinero'
              || transaction.transaction_type === 'Cobro con QR' ?
                  ''
                :
                <tr>
                  <th>{`${t('nameLastName')}:`}</th>
                  <td>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      {transaction.detail ? transaction.detail[7].value : null}
                    </div>
                  </td>
                </tr>
              }
              {transaction.transaction_type === 'Retiro de dinero'
              || transaction.transaction_type === 'Cobro con QR' ?
                  ''
                :
                <tr>
                  <th>{`${t('email')}:`}</th>
                  <td>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      {transaction.detail ? transaction.detail[8].value : null}
                    </div>
                  </td>
                </tr>
              }
              <tr>
                <th>{`${t('transactionCode')}:`}</th>
                <td>
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {transaction.detail ? transaction.detail[2].value : null}
                  </div>
                </td>
              </tr>
              <tr>
                <th>{`${t('amount')}:`}</th>
                <td>
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {transaction.detail ? transaction.detail[3].value : null}
                  </div>
                </td>
              </tr>
              {transaction.transaction_type === 'Retiro de dinero'
              || transaction.transaction_type === 'Cobro con QR' ?
                <tr>
                  <th>{transaction.detail[4].text}</th>
                  <td>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      {transaction.detail ? transaction.detail[4].value : null}
                    </div>
                  </td>
                </tr>
                :
                ''
              }
              <tr>
                <th>{`${t('status')}:`}</th>
                <td>
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {transaction.detail ? transaction.detail[5].value : null}
                  </div>
                </td>
              </tr>
            </TableList>
            <div className="center-table mb-20">
              <Link to='/account-list'>
                <PrimaryButton name={t('return')} />
              </Link>
            </div>
        </Panel>
      </Fragment>
    )
  }
}

AccountDetail.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchDetailTransactions: PropTypes.func.isRequired,
  accountDetail: PropTypes.func,
};

const mapStateToProps = ({ transactions, }) => (
    {
      transactions,
    }
);
export default compose(
    connect(mapStateToProps, {
      ...transactionsActions,
    }),
    withTranslation(),
)(AccountDetail);
