import React, { Component }                       from 'react';
import PropTypes                                  from 'prop-types';
import { compose }                                from 'redux';
import { connect }                                from 'react-redux';
import { withTranslation }                        from 'react-i18next';

import { transactionsActions }                    from '../../../state/ducks/transactions';
import { initTimer, stopTimer }                   from '../../../util/initTimer';
import { TOAST_CONFIG }                           from '../../../config/constants.js';

import {confirmAlert} from "react-confirm-alert";
import {authActions} from "../../../state/ducks/auth";
import {toast} from "react-toastify";
import './styles.scss';
import {
    Filter,
    Loading,
    FormElement,
    ContentHeader,
    TableList,
    TransactionRow,
    AmountFormat,
    ExportExcel
} from "../../../components";
import {SIZE_PER_PAGE} from "../../../config/constants";
import { Link }            from 'react-router-dom';
import { formatDate } from '../../../util';

class StateLotsList extends Component{
    constructor() {
        super();
        this.state = {
            stateLot: '',
            page: 0,
            dateStart: new Date(),
            dateEnd: new Date(((new Date()).setDate((new Date()).getDate() + 1))),
        }

        this.callApiList = this.callApiList.bind(this);
    }

    componentDidMount() {
        stopTimer();
        initTimer(this.props);

        const { fetchStateLots } = this.props;
        fetchStateLots();
        this.callApiList();
    }

    callApiList = (getExcel = false) => {
      const { stateLot, page, dateStart, dateEnd } = this.state;
      const {
        t, fetchLotsList, showMessage,
        transactions: { lots: { totalElements} },
      } = this.props;

      const data = {
        status: stateLot,
        pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
        page: getExcel ? 0 : page,
        export: getExcel,
        date_from: formatDate(dateStart),
        date_to: formatDate(dateEnd),
      }

      fetchLotsList({
        data,
        callback: (data) => {
          if(!data.success){
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        }
      });
    }

    onSubmit = (event) => {
      event.preventDefault();
      this.onChangePage();
    }

    onChangePage = (selectPage) => {

        stopTimer();
        initTimer(this.props);
        this.setState(() => ({
            page: selectPage ? selectPage.selected : 0,
        }), () => {
            this.callApiList();
        });
    };

    handleInputChange = (event) => {
      const {
        target: {
            value: targetValue, name,
        },
      } = event;
      this.setState({
        [name]: targetValue,
      });
    };

    onChangeDate = (date, name) => {
      stopTimer();
      initTimer(this.props);
      const dateValue = date !== null ? date : '';
      this.setState({
        [name]: dateValue,
      });
    };

    render() {
        const{ t, transactions: { loading, stateLots, lots: { list, totalElements} } } = this.props;
        const { stateLot, page, dateStart, dateEnd } = this.state;
        return (<div>
            {loading && <Loading />}
            <div className="wow animated slideInDown">
                <Filter
                    title={t('stateLots')}
                    onSubmit={(ev) => { this.onSubmit(ev); }}
                >
                  <FormElement
                      name="stateLot"
                      onChange={this.handleInputChange}
                      value={stateLot}
                      label={t('selectStateLot')}
                      optionsSelect={stateLots}
                      placeholder={t('all')}
                      typeElement="select"
                      controlClasses="select"
                  />
                  <FormElement
                    name='dateStart'
                    onChange={ev => this.onChangeDate(ev, 'dateStart')}
                    label={t('dateStart')}
                    value={dateStart}
                    typeElement="datepicker"
                    maxDate={dateEnd || new Date()}
                  />
                  <FormElement
                    name='dateEnd'
                    onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                    value={dateEnd}
                    label={t('dateEnd')}
                    typeElement="datepicker"
                    maxDate={new Date()}
                    minDate={dateStart}
                  />
                </Filter>
                <ContentHeader
                    headingRightText={t('totalElements')}
                    headingRightSubText={parseFloat(totalElements)}
                >
                  <ExportExcel onclick={() => { this.callApiList(true) }}/>
                    <TableList
                        tableClasses="floating-table"
                        totalElements={parseFloat(totalElements)}
                        onChangePage={this.onChangePage}
                        forcePage={page}
                        head={(
                          <tr>
                              <th>{t('dateTime')}</th>
                              <th>{t('numLot')}</th>
                              <th>{t('amountLot')}</th>
                              <th>{t('amountCommission')}</th>
                              <th> {t('status')}</th>
                              <th>{t('actions')}</th>
                          </tr>
                        )}
                        colNumber={6}
                        lengthData={parseFloat(totalElements) || 0}
                        >
                        {list && (list.map(row => (
                            <TransactionRow key={row.id}>
                                <td>{row.lot_date}</td>
                                <td>{row.lot_number === null ? `${t('noLot')}` : row.lot_number}</td>
                                <td>
                                  <AmountFormat
                                      name="balance"
                                      value={row.lot_balance}
                                      currency={'AR$'}
                                      displayType="text"
                                      allowNegative
                                  />
                                </td>
                                <td>
                                    <AmountFormat
                                        name="balance"
                                        value={row.lot_commission}
                                        currency={'AR$'}
                                        displayType="text"
                                        allowNegative
                                    />

                                </td>

                                <td>{row.status}</td>
                                <td>
                                    <Link
                                        to={{
                                            pathname: '/detail-lot',
                                            state: {
                                               lot: row.detail,
                                               urlFile: row.lot_file_url,
                                            },
                                        }}
                                    >
                                        <button className="button is-outlined is-small"> {t('seeDetail')}</button>

                                    </Link>
                                </td>
                            </TransactionRow>
                            ))
                        )}
                    </TableList>
                </ContentHeader>
            </div>
        </div>);
    }
}

StateLotsList.propTypes = {
    t: PropTypes.func.isRequired,
    fetchStateLots: PropTypes.func,
};

StateLotsList.defaultProps = {
    showMessage: () => {},
};

const mapStateToProps = ({  auth, transactions }) => ({
    auth,
    transactions,
});


export default compose(connect(mapStateToProps, {
    ...authActions,
    ...transactionsActions,
}), withTranslation())(StateLotsList);
