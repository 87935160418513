import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Panel, Form, FormElement, AmountFormat, DefaultButton, PrimaryButton
} from '../../../../components';
import './style.scss';

const DetailQr = ({
  t, amount, currency, onFinish,
}) => {
  return (
    <Panel headingText={t('paymentQr')}>
      <div className='has-text-centered	text-detail'>
        <div className='success-qr'>
          {t('successPaymentQr')}
          <div>
            <i className='fas fa-check-circle success-icon' />
          </div>
        </div>
        <div className='title-amount-qr'>{t('paymentReceived')}</div>
        <div className='amount-qr'>
          <AmountFormat
            name='amount'
            currency={currency}
            value={amount === '' ? '0' : amount}
            displayType='text'
          />
        </div>
        <div className='cancel-button'>
          <PrimaryButton
            name={t('finish')}
            icon=''
            onClick={e => onFinish(e)}
          />
        </div>
      </div>
    </Panel>
  );
}

export default withTranslation()(DetailQr);
