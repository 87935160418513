import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Switch} from 'react-router-dom';
import './styles.scss';
import {connect} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import {Aside, Dropdown} from './components';
import {authActions} from '../../state/ducks/auth';
import {RouteWithSubRoutes} from '../../config/routes';

const onClick = (event) => {
  const target = event.target.getBoundingClientRect();
  const boxElement = document.getElementsByClassName('boxSettings')[0];
  const y = target.top + 40;
  boxElement.style.right = `0px`;
  boxElement.style.top = `${y}px`;
};

const Layout = (props) => {
  const {
    auth, logout, location, routes,
  } = props;

  const {
    user: {name, phone, email, cvu, alias, user_type},
  } = auth;

  const disabled = user_type == 'agency_admin' ? false : true;

  return (
    <div className="Layout">
      <ToastContainer/>
      <div className="be-wrapper">
        <Dropdown
          logout={logout}
          name={name}
          cvu={cvu}
          alias={alias}
          onClick={onClick}
        />
        <Aside
          location={location}
          name={name}
          phone={phone}
          email={email}
          logout={logout}
          disabled={disabled}
        />
        <div className="be-content">
          <div className="main-content container is-fluid">
            <Switch>
              {routes.map(
                route => <RouteWithSubRoutes key={`${route.path}`} {...route} />,
              )}
              <Redirect to="dashboard"/>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  auth: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({auth}) => ({
  auth,
});

export default connect(mapStateToProps, authActions)(Layout);
