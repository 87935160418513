import types            from './types';
import { initialState } from '../../../App';

const transactions = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR: {
      return {
        ...state, ...initialState.transactions,
      };
    }
    case types.LOGIN: {
      return {
        ...state,
        logged: true,
      };
    }
    case types.LOGOUT: {
      return {
        ...state,
        logged: false,
      };
    }
    case types.CHANGE_LANGUAGE: {
      const { payload: { language } } = action;
      return {
        ...state,
        language,
      };
    }
    case types.UPDATE_PROFILE: {
      const { payload } = action;
      return {
        ...state,
        profile: {
          ...state.profile,
          ...(
            payload !== null ? payload : {}
          ),
        },
      };
    }
    case types.UPDATE_USER: {
      const { payload } = action;
      return {
        ...state,
        user: {
          ...state.user,
          ...(
            payload !== null ? payload : {}
          ),
        },
      };
    }
    case types.UPDATE_INFO_CASH: {
      const { data } = action.payload;
      return {
        ...state,
        cashclosure: data,
      }
    }
    case types.UPDATE_CLOSURE_CASH: {
      const { data } = action.payload;
      if (data) {
        return {
          ...state,
          approveClosureCash: data,
        }
      } else {
        return {
          ...state,
          ...initialState.transactions.approveClosureCash,
        }
      }

    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      return {
        ...state,
        loading: false,
      };
    }
    case types.UPDATE_BALANCE: {
      const { data: { currency, balance } } = action.payload;
      return {
        ...state,
        balanceData: {
          currency,
          balance,
        },
      }
    }
    case types.UPDATE_DOCUMENT_TYPE: {
      const { data } = action.payload;
      return {
        ...state,
        documentTypes: data,
      }
    }
    case types.UPDATE_VERIFY_PASSENGER: {
      const { data } = action.payload;
      return {
        ...state,
        rechargeBalancePerson: data,
      }
    }
    case types.UPDATE_RECHARGE_BALANCE: {
      const { data } = action.payload;
      if (data) {
        return {
          ...state,
          approveRechargeBalancePerson: data,
        }
      } else {
        return {
          ...state,
          ...initialState.transactions.approveRechargeBalancePerson,
        }
      }

    }
    case types.UPDATE_LIST_TRANSACTIONS: {
      const { data: { transactions, count_transactions } } = action.payload;
      return {
        ...state,
        accountDetail: {
          list: transactions,
          countTransactions: count_transactions,
        },
      }
    }
    case types.UPDATE_TRANSACTIONS_TYPES: {
      const { data } = action.payload;
      return {
        ...state,
        transactionsTypes: data
      }
    }
    case types.UPDATE_BANKS: {
      const { data } = action.payload;
      return {
        ...state,
        banks: data
      }
    }
    case types.UPDATE_UPLOAD_DEPOSIT: {
      const { data: { banks, numberTransaction, amountDeposit } } = action.payload;
      if (banks && numberTransaction && amountDeposit) {
        return {
          ...state,
          transaction: {
            banks,
            numberTransaction,
            amountDeposit
          },
        }
      } else {
        return {
          ...state,
          ...initialState.transactions,
        }
      }
    }
    case types.UPDATE_LIST_LOT_CLOSE: {
      const { data } = action.payload;
      const total = data.transactions.length;
      return {
        ...state,
        lotClosure: {
          list: data,
          totalElements: total,
        },
      }
    }
    case types.UPDATE_LOT_CLOSED: {
      const { data } = action.payload;
      return {
        ...state,
        lotClosed: data
      }
    }
    case types.UPDATE_LOT_LIST_CLOSED: {
      const { data: { lots, pagination: { totalCount, pageSize } } } = action.payload;
      return {
        ...state,
        listLotClosed: {
          listClosed: lots,
          totalCount: totalCount,
          pageSize: pageSize,
        },
      };
    }
    case types.UPDATE_TRANSACTIONS_BY_LOT: {
      const { data: { transactions, pagination: { totalCount } } } = action.payload;
      return {
        ...state,
        transactionsByLot: {
          list: transactions,
          totalElements: totalCount,
        },
      };
    }
    case types.UPDATE_ADMIN_LIST: {
      const { data: { count_transactions: countTransactions, transactions: list, dates } } = action.payload;
      return {
        ...state,
        accountDetail: {
          list,
          countTransactions,
          dates,
        },
      }
    }
    case types.UPDATE_STATE_LOTS: {
      const { data } = action.payload;
      return {
        ...state,
        stateLots: data
      }
    }
    case types.UPDATE_LOTS_LIST: {
      const { data: { lots, pagination: { totalCount } } } = action.payload;
      return {
        ...state,
        lots: {
          list: lots,
          totalElements: totalCount,
        }
      }
    }
    case types.UPDATE_TRANSACTIONS_TYPES_ACCOUNT: {
      const { data } = action.payload;
      return {
        ...state,
        transactionsTypes: data
      }
    }
    case types.GET_CHARGE_TRANSFER: {
      const { data: { amount, charge, total, rulesCharge, charge301 } } = action.payload;
      return {
        ...state,
        chargeTransfer: {
          amount, charge, total, rulesCharge, charge301,
        },
      };
    }
    case types.UPDATE_CHARGE_QR: {
      const { data: { amount, concept } } = action.payload;
      return {
        ...state,
        paymentQr: {
          amount, concept,
        },
      };
    }
    case types.UPDATE_VERIFY_EXTRACTION: {
      const { data } = action.payload;
      return {
        ...state,
        extraction: data,
      }
    }
    case types.UPDATE_CONFIRM_EXTRACTION: {
      const { data } = action.payload;
      return {
        ...state,
        voucher: data,
      }
    }
    default:
      return state;
  }
};

export default transactions;
