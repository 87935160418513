import React                        from 'react';
import { withTranslation }          from 'react-i18next';
import { Form, FormElement, Panel } from '../../../../components';

const FormBalanceCharge = ({ t, document, documentType, onSubmit, onChangeInput, documentTypes, disabled }) => {
  return (
    <Panel headingText={t('balanceChargePassengers')}>
      <Form
        submitText={t('continue')}
        onSubmit={(ev) => onSubmit(ev)}
        className={'form-elements'}
        disabled={disabled}
      >
        <FormElement
          name="documentType"
          onChange={ev => onChangeInput(ev)}
          value={documentType}
          label={t('documentType')}
          typeElement={'select'}
          optionsSelect={documentTypes}
          placeholder="Seleccione..."
          controlClasses="select"
        />
        <FormElement
          name='document'
          label={t('document')}
          value={document}
          onChange={ev => onChangeInput(ev)}
        />
      </Form>
    </Panel>
  );
}

export default withTranslation()(FormBalanceCharge);