import React, { Component, Fragment }                        from 'react';
import { compose }                                           from 'redux';
import { connect }                                           from 'react-redux';
import { withTranslation }                                   from 'react-i18next';
import PropTypes                                             from 'prop-types';
import { toast }                                             from 'react-toastify';
import { authActions }                                       from '../../state/ducks/auth';
import { initTimer, stopTimer }                              from '../../util/initTimer';
import {
  Form, Loading, Panel, TableList, Tabs, TextInput, PrimaryButton, QrCodeGenerate
} from '../../components';
import './style.scss';
import { TOAST_CONFIG }                                      from '../../config/constants';
import logo from '../../resources/images/assets/susa_logo.png';
import { Link } from 'react-router-dom';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      old_password: '',
      new_password_repeat: '',
      new_password: '',
      loading: false,
      isToggle: false,
      same: true,
      message: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showChangePassword = this.showChangePassword.bind(this);
    this.samePassword = this.samePassword.bind(this);
  }

  validateFields = () => {
    const {
      old_password, new_password_repeat, new_password,
    } = this.state;
    const empty = (old_password === '' || new_password_repeat === '' || new_password === '');
    const same_password = (new_password !== new_password_repeat);
    const diff_password = (old_password === new_password);
    return (empty || same_password || diff_password);
  };

  handleInputChange = (event) => {
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  onFinish = () => {
    this.setState(() => ({
      old_password: '',
      new_password_repeat: '',
      new_password: '',
      isToggle: false,
    }));
  };

  showChangePassword = () => {
    this.setState({ isToggle: !this.state.isToggle });
  };

  samePassword = () => {
    const {
      old_password, new_password_repeat, new_password,
    } = this.state;
    const empty = (old_password === '' || new_password_repeat === '' || new_password === '');
    const same_password = (new_password !== new_password_repeat);
    const diff_password = (old_password === new_password);
    if (empty) {
      this.setState({
        same: true,
        message: 'emptyFields',
      });
    } else if (same_password) {
      this.setState({
        same: true,
        message: 'samePassword',
      });
    } else if (diff_password) {
      this.setState({
        same: true,
        message: 'equalPassword',
      });
    } else {
      this.setState({
        same: false,
        message: '',
      });
    }
  };

  componentDidMount() {
    initTimer(this.props);
  }

  componentWillUnmount() {
    stopTimer();
  }

  handleSubmit(event) {
    event.preventDefault();
    const { changePassword, t, showMessage } = this.props;
    const { old_password, new_password } = this.state;
    const data = {
      current_password: old_password,
      new_password,
    };
    this.setState({
      loading: true,
    });
    changePassword({
      data,
      callback: (response) => {
        this.setState({
          loading: false,
        });
        this.onFinish();
        if (response.success) {
          toast.dismiss();
          showMessage({
            message: response.data.message,
            config: TOAST_CONFIG.SUCCESS,
          });
        } else if (!('success' in response)) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        } else if (!response.data.message) {
          response.data.message = response.data.message
            ? response.data.message
            : response.data.data.message;
          if (!response.data.message) {
            response.data.message = t('connectionError');
          }
        }
      },
    });
  }

  pdfPreview = () => {
    const canvas = document.getElementById('react-qrcode-logo');
    const pngUrl = canvas.toDataURL('image');
    const { auth: { user: { token }, expToken }, downloadQr } = this.props;
    const { auth } = this.props;
    const data = {
      'qr-image': pngUrl,
      'qr-owner': auth.user.agency.name,
    };
    downloadQr({
      data,
      token,
      expToken,
    });
  }

  render() {
    const { t, auth } = this.props;
    const dataQr = {
      name: auth.user.name,
      lastname: auth.user.lastname,
      email: auth.user.email,
      document: auth.user.document_number,
      type: auth.user.document_type,
      associate: auth.user.id_person,
    };
    // const dataQr = {
    //   name: auth.user.agency.person_name,
    //   lastname: auth.user.agency.person_lastname,
    //   email: auth.user.agency.email,
    //   document: auth.user.agency.cuit,
    //   type: auth.user.document_type,
    //   operator:  auth.user.id_person,
    //   associate: auth.user.agency.id_person,
    // };
    const disabled = this.validateFields();
    const {
      loading, message, old_password, new_password_repeat, new_password, same,
    } = this.state;
    const ref = React.createRef();
    const listTabs = [
      {
        title: 'userInformation',
        id: 't1',
        content: (
          <TableList lengthData={1}>

            <tr className="row-title">
              <td colSpan='2'>
                <div className="title-data">{t('personalData')}</div>
              </td>
            </tr>
            <tr className="row-data" key="personalData">
              <td><span className="title-bold">{t('nameLastName')}</span> {`${auth.user.name} ${auth.user.lastname}`}
              </td>
              <td><span className="title-bold">{t('numberDocument')}</span> {`${auth.user.document_number}`}</td>
            </tr>
            <tr className="row-data" key="personalData">
              {auth.user.user_type === 'agency_admin' ?
                ''
                :
                <td><span className="title-bold">{t('userType')}</span> {`${auth.user.role}`}</td>
              }
              <td><span
                className="title-bold">{t('phoneNumber')}</span> {`${auth.user.country_code} ${auth.user.phone_number}`}
              </td>
            </tr>

            <tr className="row-title">
              <td colSpan='2'>
                <div className="title-data">{t('accountData')}</div>
              </td>
            </tr>
            <tr className="row-data" key="accountData">
              <td><span className="title-bold">{t('email')}</span> {`${auth.user.email}`}</td>
              <td><span className="title-bold">IVA</span> -</td>
            </tr>
            <tr className="row-data" key="accountData">
              {auth.user.user_type === 'agency_admin' ?
                <td><span className="title-bold">{t('userType')}</span> {`${auth.user.role}`}</td>
                :
                ''
              }
              <td><span className="title-bold">{t('alias')}</span> {auth.user.agency.alias === null ? t('notAlias') : auth.user.agency.alias}</td>
            </tr>
            <tr>
              <td colSpan='2'><span className="title-bold">{t('CVU')}</span> {auth.user.agency.cvu}</td>
            </tr>

            <tr className="row-title">
              <td>
                <div className="title-data">{t('agencyData')}</div>
              </td>
            </tr>
            <tr className="row-data" key="nameAgency">
              <td colSpan='2'><span className="title-bold">{t('name')}</span>{`${auth.user.agency.name}`}</td>
            </tr>
            <tr key="addressAgency">
              <td colSpan='2'><span
                className="title-bold">{t('addressAgency')} </span> {`${auth.user.agency.address_agency}`}</td>
            </tr>
            <tr key="agencyData">
              <td><span
                className="title-bold">{t('legalRepresentative')}</span> {`${auth.user.agency.person_name} ${auth.user.agency.person_lastname}`}
              </td>
              <td><span className="title-bold">{t('cuit')}</span> {`${auth.user.agency.cuit}`}</td>
            </tr>
            <tr key="addressAgency">
              <td><span className="title-bold">{t('cityAgency')}</span> {`${auth.user.agency.city_agency}`}</td>
              <td><span className="title-bold">{t('province')}</span> {`${auth.user.agency.province_agency}`}</td>
            </tr>
          </TableList>
        ),
      },
      {
        title: 'changePassword',
        id: 't2',
        content: (
          <Fragment>
            <Form
              submitText={t('save')}
              onSubmit={(event) => {
                event.preventDefault();
                this.handleSubmit(event);
              }}
              disabled={disabled}
              className="column"
            >
              <TableList tableClasses="mb-0" lengthData={1}>
                <tr key="name">
                  <th className="detail-text">{t('password')}</th>
                  <td className="detail-value">
                    <TextInput
                      name="old_password"
                      placeholder="********"
                      type="password"
                      fieldClasses="pb-0 pt-0"
                      value={old_password}
                      onChange={this.handleInputChange}
                      onBlur={this.samePassword}
                    />
                  </td>
                </tr>
                <tr key="name">
                  <th className="detail-text">{t('newPassword')}</th>
                  <td className="detail-value">
                    <TextInput
                      name="new_password_repeat"
                      placeholder="********"
                      type="password"
                      fieldClasses="pb-0 pt-0"
                      value={new_password_repeat}
                      onChange={this.handleInputChange}
                      onBlur={this.samePassword}
                    />
                  </td>
                </tr>
                <tr key="name">
                  <th className="detail-text">{t('repeatPassword')}</th>
                  <td className="detail-value">
                    <TextInput
                      name="new_password"
                      placeholder="********"
                      type="password"
                      value={new_password}
                      fieldClasses="pb-0 pt-0"
                      onChange={this.handleInputChange}
                      onBlur={this.samePassword}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className={`has-text-danger is-small has-text-centered ${same ? 'is-block' : 'is-hidden'}`}>
                      {t(message)}
                    </div>
                  </td>
                </tr>
              </TableList>
            </Form>
          </Fragment>
        ),
      },
      {
        title: 'codeQr',
        id: 't3',
        content: (
          <Fragment>
            <div className='qr-pdf has-text-centered' id='pdf'>
              <QrCodeGenerate
                value={dataQr}
                level='H'
                size={300}
                bgColor="#FFFFFF"
                fgColor="#000000"
                logoImage={logo}
                logoWidth={50}
              />
            </div>
            <div className='download-qr has-text-centered'>
              <PrimaryButton classButton={`btn-qr`} name={t('downloadQr')} icon='' onClick={() => this.pdfPreview()} />
            </div>
          </Fragment>
        )
      }
    ];
    return (
      <Fragment>
        {loading && <Loading />}
        <Panel headingText={t('profileUser')}>
          <Tabs listTabs={listTabs} lenghtData={2} />
        </Panel>
      </Fragment>
    );
  }
}

Profile.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.shape({}).isRequired,
  downloadQr: PropTypes.func.isRequired,
};

const mapStateToProps = ({ transactions, auth }) => (
  {
    transactions,
    auth,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withTranslation(),
)(Profile);
