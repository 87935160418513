import types            from './types';
import { initialState } from '../../../App';

const users = (state = {}, action) => {
    switch (action.type) {
        case types.CLEAR: {
            return {
                ...state, ...initialState.users,
            };
        }
        case types.START_FETCH: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.END_FETCH: {
            const {error, payload} = action;
            return {
                ...state,
                loading: false,
                error: error ? payload : null,
            };
        }
        case types.SET_OPERATOR_AGENCY:{
            const { payload: { data } } = action;
            return {
                ...state,
                list_users_operator: {
                    list: data,
                    totalElements: data.length ,
                },
            };
        }
        case types.UPDATE_CONTACT_BOOK:{
          const { data: { contacts, count_contacts } } = action.payload;
          return {
            ...state,
            listContact: {
              list: contacts,
              totalElements: count_contacts.count,
            },
          };
        }
        case types.UPDATE_DELETE_CONTACT:{
          const { data: { contacts, count_contacts } } = action.payload;
          return {
            ...state,
            listContact: {
              list: contacts,
              totalElements: count_contacts.count,
            },
          };
        }
        default:
            return state;
    }
};

export default users;
