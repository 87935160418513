import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import './styles.scss';

class PrimaryButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wait: false,
    };
  }

  handleLoading(event) {
    const { onClick } = this.props;
    const { wait } = this.state;
    if (onClick !== '') onClick(event);
    if (!wait) {
      this.setState(() => ({
        wait: true,
      }));
    }
  }

  render() {
    const {
      disabled, name, type, classButton,
    } = this.props;
    const { wait } = this.state;
    const stateDisabled = wait || disabled;
    return (
      <button
        type={type}
        onClick={(event) => {
          this.handleLoading(event);
        }}
        className={`button-login ${classButton}`}
        disabled={disabled}
      >
        {name}
      </button>
    );
  }
}

PrimaryButton.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  classButton: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};
PrimaryButton.defaultProps = {
  type: 'button',
  onClick: () => {},
  disabled: false,
  classButton: '',
};

export default PrimaryButton;
