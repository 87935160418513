import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { initTimer, stopTimer } from '../../../util/initTimer';
import {
  AmountFormat, Filter, FormElement, ContentHeader, TableList, TransactionRow, Loading,
  ExportExcel
} from '../../../components';
import { transactionsActions } from '../../../state/ducks/transactions';
import { authActions } from '../../../state/ducks/auth';
import { TOAST_CONFIG, SIZE_PER_PAGE } from '../../../config/constants';
import { formatDate } from '../../../util';
import './style.scss';

class ListMovementsOperators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionType: '',
      page: 0,
      id: '',
      dateStart: new Date(),
      dateEnd: new Date(((new Date()).setDate((new Date()).getDate() + 1))),
    };
  }

  componentDidMount() {
    const { checkBalance, fetchTransactionsTypesAccount} = this.props;
    fetchTransactionsTypesAccount();
    checkBalance();
    stopTimer();
    initTimer(this.props);
    this.callApiList();
  }

  callApiList = (getExcel = false) => {
    const {
      t,
      fetchAdminList,
      showMessage,
      transactions: { accountDetail: { countTransactions }, transactionsTypes },
    } = this.props;
    const { id, transactionType, dateStart, dateEnd, page} = this.state;
    const type = transactionsTypes.find(item => item.value === transactionType);
    fetchAdminList({
      data: {
        pageSize: !getExcel ? SIZE_PER_PAGE : countTransactions,
        page: getExcel ? 0 : page,
        export: getExcel,
        id: id,
        typeTransaction: type!== undefined ? type.id : '',
        date_from: formatDate(dateStart),
        date_to: formatDate(dateEnd),
      }, callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  handleInputChange = (event) => {
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({
      [name]: dateValue,
    });
  };

  onSubmit = (e) => {
  e.preventDefault();
  this.onChangePage();
};

  render () {
    const {
      t, auth,
      transactions: {
        loading, transactionsTypes,
        balanceData: { balance, currency },
        accountDetail: { list, countTransactions },
      }
    } = this.props;
    const { transactionType, page, dateStart, dateEnd } = this.state;

    return (
      <div>
        {loading && <Loading />}
        <Fragment>
          <div className='content-amount'>
            <div className='columns'>
              <div className='column'>
                <div className='label-amount'>{t('alias')}</div>
                <b className='cvu-alias'>
                  {auth.user.agency.alias === null ? t('notAlias') : auth.user.agency.alias}
                </b>
              </div>
              <div className='column'>
                <div className='label-amount'>{t('operatingBalance')}</div>
                <b className='amount-format'>
                  <AmountFormat
                    name='balance'
                    value={balance}
                    currency={currency}
                    displayType='text'
                  />
                </b>
              </div>
              <div className='column'>
                <div className='label-amount'>{t('cvu')}</div>
                <b className='cvu-alias'>{auth.user.agency.cvu}</b>
              </div>
            </div>
          </div>
          <Filter onSubmit={(e) => this.onSubmit(e)}>
            <FormElement
              name='transactionType'
              onChange={this.handleInputChange}
              value={transactionType}
              label={t('selectTypeTransactions')}
              optionsSelect={transactionsTypes}
              placeholder={t('all')}
              typeElement='select'
              controlClasses='select'
            />
            <FormElement
              name='dateStart'
              onChange={ev => this.onChangeDate(ev, 'dateStart')}
              label={t('dateStart')}
              value={dateStart}
              typeElement="datepicker"
              maxDate={dateEnd || new Date()}
            />
            <FormElement
              name='dateEnd'
              onChange={ev => this.onChangeDate(ev, 'dateEnd')}
              value={dateEnd}
              label={t('dateEnd')}
              typeElement="datepicker"
              maxDate={new Date()}
              minDate={dateStart}
            />
          </Filter>
          <ContentHeader
            headingRightText='Total de operaciones '
            headingRightSubText={parseFloat(countTransactions)}
            >
            <ExportExcel onclick={() => { this.callApiList(true) }}/>
            <TableList
              tableClasses="floating-table"
              totalElements={parseFloat(countTransactions)}
              onChangePage={this.onChangePage}
              forcePage={page}
              head={(
                <tr>
                  <th>{t('date')} / {t('hour')}</th>
                  <th>{t('typeOfTransaction')}</th>
                  <th>{t('amount')}</th>
                  <th> {t('status')}</th>
                  <th>{t('actions')}</th>
                </tr>
              )}
              colNumber={5}
              lengthData={countTransactions || 0}
            >
              {list && (list.map(operation => (
                <TransactionRow key={operation.id}>
                  <td>{operation.transaction_date}</td>
                  <td>{operation.transaction_type}</td>
                  <td>
                    <AmountFormat
                      name='balance'
                      value={operation.transaction_amount}
                      currency={operation.product}
                      displayType='text'
                      allowNegative
                    />
                  </td>
                  <td>{operation.transaction_status}</td>
                  <td>
                    <Link to={{
                      pathname: '/detail-movements-operation',
                      state: { operation: operation }
                    }}
                    >
                      <button
                        className="button is-outlined is-small">{t('showTransacction')}</button>
                    </Link>
                  </td>
                </TransactionRow>
              )))
              }
            </TableList>
          </ContentHeader>
        </Fragment>
      </div>
    );
  }
}

ListMovementsOperators.propTypes = {
  t: PropTypes.func.isRequired,
  checkBalance: PropTypes.func.isRequired,
  fetchTransactionsTypesAccount: PropTypes.func,
  fetchAdminList: PropTypes.func.isRequired,
};

ListMovementsOperators.defaultProps = {
  showMessage: () => {
  },
  fetchTransactionsTypesAccount: () => {},
};

const mapStateToProps = ({ transactions, auth }) => ({
  transactions, auth
});

export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
    ...authActions,
  }),
  withTranslation(),
)(ListMovementsOperators);
