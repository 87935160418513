import { createAction } from 'redux-actions';
import types            from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const fetchOperatorAgency = createAction(types.FETCH_OPERATOR_AGENCY);
const setOperatorAgency = createAction(types.SET_OPERATOR_AGENCY);
const enableDisabledOperator = createAction(types.ENABLE_DISABLED_OPERATOR);
const fetchContactBook = createAction(types.FETCH_CONTACT_BOOK);
const updateContactBook = createAction(types.UPDATE_CONTACT_BOOK);
const fetchDeleteContact = createAction(types.FETCH_DELETE_CONTACT);
const updateDeleteContact = createAction(types.UPDATE_DELETE_CONTACT);

const showMessage = createAction(types.MESSAGE,
    ({ message }) => ({
        message,
    }),
    ({ config }) => ({
        config,
    }));

export default {
    startFetch,
    endFetch,
    endError,
    showMessage,
    fetchOperatorAgency,
    setOperatorAgency,
    enableDisabledOperator,
    fetchContactBook,
    updateContactBook,
    fetchDeleteContact,
    updateDeleteContact,
}
