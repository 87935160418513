import React from 'react';
import { withTranslation } from 'react-i18next';
import './styles.scss';
import PropTypes           from 'prop-types';

/**
 * Created by Feedback Software on 25/10/18.
 * @param t
 * @param headingText is the title of the panel
 * @param children contain the elements inside the panel body
 * @param headingSubText
 * @param headingRightText
 * @param headingRightSubText
 * @param classDashboard
 * @param classFirstColumn
 * @returns {*}
 * @constructor
 */
const ContentHeader = (
  {
    headingText, children, headingSubText, headingRightText, headingRightSubText, classDashboard = '', classFirstColumn
  },
) => {

  return (
    <div className="ContentHeader">
      <div className="columns is-centered " style={{ animationDelay: '1s', marginBottom: '0px' }}>
        <div className={`column is-full wow animated slideInDown ${classFirstColumn}`} style={{marginBottom: '0px'}}>
          <div className="columns is-marginless">
            <div className="column is-half is-left is-paddingless">
              <div className="title-header">
                {headingText && <span className="has-text-dark">{headingText}</span>}
                {headingSubText && <span className="pl-8px">{headingSubText}</span>}
              </div>
            </div>
            <div className="column is-half is-right is-paddingless is-flex content-right">
              <div className={`date-header ${classDashboard}`}>
                {headingRightText && <span className="has-text-dark">{headingRightText}</span>}
                {headingRightSubText && <span className="pl-8px">{headingRightSubText}</span>}
              </div>
            </div>
          </div>
          {/* <hr className="dots" /> */}
        </div>
      </div>
      <div className="columns is-centered" style={{ animationDelay: '1s'}}>
        <div className="column is-full super-position wow animated slideInDown">
          {children}
        </div>
      </div>
      <div className="columns is-centered " style={{ animationDelay: '1s' }}>
        <div className="column is-full wow animated slideInDown">
          <hr className="dots" />
        </div>
      </div>
    </div>
  );
};

ContentHeader.propTypes = {
  children: PropTypes.any.isRequired,
  headingText: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  headingSubText: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  headingRightText: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  headingRightSubText: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  classFirstColumn: PropTypes.string,
};

ContentHeader.defaultProps = {
  headingText: '',
  headingSubText: '',
  headingRightText: '',
  headingRightSubText: '',
  classFirstColumn: '',
};

export default withTranslation()(ContentHeader);
