import React, { Fragment } from 'react';
import PropTypes           from 'prop-types';
import { Link }            from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Menu                from './Menu';
import { Toggle }          from '../../../../components';
import { OptionLogout }    from '..';
import { IMG }             from '../../../../config/constants';
import './styles.scss';


const onClick2 = (event) => {
  const target = event.target.getBoundingClientRect();
  const boxElement = document.getElementsByClassName('boxSetAsi')[0];
  let y = 0;
  if (window.innerWidth > 768) {
    y = target.top + 40;
  } else {
    y = target.top + 50;
  }
  boxElement.style.top = `${y}px`;
};

const Aside = (props) => {
  const {
    t, location: { pathname }, name, logout, disabled
  } = props;
  const menu = [
    {
      url: '/balance-charge-passengers',
      icon: 'fas fa-money-check-alt',
      name: 'balanceChargePassengers',
    },
    {
      url: '/charge-qr',
      icon: 'fas fa-qrcode',
      name: 'chargeQr',
    },
    {
      url: '/retreats',
      icon: 'fas fa-coins',
      name: 'retreats',
    },
    {
      url: '/account-list',
      icon: 'fas fa-list',
      name: 'accountDetails',
    }, {
      url: '#',
      icon: 'fas fa-user-cog',
      name: 'funcAdmin',
      submenu: {
        items: [
          {
            id: 'sm4-1',
            url: '/cash-closure',
            name: 'titlecash',
            icon: 'fas fa-money-check-alt',
          },
          {
            id: 'sm4-2',
            url: '/admin-account-list',
            name: 'accountAdmin',
            icon: 'fas fa-user',
            disabled: disabled
          },
          {
            id: 'sm4-3',
            url: '/list-of-lots-unclosed',
            name: 'lotClosure',
            icon: 'fas fa-door-closed',
            disabled: disabled
          },
          {
            id: 'sm4-4',
            url: '/make-transfer',
            name: 'sendMoney',
            icon: 'fas fa-money-bill-wave',
            disabled: disabled
          },
          {
            id: 'sm4-5',
            url: '/deposit-register',
            name: 'depositRegister',
            icon: 'fas fa-money-check-alt',
            disabled: disabled,
          },
          {
            id: 'sm4-6',
            url: '/state-lots',
            name: 'stateLots',
            icon: 'fas fa-clipboard-list',
            disabled: disabled
          },
          {
            id: 'sm4-7',
            url: '/operators-agency',
            name: 'operatorAgency',
            icon: 'fas fa-users',
            disabled: disabled,
          },
        ]
      }
    },
  ];
  return (
    <div className="Aside">
      <Toggle>
        {({
            toggled, toggledMenu, onToggle, exitToggle, exitToggleDrop, onToggleMenu, exitToggleMenu,
          }) => (
          <Fragment>
            <div className="be-left-sidebar">
              <div className="left-sidebar-wrapper">
                <div className="left-sidebar-toggle">
                  <div className="user verticalCenter">
                    <div className="avatar">
                      <li className="dropdown">
                        <Link to="/profile">
                          <button tabIndex={-1} className="dropdown-toggle">
                            <img
                              src={IMG.avatar}
                              alt="Avatar minified"
                            />
                          </button>
                        </Link>
                      </li>
                    </div>
                    <div className="profile">
                      <div className="welcome">{t('welcome')}</div>
                      <div className="username">{`${t('user')}: ${name}`}</div>
                    </div>
                  </div>
                  <div className="menu verticalCenter alignCustom">
                    <span
                      role="presentation"
                      onClick={(event) => {
                        exitToggleMenu();
                        exitToggleDrop();
                        onToggle();
                      }}
                      className="presentation"
                    >
                      <i className="fas fa-bars" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="logout verticalCenter">
                    <div className="logout-arrow verticalCenter">
                      <li className="dropdown">
                        <button
                          tabIndex={-1}
                          className="dropdown-toggle text-decoration-none"
                          onClick={(event) => {
                            onClick2(event);
                            exitToggle();
                            exitToggleDrop();
                            onToggleMenu();
                          }}
                          onBlur={() => toggledMenu && setTimeout(onToggleMenu, 500)}
                        >
                          <img
                            src={IMG.arrowDownOutline}
                            alt="Avatar minified"
                          />
                        </button>
                      </li>
                    </div>
                  </div>
                </div>
                <div className={`${toggled ? 'show-up-down' : 'hide-down-up'}`}>
                  <ul className="sidebar-elements">
                    {menu.map((value, i) => (
                      <Menu
                        key={`menu-${i}`}
                        name={value.name}
                        icon={value.icon}
                        url={value.url}
                        pathname={pathname}
                        submenu={value.submenu}
                        disabled={value.disabled}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <OptionLogout t={t} name={name} logout={logout} toggled={toggledMenu} tclass="boxSetAsi" />
          </Fragment>
        )}
      </Toggle>
    </div>
  );
};

Aside.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default withTranslation()(Aside);
