import React, { Component }                       from 'react';
import PropTypes                                  from 'prop-types';
import { compose }                                from 'redux';
import { connect }                                from 'react-redux';
import { withTranslation }                        from 'react-i18next';
import { transactionsActions }                    from '../../../state/ducks/transactions';
import { initTimer, stopTimer }                   from '../../../util/initTimer';
import {authActions}                              from "../../../state/ducks/auth";
import './styles.scss';
import {
    AmountFormat, ContentHeader, DefaultButton, Filter,
    TableList, TransactionRow
} from "../../../components";
import { Route}                              from "react-router-dom";
import { TOAST_CONFIG } from '../../../config/constants';
import { toast } from 'react-toastify';

class DetailLot extends Component{
    constructor() {
        super();
        this.state = {
            lot: '',
            urlFile: '',
        }
    }

    componentDidMount() {
        stopTimer();
        initTimer(this.props);

        const {location } = this.props;

        if (location !== undefined && location.state !== undefined && location.state.lot !== undefined) {
            this.setState({ lot: location.state.lot, urlFile: location.state.urlFile });
        }
    }

    getPdf = (pdf) => {
      const {
        t, showMessage, fetchPdf,
      } = this.props;
      this.setState({ pdfFile: pdf })
      fetchPdf({
        data: {
          url_file: pdf,
        }, callback: (success) => {
          if (!success) {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        },
      });
    }


    render() {
        const {t } = this.props;
        const {urlFile, lot} = this.state;
        return (
            <div>
                <div className="wow animated slideInDown">
                    <Filter
                        title={t('stateLots')}
                        subtitle={t('detailLot')}
                    />

                    <ContentHeader
                        headingRightText={
                            <div>
                                {urlFile !== null && urlFile !== '' ?
                                <div className="content-button-voucher">
                                  <button
                                    className="button button-download button-voucher"
                                    onClick={() => this.getPdf(urlFile)}
                                    >
                                    {t('voucher')}
                                  </button>
                                </div>
                                :
                                <div className="text-voucher">
                                  {t('voucherNotAvailable')}
                                </div>
                                }

                                <div className="content-total">
                                    <span>{t('totalElements')}</span>
                                    <span className='text-total-value'>{`${lot.length}`}</span>
                                </div>
                            </div>

                        }
                       // headingRightSubText={`${lot.length}`}
                        headingText={
                            <Route render={({ history}) => (
                                <DefaultButton name={t('goBack')} onClick={() => { history.push('/state-lots') }} />
                            )} />
                        }
                    >

                        <TableList
                            tableClasses="floating-table"
                            totalElements={lot.length}
                            onChangePage={this.onChangePage}
                            hidePagination
                            head={(
                                <tr>
                                    <th>{t('numBox')}</th>
                                    <th>{t('dateOpen')}</th>
                                    <th>{t('dateClose')}</th>
                                    <th>{t('numOperations')}</th>
                                    <th>{t('amount')}</th>
                                </tr>
                            )}
                            colNumber={5}
                            lengthData={lot.length || 0}
                        >
                            {lot && (lot.map(row => (
                                    <TransactionRow>
                                        <td>{row.cash_number}</td>
                                        <td>{row.open_date}</td>
                                        <td>{row.close_date}</td>
                                        <td>{row.operations}</td>
                                        <td><AmountFormat
                                            name="balance"
                                            value={row.close_amount}
                                            currency={'AR$'}
                                            displayType="text"
                                            allowNegative
                                        />
                                        </td>
                                    </TransactionRow>
                                ))
                            )}
                        </TableList>
                    </ContentHeader>
                </div>

            </div>
        );
    }
}

DetailLot.propTypes = {
    t: PropTypes.func.isRequired,

};

DetailLot.defaultProps = {
    showMessage: () => {},
};

const mapStateToProps = ({  auth, transactions }) => ({
    auth,
    transactions,
});


export default compose(connect(mapStateToProps, {
    ...authActions,
    ...transactionsActions,
}), withTranslation())(DetailLot);
