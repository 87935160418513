import React               from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes           from 'prop-types';
import { Form, Panel }     from '../../../../components';
import FormElement         from '../../../../components/FormElement';


const TransferForm = (props) => {
  const {
    t,
    disabled,
    formData,
    handleSubmit,
    handleInputChange,
    validateField,
    currency,
    account,
    amount,
    concept,
    showDirectory,
  } = props;
  return (
    <div className="columns individual-transfer">
      <div className="column is-full">
        <Panel titleContentHeader={t('makeNewTransfer')} headingText={t('sendCvuCbuOrAlias')}>
          <Form
            submitText={t('continue')}
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit(event, amount, formData);
            }}
            disabled={disabled}
          >
            <div className="columns is-centered content-form">
              <div className="column is-4">
                <FormElement
                  name="account"
                  value={account}
                  label={t('recipient')}
                  onChange={handleInputChange}
                  validateField={validateField}
                  controlClasses="input-custom"
                  helper={t('enterCbuCvuUser')}
                  typeElement={'cbuFormat'}
                  information={t('enterCbuCvuUser')}
                />
              </div>
              <div className="column is-4">
                <FormElement
                  value={amount}
                  name="amount"
                  label={`${t('amount')} ${currency}`}
                  onChange={handleInputChange}
                  controlClasses="input-custom"
                  inputClasses="text-right"
                  typeElement="amountFormat"
                />
              </div>
              <div className="column is-4">
                <FormElement
                  name="concept"
                  value={concept}
                  label={t('concept')}
                  placeholder=""
                  onChange={handleInputChange}
                  typeElement="input"
                  controlClasses="input-custom"
                />
              </div>

            </div>
            <div
              className="findRecipients"
              onClick={showDirectory}>
              <div className="findRecipient is-one-third">
                <i className="fa fa-search" />
                &nbsp;
                <span>{t('findRecipient')}</span>
              </div>
            </div>
          </Form>
        </Panel>
      </div>
    </div>
  );
};


TransferForm.propTypes = {
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  formData: PropTypes.shape({}).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showDirectory: PropTypes.func.isRequired,
  validateField: PropTypes.func,
  currency: PropTypes.string,
  account: PropTypes.string,
  amount: PropTypes.string,
  concept: PropTypes.string,
};

TransferForm.defaultProps = {
  disabled: false,
  validateField: () => {},
  currency: '',
  account: '',
  amount: '',
  concept: '',
};


export default withTranslation()(TransferForm);
