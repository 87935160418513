import React, { Component }              from 'react';
import PropTypes                         from 'prop-types';
import { compose }                       from 'redux';
import { connect }                       from 'react-redux';
import { withTranslation }               from 'react-i18next';
import { Link }                          from 'react-router-dom';
import { toast }                         from 'react-toastify';
import { Loading, Panel, PrimaryButton, DefaultButton } from '../../components';
import { transactionsActions }           from '../../state/ducks/transactions';
import { authActions }                   from '../../state/ducks/auth';
import { TransferDetail, TransferForm }  from './components';
import { TOAST_CONFIG }                  from '../../config/constants';
import './styles.scss';
import { initTimer, stopTimer }          from '../../util/initTimer';
import { confirmAlert }                  from "react-confirm-alert";
import { ContactBook }                   from "../index";
import { usersActions }                  from "../../state/ducks/users";

class Transfer extends Component {
  constructor(props) {
    super(props);
    const { t, transactions: { currency } } = props;
    this.state = {
      loading: false,
      product: currency,
      ownerName: '',
      ownerLastName: '',
      account: '',
      amount: '',
      currency: '',
      step: 'first',
      concept: '',
      validate: null,
      cbu: '',
      document: '',
      type_document: '',
      type_account: '',
      alias: '',
      bind_success: '1',
      bind_message: '',
      bank_sender: 'SUSA',
      bank_sender_code: 919,
      bank_receiver: '',
      bank_receiver_code: '',
      page: 0,
      contacts: [],
      contact: null,
      isToggle: false,
      detailTransfer: '',
      disable: false,
    };

    this.stepFirst = this.stepFirst.bind(this);
    this.stepSecond = this.stepSecond.bind(this);
  }

  componentDidMount() {
    const { validateTimeTransfer } = this.props;
    const { disable } = this.state;
    validateTimeTransfer({
      callback: (response) => {
        if (!response.data.value || disable === true) {
          return this.showMessageTransfer(this.message());
        }
      }
    });
    initTimer(this.props);
  }

  componentWillUnmount() {
    stopTimer();
  }

  handleInputChange = (event) => {
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    });

  };

  showMessageTransfer = (message) => {
    const { t } = this.props;
    const options = {
      customUI: () => (
        <div className="information-alert message columns is-multiline">
          <div className="column is-full has-text-centered text-message">
            {message}
          </div>
          <div className="column is-full has-text-centered">
            <button
              className="button is-outlined"
              onClick={() => window.location.href = '/dashboard'}
            >
              {t('accept')}
            </button>
          </div>
        </div>
      ),
      closeOnEscape: false,
      closeOnClickOutside: false,
    };
    return (confirmAlert(options));
  }

  message = () => {
    const { t } = this.props;
    const { disable } = this.state;
    if (disable === true) {
      return(
        <div className='has-text-centered mt-16px'>
          <span>
            <i className='fas fa-chart-line has-text-success' /> {t('comingSoon')} <i className='fas fa-chart-line has-text-success' />
          </span>
        </div>
      )
    } else {
      return (
        <div className='has-text-centered mt-16px'>
          <span>
            <i className='fas fa-exclamation-triangle has-text-danger' /> {t('limitToTransfer')}
          </span>
        </div>
      )
    }
  };

  validateEmptyFields = (checkOutside = null) => {
    if (checkOutside !== null) {
      this.setState({
        validate: checkOutside,
      });
    }
    const {
      account, amount, concept, validate,
    } = this.state;
    return (amount === '' || account === '' || concept === '' || validate === null);
  };

  onSubmit = (event, total) => {
    event.preventDefault();
    const { ...data } = this.state;
    const { step, amount } = this.state;
    const { showMessage, validateAmountLimits } = this.props;
    if (step === 'first') {
      validateAmountLimits({
        data: {
          amount: parseFloat(amount.toString().replace(/\./g, '').replace(',', '.')),
        }, callback: (response) => {
          if (response.data.valid === false) {
            toast.dismiss();
            showMessage({
              message: response.data.message,
              config: TOAST_CONFIG.INFO,
            });
          } else if (response.data) {
            this.stepFirst({
              data,
              total,
            });
          }
        },
      });
    } else if (step === 'second') {
      this.stepSecond({
        data,
        total,
      });
    }
    toast.dismiss();
  };

  onFinish = () => {
    const { checkBalance } = this.props;
    this.setState(() => ({
      ownerName: '',
      ownerLastName: '',
      account: '',
      amount: '',
      currency: '',
      step: 'first',
      concept: '',
      validate: null,
      cbu: '',
      document: '',
      type_document: '',
      type_account: '',
      bank_sender: 'SUSA', //validar el nombre que va tener
      bank_sender_code: 919, //validar el code del banco de susa
      bank_receiver: '',
      bank_receiver_code: '',
    }));
    checkBalance();
  };

  handleReturn = (step) => {
    this.setState(() => ({
      step,
    }));
  };

  showDirectory = () => {

    this.setState(() => ({
      step: 'showDirectory',
    }));

  };

  async stepFirst({ data, total }) {
    const {
      t, showMessage, checkCbu, transactions: { balanceData: { balance } }, auth: { user: { id_person } },
      checkChargeTransfer, validateAmountLimits
    } = this.props;
    const { amount, validate } = this.state;
    if (parseFloat(amount.replace('.', '').replace(',', '.')) <= parseFloat(balance)) {
      this.setState(() => ({
        loading: true,
      }));
      const dataSend = {
        type_search: validate,
        alias: validate === 'alias' ? data.account : '',
        cbu: validate === 'cbu' ? data.account : '',
      };

      checkChargeTransfer({
        data: {
          id_person,
          amount: parseFloat(amount.toString()
            .replace(/\./g, '')
            .replace(',', '.')),
          alias: dataSend.alias,
          cbu: dataSend.cbu,
        },
        callback: (response) => {
          if (response.success) {
            checkCbu({
              data: dataSend,
              callback: (response) => {
                this.setState(() => ({
                  loading: false,
                  contact: null,
                  isToggle: false,
                }));
                if (response.success) {
                  if (response.data.account_receiver !== null) {
                    if (Array.isArray(response.data.account_receiver.owners)) {
                      const { display_name } = response.data.account_receiver.owners[0];
                      let arrayDeCadenas;
                      if (display_name.indexOf(',') > -1) {
                        arrayDeCadenas = display_name.split(',');
                        this.setState(() => ({
                          ownerName: `${arrayDeCadenas[1].trim()}`,
                          ownerLastName: arrayDeCadenas[0] === undefined
                            ? ''
                            : arrayDeCadenas[0].trim(),
                          document: response.data.account_receiver.owners[0].id.trim(),
                          type_document: response.data.account_receiver.owners[0].id_type.trim(),
                        }));
                      } else {
                        this.setState(() => ({
                          ownerName: `${display_name.trim()}`,
                          ownerLastName: '',
                          document: response.data.account_receiver.owners[0].id.trim(),
                          type_document: response.data.account_receiver.owners[0].id_type.trim(),
                        }));
                      }
                    } else {
                      const { display_name } = response.data.account_receiver.owners;
                      let arrayDeCadenas;
                      if (display_name.indexOf(',') > -1) {
                        arrayDeCadenas = display_name.split(', ');
                        this.setState(() => ({
                          ownerName: `${arrayDeCadenas[1].trim()}`,
                          ownerLastName: arrayDeCadenas[0] === undefined
                            ? ''
                            : arrayDeCadenas[0].trim(),
                          document: response.data.account_receiver.owners.id.trim(),
                          type_document: response.data.account_receiver.owners.id_type.trim(),
                        }));
                      } else {
                        this.setState(() => ({
                          ownerName: `${display_name.trim()}`,
                          ownerLastName: '',
                          document: response.data.account_receiver.owners.id.trim(),
                          type_document: response.data.account_receiver.owners.id_type.trim(),
                        }));
                      }
                    }
                    const { currency } = response.data.account_receiver;
                    this.setState(() => ({
                      currency: currency === 'ARS' ? 'AR$' : currency,
                      cbu: response.data.account_receiver.account_routing.address,
                      type_account: validate,
                      alias: response.data.account_receiver.label,
                      step: 'second',
                      bank_receiver: response.data.account_receiver.bank_routing.address,
                      bank_receiver_code: response.data.account_receiver.bank_routing.code,
                    }));
                  } else {
                    toast.dismiss();
                    showMessage({
                      message: t('invalidCbu'),
                      config: TOAST_CONFIG.ERROR,
                    });
                  }
                } else if (!('success' in response)) {
                  toast.dismiss();
                  showMessage({
                    message: t('connectionError'),
                    config: TOAST_CONFIG.ERROR,
                  });
                }
              },
            });
          }
        },
      });
    } else {
      toast.dismiss();
      showMessage({
        message: t('insufficientFunds'),
        config: TOAST_CONFIG.ERROR,
      });
    }
  }

  handleClickShowHidden = (data, e) => {
    this.setState(() => ({
      isToggle: !this.state.isToggle,
      contact: null,
    }));
  }

  stepSecond = ({ data, total }) => {
    const {
      t, showMessage, saveTransaction, transactions: { balance }, checkBalance, auth: {
        user: {
          role, id_role, enable_group_signature, id_person, responsible: { id_person_company, id },
        },
      },
    } = this.props;
    const {
      ownerName, ownerLastName, cbu, currency, document, account, amount, concept, type_document, type_account, alias, bank_receiver,
      bank_receiver_code, bank_sender, bank_sender_code, contact,
    } = this.state;
    const transfer_pending = (id_role === 3 && enable_group_signature) || (id_role === 5);
    const totalAmount = parseFloat(total).toString().replace(/\./g, '').replace(',', '.');
    const balanceAmount = parseFloat(balance).toString().replace(/\./g, '').replace(',', '.');
    if ( totalAmount <= balanceAmount) {
      this.setState(() => ({
        loading: true,
      }));
      const amountFormat = amount.replace(/\./g, '')
        .replace(/,/g, '.');
      const sendData = {
        name: ownerName,
        lastname: ownerLastName,
        document,
        type: type_document,
        type_search: type_account,
        account,
        transaction_type: 'transfer',
        amount: amountFormat,
        product: currency,
        detail: concept,
        transfer_pending,
        id_company: id_person_company,
        alias,
        cbu,
        id_person: id,
        bank_receiver,
        bank_receiver_code,
        bank_sender,
        bank_sender_code,
      };
      if (contact !== null && contact !== '') {
        sendData.contact = contact;
      }
      saveTransaction({
        data: sendData,
        callback: (response) => {
          checkBalance({
            callback: (response) => {
              this.setState(() => ({
                loading: false,
              }));
            },
          });
          if (response.success) {
            this.setState(() => ({
              codeTransaction: response.data.transaction_business.transaction_code,
              bind_success: response.data.transaction_business.bind_success,
              bind_message: response.data.transaction_business.bind_message,
              step: 'third',
            }));
          } else if (!('success' in response)) {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          } else if (!response.data.message) {
            response.data.message = response.data.transaction_business.message
              ? response.data.transaction_business.message
              : response.data.transaction_business.data.message;
            if (!response.data.message) {
              response.data.message = t('connectionError');
            }
          }
        },
      });
    } else {
      toast.dismiss();
      showMessage({
        message: t('invalidAmount'),
        config: TOAST_CONFIG.ERROR,
      });
    }
  }

  render() {
    const { t, transactions: { chargeTransfer } } = this.props;
    const {
      account, concept, amount, detailTransfer, step, loading,
    } = this.state;
    const disabledForm = this.validateEmptyFields();
    if (detailTransfer) {
      return (
        <TransferDetail
          detailTransfer={detailTransfer}
          onFinish={this.onFinish}
          chargeTransfer={chargeTransfer}
        />
      );
    }
    if (step === 'first') {
      stopTimer();
      initTimer(this.props);
      return (
        <section>
          {loading && <Loading />}
          <TransferForm
            formData={{ ...this.state }}
            disabled={disabledForm}
            handleInputChange={this.handleInputChange}
            handleSubmit={this.onSubmit}
            validateField={this.validateEmptyFields}
            currency={'AR$'}
            account={account}
            amount={amount}
            concept={concept}
            showDirectory={this.showDirectory}
          />
          {this.message()}
        </section>
      );
    }
    if (step === 'second') {
      stopTimer();
      initTimer(this.props);
      const {
        ownerName, ownerLastName, currency, contact, isToggle,
      } = this.state;
      const { t, users: { listContact: { list: contacts } } } = this.props;
      let name = ownerName !== 'undefined' ? ownerName : '';
      const lastName = ownerLastName !== 'undefined' ? ownerLastName : '';
      if (name === '' && lastName === '') {
        name = t('noBeneficiary');
      }
      return (
        <section>
          {loading && <Loading />}
          <Panel
            titleContentHeader=""
            headingText={t('confirmInformation')}
          >
            <TransferDetail
              currency={currency}
              fullName={`${name} ${lastName}`}
              amount={amount}
              concept={concept}
              account={account}
              successfull={false}
              contact={contact}
              handleInputChange={this.handleInputChange}
              handleClickShowHidden={this.handleClickShowHidden.bind(this, isToggle)}
              isToggle={isToggle}
              contactExist={contacts.find(contactExist => contactExist.number_account === account)}
              chargeTransfer={chargeTransfer}
            />
            <div className="field is-grouped is-grouped-centered">
              <p className="control m-8px">
                <DefaultButton
                  name={t('return')}
                  type="button"
                  className="button is-custom-default"
                  onClick={() => {
                    this.handleReturn('first');
                  }}
                />
              </p>
              <div className="control">
                <PrimaryButton
                  name={t('confirm')}
                  onClick={(event) => {
                    this.onSubmit(event, amount);
                  }}
                />
              </div>
            </div>
          </Panel>
          <div style={{marginTop: 20}}>{this.message()}</div>
        </section>
      );
    }
    if (step === 'third') {
      stopTimer();
      initTimer(this.props);
      const {
        ownerName, ownerLastName, currency, codeTransaction, bind_success,
      } = this.state;
      let name = ownerName !== 'undefined' ? ownerName : '';
      const lastName = ownerLastName !== 'undefined' ? ownerLastName : '';
      if (name === '' && lastName === '') {
        name = t('noBeneficiary');
      }
      let { className, textInformation, icon } = '';
      if (!bind_success) {
        className = 'text-warning';
        textInformation = 'transactionNeedsApproved';
        icon = 'fas fa-info-circle';
      } else if (bind_success === 1 || bind_success === 11 || bind_success === 13) {
        className = 'sms-success';
        textInformation = 'successfullOperation';
        icon = 'far fa-check-circle';
      } else {
        className = 'failed';
        textInformation = 'failedOperation';
        icon = 'far fa-times-circle';
      }
      return (
        <section>
          {loading && <Loading />}
          <Panel titleContentHeader="" headingText="">
            <h2 className={className}>
              {t(textInformation)}
              {' '}
              <i className={icon} />
            </h2>
            <TransferDetail
              currency={currency}
              fullName={`${name} ${lastName}`}
              amount={amount}
              concept={concept}
              account={account}
              codeTransaction={codeTransaction}
              chargeTransfer={chargeTransfer}
              successfull
            />
            <div className="field is-grouped is-grouped-centered">
              <p className="control">
                <Link to={{
                  pathname: '/admin-account-list',
                  // state: { operation: {...this.state} }
                }}
                >
                  <PrimaryButton name={t('close')} />
                </Link>
              </p>
            </div>
          </Panel>
          <div style={{marginTop: 20}}>{this.message()}</div>
        </section>
      );
    }
    if (step === 'showDirectory') {
      return (
        <section>
          {loading && <Loading />}
          <ContactBook
            handleReturn={() => this.handleReturn('first')}
            handleInputChange={this.handleInputChange}
          />
          {this.message()}
        </section>
      );
    }
  }
}

Transfer.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.shape({}).isRequired,
  transactions: PropTypes.shape({}).isRequired,
};


const mapStateToProps = ({ users, transactions, auth }) => (
  {
    transactions,
    auth,
    users
  }
);

export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
    ...authActions,
    ...usersActions,
  }), withTranslation())(Transfer);
