/*const evaluateAgeFromDateBirthdate = (date) => {
  console.log(date);
  let fecha = date.format('YYYY-MM-DD')
    .split('-');
  let dia = fecha[2];
  let mes = fecha[1];
  let ano = fecha[0];
  let fecha_hoy = new Date();
  let ahora_ano = fecha_hoy.getYear();
  let ahora_mes = fecha_hoy.getMonth() + 1;
  let ahora_dia = fecha_hoy.getDate();
  let edad = (
    ahora_ano + 1900
  ) - ano;
  if (ahora_mes < mes) {
    edad--;
  }
  if ((
    mes == ahora_mes
  ) && (
    ahora_dia < dia
  )) {
    edad--;
  }
  if (edad > 1900) {
    edad -= 1900;
  }
  console.log(edad, MAX_AGE);
  return edad >= MAX_AGE;
};*/

export const formatDate = (date) => {
  if (date != undefined && date !== '')
    return `${date.getFullYear()}-${getMonth(date.getMonth())}-${putZero(date.getDate())}`;
  return date;
};

export const putZero = (number) => {
  return number < 10 ? `0${number}` : `${number}`;
};

export const getMonth = (month) => {
  const result = month + 1;

  return putZero(result);
};

export const convertDecimalPoint = (amount) => {
  if (typeof amount !== 'string') {
    return amount;
  }
  return amount.replace(/[,a-zA-Z \$]/gi, '').replace('.', ',');
};

export const formatThousand = (number) => {
  const arrayNum = number.toString().split('.');
  const integer = arrayNum[0];
  const decimal = typeof arrayNum[1] !== 'undefined' ? arrayNum[1].substr(0, 2) : '00';
  let num = integer.toString().replace(/\./g, '');
  if (!isNaN(num)) {
    num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
    num = num.split('').reverse().join('').replace(/^[\.]/, '');
  }
  return `${num},${decimal}`;
};
