import React                                  from 'react';
import { withTranslation }                    from 'react-i18next';
import PropTypes                              from 'prop-types';
import { AmountFormat, TableList, TextInput } from '../../../../components';
import './styles.scss';
import TotalTransaction                       from '../../../../components/TotalTransaction';
import { formatThousand }                     from '../../../../util';

const TransferDetail = (props) => {
  const {
    t, currency, fullName, amount, concept, account, codeTransaction, successfull, responsible,
    contact, handleInputChange, handleClickShowHidden, isToggle, contactExist,
    chargeTransfer: { charge, total, charge301 }
  } = props;
  return (
    <div>
      <TableList
        lengthData={1}
        tableClasses="dashed"
        head={(
          <tr>
            <th>{t('beneficiary')}</th>
            <th>{t('cbuOrAlias')}</th>
            <th>{`${t('amount')} ${currency}`}</th>
            <th>{t('concept')}</th>
          </tr>
        )}
      >
        <tr key="">
          <td>{fullName}</td>
          <td>{account}</td>
          <td>
            <AmountFormat value={amount} displayType="text" name="amountTable" />
          </td>
          <td className="concept">{concept}</td>
        </tr>
      </TableList>
      {(codeTransaction && responsible) && (
        <div className="columns">
          <div className="column is-full is-centered">
            <h4>
              {`${t('codeTransaction')}: `}
              <b>{` ${codeTransaction} `}</b>
            </h4>
          </div>
        </div>
      )}
      {successfull ? '' :
        <div className="columns">
          <div className="column is-full is-centered">
            {!contactExist && (<div className="addContact">
              <span onClick={handleClickShowHidden}>
                {isToggle ? `- ${t('noAddRecipient')}` : `+ ${t('addRecipient')}`}
              </span>
              {isToggle && <TextInput
                name="contact"
                value={contact}
                placeholder={t('aliasContact')}
                onChange={handleInputChange}
                type="text"
                controlClasses="is-one-third"
                fieldClasses="is-8-touch is-offset-2-touch is-one-third-desktop is-offset-0-desktop is-one-fifth-fullhd "
              />
              }
            </div>)
            }
          </div>
        </div>
      }
      <TotalTransaction
        label={successfull ? `${t('totalTransferred')} ` : `${t('totalToTransferred')} `}
        amount={amount}
        currency={currency}
        total={formatThousand(total)}
        charge={formatThousand(charge)}
        charge301={formatThousand(charge301)}
        chargeDescription={t('chargeTaxes')}
        chargeLawDescription={t('chargeLawDescription')}
        amountDescription={t('amountToReceive')}
      />
    </div>
  );
};

TransferDetail.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(TransferDetail);
