import axios                      from 'axios';
import { authActions, authTypes } from '../ducks/auth';
import { transactionsTypes }      from '../ducks/transactions';
import {usersTypes}               from "../ducks/users";

const api = ({ dispatch, getState }) => next => (action) => {
  const types = [
    authTypes.API_CALL,
    transactionsTypes.API_CALL,
    usersTypes.API_CALL,
  ];

  if (!types.includes(action.type)) {
    return next(action);
  }

  const {
    config: preConfig,
    authorization = false,
    onStart = () => console.error('onStart not defined'),
    onEnd = () => console.error('onEnd not defined'),
    onComplete = response => console.log('onComplete', response),
    onError = error => console.log('onError', error),
  } = action.payload;

  const { auth: { user, expToken } } = getState();

  const config = authorization ? {
    ...preConfig,
    headers: {
      ...preConfig.headers,
      // In this case we are using a bearer-token-based authentication
      Authorization: `${user.token}`,
      Exptoken: `${expToken}`,
    },
  } : preConfig;

  dispatch(onStart());
  axios(config)
    .then((response) => {
      const { status } = response;
      if (status === 401) {
        dispatch(authActions.logout());
      }
      if (Object.keys(response.data).find(item => item === 'success') === 'success') {
        const { data: { success, data: { message } } } = response;
        if (success === false && message === 'El token es incorrecto') {
          dispatch(authActions.logout());
        }
        dispatch(authActions.updateExpToken({ expToken: response.data.expToken }));
      }
      onComplete(response);
      dispatch(onEnd(response));
    })
    .catch((error) => {
      const { response } = error;
      if (response) {
        const { status } = response;
        if (status === 401) {
          dispatch(authActions.logout());
        }
      }
      onError(error);
      dispatch(onEnd(error));
    });
};

export default [api];
