import React                        from 'react';
import { withTranslation }          from 'react-i18next';
import { Form, FormElement, Panel, AmountFormat } from '../../../../components';
import './styles.scss';

const DetailDepositRegister = ({
  t, view, numberTransc, typeBank, amount, currency, onConfirm, selectedFile, imagePreviewUrl, onChangeView
}) => {
  return (
    <Panel headingText={t('detailRegister')}>
      <Form
        submitText={view === 'second' ? t('confirm') : null}
        secondButton={view === 'second' ? t('goBack') : null}
        onSubmit={onConfirm}
        handleSecondButton={() => onChangeView()}
        >
        <div className="columns">
          <div className="column detail-register">
            <div className='label-value'>{t('bank')}</div>
            <div>{typeBank}</div>
          </div>
          <div className="column detail-register">
            <div className='label-value'>{t('numberTransaction')}</div>
            <div>{numberTransc}</div>
          </div>
          <div className="column detail-register">
            <div className='label-value'>{t('depositedAmount')}</div>
            <AmountFormat
              name='balance'
              value={amount}
              currency={currency}
              displayType="text"
            />
          </div>
          <div className="column detail-register">
            <div className='label-value'>{t('voucher')}</div>
            <div>
              { imagePreviewUrl ? <img src={imagePreviewUrl}  /> : ''}
              {selectedFile ? selectedFile.name : t('withoutAttached')}</div>
          </div>
        </div>
      </Form>
    </Panel>
  );
}

export default withTranslation()(DetailDepositRegister);
