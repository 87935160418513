import { createAction } from 'redux-actions';
import types            from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const clearTransactions = createAction(types.CLEAR);
const checkBalance = createAction(types.CHECK_BALANCE);
const updateBalance = createAction(types.UPDATE_BALANCE);
const fetchListTransactions = createAction(types.FETCH_LIST_TRANSACTIONS);
const updateListTransactions = createAction(types.UPDATE_LIST_TRANSACTIONS);
const fetchTransactionsTypes = createAction(types.FETCH_TRANSACTIONS_TYPES);
const updateTransactionsTypes = createAction(types.UPDATE_TRANSACTIONS_TYPES);
const fetchDocumentType = createAction(types.FETCH_DOCUMENT_TYPE);
const updateDocumentType = createAction(types.UPDATE_DOCUMENT_TYPE);
const fetchVerifyPassenger = createAction(types.FETCH_VERIFY_PASSENGER);
const updateVerifyPassenger = createAction(types.UPDATE_VERIFY_PASSENGER);
const fetchRechargeBalance = createAction(types.FETCH_RECHARGE_BALANCE);
const updateRechargeBalance = createAction(types.UPDATE_RECHARGE_BALANCE);
const fetchBanks = createAction(types.FETCH_BANKS);
const updateBanks = createAction(types.UPDATE_BANKS);
const fetchUploadDeposit = createAction(types.FETCH_UPLOAD_DESPOSIT);
const updateUploadDeposit = createAction(types.UPDATE_UPLOAD_DEPOSIT);
const fetchListLotClose = createAction(types.FETCH_LIST_LOT_CLOSE);
const updateListLotClose = createAction(types.UPDATE_LIST_LOT_CLOSE);
const fetchAdminList = createAction(types.FETCH_ADMIN_LIST);
const updateAdminList = createAction(types.UPDATE_ADMIN_LIST);
const fetchStateLots = createAction(types.FETCH_STATE_LOTS);
const updateStateLots = createAction(types.UPDATE_STATE_LOTS);
const fetchLotsList = createAction(types.FETCH_LOTS_LIST)
const updateLotsList = createAction(types.UPDATE_LOTS_LIST);
const fetchInfoCash = createAction(types.FETCH_INFO_CASH);
const updateInfoCash = createAction(types.UPDATE_INFO_CASH);
const fetchClosureCash = createAction(types.FETCH_CLOSURE_CASH);
const updateClosureCash = createAction(types.UPDATE_CLOSURE_CASH);
const fetchLotClosed = createAction(types.FETCH_LOT_CLOSED);
const updateLotClosed = createAction(types.UPDATE_LOT_CLOSED);
const fetchLotListClosed = createAction(types.FETCH_LOT_LIST_CLOSED);
const updateLotListClosed = createAction(types.UPDATE_LOT_LIST_CLOSED);
const fetchTransactionsByLot = createAction(types.FETCH_TRANSACTIONS_BY_LOT);
const updateTransactionsByLot = createAction(types.UPDATE_TRANSACTIONS_BY_LOT);
const fetchTransactionsTypesAccount = createAction(types.FETCH_TRANSACTIONS_TYPES_ACCOUNT);
const updateTransactionsTypesAccount = createAction(types.UPDATE_TRANSACTIONS_TYPES_ACCOUNT);
const checkChargeTransfer = createAction(types.FETCH_CHARGE_TRANSFER);
const getChargeTransfer = createAction(types.GET_CHARGE_TRANSFER);
const saveTransaction = createAction(types.SAVE_TRANSACTION);
const checkCbu = createAction(types.CHECK_CBU);
const updateCbu = createAction(types.UPDATE_CBU);
const validateAmountLimits = createAction(types.VALIDATE_AMOUNT_LIMITS);
const validateTimeTransfer = createAction(types.VALIDATE_TIME_TRANSFER);
const fetchPdf = createAction(types.FETCH_PDF);
const fetchChargeQr = createAction(types.FETCH_CHARGE_QR);
const updateChargeQr = createAction(types.UPDATE_CHARGE_QR);
const cancelChargeQr = createAction(types.CANCEL_CHARGE_QR);
const fetchStatusChargeQr = createAction(types.FETCH_STATUS_CHARGE_QR);
const fetchVerifyExtraction = createAction(types.FETCH_VERIFY_EXTRACTION);
const updateVerifyExtraction = createAction(types.UPDATE_VERIFY_EXTRACTION);
const cancelExtraction = createAction(types.CANCEL_EXTRACTION);
const confirmExtraction = createAction(types.CONFIRM_EXTRACTION);
const updateConfirmExtraction = createAction(types.UPDATE_CONFIRM_EXTRACTION);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  endError,
  showMessage,
  clearTransactions,
  checkBalance,
  updateBalance,
  fetchListTransactions,
  updateListTransactions,
  fetchTransactionsTypes,
  updateTransactionsTypes,
  fetchDocumentType,
  updateDocumentType,
  fetchVerifyPassenger,
  updateVerifyPassenger,
  fetchRechargeBalance,
  updateRechargeBalance,
  fetchBanks,
  updateBanks,
  fetchInfoCash,
  updateInfoCash,
  fetchClosureCash,
  updateClosureCash,
  fetchUploadDeposit,
  updateUploadDeposit,
  fetchListLotClose,
  updateListLotClose,
  fetchAdminList,
  updateAdminList,
  fetchStateLots,
  updateStateLots,
  fetchLotsList,
  updateLotsList,
  fetchLotClosed,
  updateLotClosed,
  fetchLotListClosed,
  updateLotListClosed,
  fetchTransactionsByLot,
  updateTransactionsByLot,
  fetchTransactionsTypesAccount,
  updateTransactionsTypesAccount,
  checkChargeTransfer,
  getChargeTransfer,
  saveTransaction,
  checkCbu,
  updateCbu,
  validateAmountLimits,
  validateTimeTransfer,
  fetchPdf,
  fetchChargeQr,
  updateChargeQr,
  cancelChargeQr,
  fetchStatusChargeQr,
  fetchVerifyExtraction,
  updateVerifyExtraction,
  cancelExtraction,
  confirmExtraction,
  updateConfirmExtraction,
};
