import React, { Component, Fragment }                                              from 'react';
import { withTranslation }                                                         from 'react-i18next';
import { toast }                                                                   from 'react-toastify';
import PropTypes                                                                   from 'prop-types';
import { compose }                                                                 from 'redux';
import { connect }                                                                 from 'react-redux';
import { AmountFormat, ContentHeader, Filter, Loading, TableList, TransactionRow } from '../../../components';
import { initTimer, stopTimer }                                                    from '../../../util/initTimer';
import { transactionsActions }                                                     from '../../../state/ducks/transactions';
import { SIZE_PER_PAGE, TOAST_CONFIG }                                             from '../../../config/constants';
import './style.scss';
import { authActions }                                                             from "../../../state/ducks/auth";

class ListLot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      status: '',
      pdfFile: '',
    }
  }

  componentDidMount() {
    const { clearTransactions } = this.props;
    clearTransactions();
    stopTimer();
    initTimer(this.props);
    this.callApiList();
  }

  callApiList = () => {
    const {
      t, showMessage, fetchListLotClose, fetchLotListClosed,
    } = this.props;
    const { page, status } = this.state;

    fetchListLotClose({
      callback: (success) => {
        if (!success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        } else {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
        return;
      },
    });

    fetchLotListClosed({
      data: {
        page,
        pageSize: SIZE_PER_PAGE,
      }, callback: (success) => {
        if (!success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
        return;
      },
    });
  }

  getPdf = (pdf) => {
    const {
      t, showMessage, fetchPdf,
    } = this.props;
    this.setState({ pdfFile: pdf })
    fetchPdf({
      data: {
        url_file: pdf,
      }, callback: (success) => {
        if (!success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  handleCloseLot = () => {
    const { t, fetchLotClosed, showMessage } = this.props;
    this.setState({ status: 'close' });
    fetchLotClosed({
      callback: (data) => {
        if (data.success) {
          this.setState(() => ({
            message: data.message ?? t('lotSuccess'),
            messageStyle: 'has-text-success',
          }));
          toast.dismiss();
          showMessage({
            message: data.message ?? t('lotSuccess'),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.handleGoExitLot();
          return;
        }
      }
    });
    return;
  }

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  handleGoExitLot = () => {
    this.props.history.push("/lot-closed-successful");
    return;
  }

  render() {
    const {
      t,
      transactions: {
        loading,
        lotClosure: { list, totalElements },
        listLotClosed: { listClosed, totalCount },
      }
    } = this.props;
    const { page } = this.state;
    if (!list.transactions) return null;
    return (
      <div>
        {loading && <Loading />}
        <Fragment>
          <Filter title={t('funcAdmin')} subtitle={t('lotClosure')} />
          <div className='last-close'>{t('lastClosing')}</div>
          <div className='date'>{list.lastLotTime}</div>
          <Filter subtitle={t('transactionsOutLot')} />
          <ContentHeader
            headingText={t('numberTransactions')}
            headingSubText={list.transactions.length !== undefined ? list.transactions.length : 0}
            headingRightText={t('totalLot')}
            headingRightSubText={list.totalPreLiquidation}
          >
            <TableList
              tableClasses="floating-table"
              head={(
                <tr>
                  <th>{t('date')} / {t('hour')}</th>
                  <th>{t('typeOfTransaction')}</th>
                  <th>{t('amount')}</th>
                  <th>{t('commission')}</th>
                </tr>
              )}
              colNumber={4}
              lengthData={totalElements !== undefined ? totalElements : 0}
            >
              {list && (list.transactions.map(transaction => (
                <TransactionRow key={transaction.id_transaction}>
                  <td>{transaction.date}</td>
                  <td>{transaction.transaction_type}</td>
                  <td>
                    <AmountFormat
                      name='balance'
                      value={transaction.amount}
                      currency='AR$'
                      displayType="text"
                      allowNegative
                    />
                  </td>
                  <td>
                    <AmountFormat
                      name='balance'
                      value={transaction.commission}
                      currency='AR$'
                      displayType="text"
                      allowNegative
                    />
                  </td>
                </TransactionRow>
              )))
              }
            </TableList>
            <div className='close-lot'>
              <button
                onClick={() => {this.handleCloseLot()}}
                className='button button-lot is-outlined'
              >
                {t('closeBatch')}
              </button>
            </div>
          </ContentHeader>
          <div className='lot-list'>{t('closedLots')}</div>
          <TableList
            tableClasses='floating-table'
            totalElements={parseFloat(totalCount)}
            onChangePage={this.onChangePage}
            forcePage={page}
            head={(
              <tr>
                <th>{t('date')} / {t('hour')}</th>
                <th>{t('lotNumber')}</th>
                <th>{t('amount')}</th>
                <th>{t('numberOperations')}</th>
                <th>{t('actions')}</th>
              </tr>
            )}
            colNumber={5}
            lengthData={totalCount !== undefined ? totalCount : 0}
          >
            {listClosed && (listClosed.map((lot, i) => (
              <TransactionRow key={i}>
                <td>{lot.lot_date}</td>
                <td>{lot.lot_number !== null ? lot.lot_number : `${t('noLot')}`}</td>
                <td>
                  <AmountFormat
                    name="balance"
                    value={lot.lot_balance}
                    currency='AR$'
                    displayType="text"
                    allowNegative
                  />
                </td>
                <td>{lot.operations}</td>
                <td>
                  {lot.lot_file_url === null ?
                    <button disabled={true} className='button is-outlined is-small'>
                      {t('noPdf')}
                    </button>
                    :
                    <button
                      className='button button-download is-outlined is-small'
                      onClick={() => this.getPdf(lot.lot_file_url)}
                    >
                      {t('downloadPdf')}
                    </button>
                  }
                </td>
              </TransactionRow>
            )))
            }
          </TableList>
        </Fragment>
      </div>
    )
  }
}

ListLot.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchListLotClose: PropTypes.func,
  fetchLotListClosed: PropTypes.func,
  fetchLotClosed: PropTypes.func,
};

ListLot.defaultProps = {
  showMessage: () => {
  },
  fetchListLotClose: () => {},
  fetchLotListClosed: () => {},
  fetchLotClosed: () => {},
};

const mapStateToProps = ({ auth, transactions, }) => (
  { auth, transactions }
);
export default compose(
  connect(mapStateToProps, {
    ...authActions,
    ...transactionsActions,
  }),
  withTranslation(),
)(ListLot);
