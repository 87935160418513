import React              from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes          from 'prop-types';
import { QRCode }         from 'react-qrcode-logo';

const QrCodeGenerate = ({
  bgColor,
  fgColor,
  level,
  size,
  logoImage,
  logoWidth,
  value
}) => {

  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  const Base64 = {
    btoa: (input: string = '') => {
      let str = input;
      let output = '';

      for (let block = 0, charCode, i = 0, map = chars;
           str.charAt(i | 0) || (map = '=', i % 1);
           output += map.charAt(63 & block >> 8 - i % 1 * 8)) {

        charCode = str.charCodeAt(i += 3 / 4);

        if (charCode > 0xFF) {
          throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
        }

        block = block << 8 | charCode;
      }

      return output;
    },
    atob: (input: string = '') => {
      let str = input.replace(/=+$/, '');
      let output = '';

      if (str.length % 4 === 1) {
        throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
      }
      for (let bc = 0, bs = 0, buffer, i = 0;
           buffer = str.charAt(i++);

           ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
           bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
      ) {
        buffer = chars.indexOf(buffer);
      }

      return output;
    }
  };
  const insert = (main_string, ins_string, pos) => {
    if (typeof (pos) === 'undefined') {
      pos = 0;
    }
    if (typeof (ins_string) === 'undefined') {
      ins_string = '';
    }
    return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
  };

  let jsn = Base64.btoa(JSON.stringify(value));
  let charactersLength = chars.length;
  let position = [15, 20, 7];
  let b = chars.charAt(Math.floor(Math.random() * charactersLength));
  let dataQr = insert(jsn, b, position[0]);

  return (
    <QRCode
      bgColor={bgColor}
      fgColor={fgColor}
      level={level}
      size={size}
      logoImage={logoImage}
      logoWidth={logoWidth}
      value={dataQr}
    />
  )
};

QrCodeGenerate.propTypes = {
  bgColor: PropTypes.string,
  fgColor: PropTypes.string,
  level: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  logoImage: PropTypes.string.isRequired,
  logoWidth: PropTypes.number.isRequired,
  value: PropTypes.shape({}).isRequired
};

QrCodeGenerate.defaultProps = {
  bgColor: '#FFFFFF',
  fgColor: '#000000',
  level: 'Q',
  size: 300,
  logoImage: '',
  logoWidth: 25,
  value: {}
};


export default withTranslation()(QrCodeGenerate);
