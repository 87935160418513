import React, { Component }                       from 'react';
import PropTypes                                  from 'prop-types';
import { compose }                                from 'redux';
import { connect }                                from 'react-redux';
import { withTranslation }                        from 'react-i18next';
import { Loading,
    Filter,
    ContentHeader,
    TableList,
    Switch,
    TransactionRow,
    }                                             from '../../components';
import { initTimer, stopTimer }                   from '../../util/initTimer';
import {authActions}                              from "../../state/ducks/auth";
import {usersActions}                             from "../../state/ducks/users";
import {toast}                                    from "react-toastify";
import {TOAST_CONFIG}                             from "../../config/constants";
import './styles.scss';

class OperatorAgency extends Component{

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            page: 0,
            switchList:[]
        };
    }

    componentDidMount() {
        stopTimer();
        initTimer(this.props);
        this.callApiList();
    }

    callApiList = () => {
        const {t, showMessage, fetchOperatorAgency, users : { list_users_operator :{ list, totalElements } } } = this.props;

        fetchOperatorAgency({
            callback: (response) => {
                if (response) {
                    if (!response.success) {
                        toast.dismiss();
                        showMessage({
                            message: t('connectionError'),
                            config: TOAST_CONFIG.ERROR,
                        });
                        return false;
                    }
                } else {
                    toast.dismiss();
                    showMessage({
                        message: t('connectionError'),
                        config: TOAST_CONFIG.ERROR,
                    });
                }
            },
        });
    }

    handleActiveDisable = (event, i, id_user) => {

        const item = event.target.name;
        const isChecked = event.target.checked;

        const {switchList} = this.state;

        const {t, showMessage, enableDisabledOperator} = this.props;
        const data = {
            id_user : id_user,
            new_status: !isChecked ? 0 : 1,
        };

        const status = isChecked ? 'activó' : 'desactivó';

        enableDisabledOperator({
            data,
            callback: (response) => {
                if (response.success) {
                    switchList[i] = isChecked;
                    this.setState({switchList});
                    toast.dismiss();
                    showMessage({
                        message: t('activeDisableOperatorSuccess', {status}),
                        config: TOAST_CONFIG.INFO,
                    });
                } else {
                    toast.dismiss();
                    showMessage({
                        message: t('activeDisableOperatorError', {status}),
                        config: TOAST_CONFIG.ERROR,
                    });
                }
            },
        });
    }

    render() {
        const {t, users :{ loading, list_users_operator :{ list, totalElements } } } = this.props;
        const {  switchList} = this.state;
        return(
            <div className="list-operators">
                { loading && <Loading />}
                <div className="wow animated slideInDown">
                    <Filter title={t('operatorAgency')}>
                    </Filter>
                    <ContentHeader
                        headingRightText={t('operatorsTotal')}
                        headingRightSubText={`${totalElements}`}
                        classFirstColumn="is-paddingless"
                    >
                        <TableList
                            tableClasses="floating-table"
                            totalElements={totalElements}
                            onChangePage={this.onChangePage}
                            hidePagination
                            head={(
                                <tr>
                                    <th>{t('createdDate')}</th>
                                    <th>{t('username')}</th>
                                    <th>{t('name')}</th>
                                    <th>{t('userState')}</th>
                                    <th>{t('role')}</th>
                                    <th>{t('activeDisabled')}</th>
                                </tr>
                            )}
                            colNumber={6}
                            lengthData={totalElements}
                        >
                            {(list !== undefined) && (list.map((user, i) => (
                                <TransactionRow
                                    key={i}
                                    helper={t('detailOperator')}
                                >
                                    <td>{user.date}</td>
                                    <td>{user.username}</td>
                                    <td>{user.name}</td>
                                    <td>{switchList[i] !== undefined ? (switchList[i] == 1 ? t('active'): t('inactive')) : (user.status == '1' ? t('active'): t('inactive')) } </td>
                                    <td>{user.rol}</td>
                                    <td>
                                        <div className="content-switch">
                                        <Switch name={`switch-${i}`}
                                                id={`switch-orange-${i}`}
                                                value={switchList[i] !== undefined ? switchList[i] : (user.status === '1' ? true : false)}
                                                onChange={(event) =>{this.handleActiveDisable(event, i, user.id_user)} }
                                                checked={switchList[i] !== undefined ? switchList[i] : (user.status === '1' ? true : false)}
                                                htmlFor={`switch-orange-${i}`}
                                        />
                                        </div>

                                    </td>
                                </TransactionRow>
                                ))
                            )}

                        </TableList>
                    </ContentHeader>
                </div>
            </div>
        );
    }
}

OperatorAgency.propTypes = {
    t: PropTypes.func.isRequired,
    showMessage: PropTypes.func,
    auth: PropTypes.shape({}).isRequired,
    users: PropTypes.shape({}).isRequired,

};

OperatorAgency.defaultProps = {
    showMessage: null,
};

const mapStateToProps = ({  auth, users }) => ({
    auth,
    users
});

export default compose(connect(mapStateToProps, {
    ...authActions,
    ...usersActions,
}), withTranslation())(OperatorAgency);
