import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, FormElement, Panel } from '../../../../components';
import './style.scss';

const FormRetreats = ({
  t, document, documentType, codeSecurity, onSubmit, onChangeInput, documentTypes, disabled
}) => {
  return (
    <Panel headingText={t('documentAndCode')}>
      <Form
        submitText={t('continue')}
        onSubmit={(ev) => onSubmit(ev)}
        className={'form-elements'}
        disabled={disabled}
      >
        <FormElement
          name='documentType'
          onChange={ev => onChangeInput(ev)}
          value={documentType}
          label={t('documentType')}
          typeElement={'select'}
          optionsSelect={documentTypes}
          placeholder='Seleccione...'
          controlClasses='select'
        />
        <FormElement
          name='document'
          label={t('document')}
          value={document}
          onChange={ev => onChangeInput(ev)}
        />
        <FormElement
          name='codeSecurity'
          label={t('codeSecurity')}
          value={codeSecurity}
          onChange={ev => onChangeInput(ev)}
        />
      </Form>
    </Panel>
  );
}

export default withTranslation()(FormRetreats);
