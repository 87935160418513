import React, { Component, Fragment } from 'react';
import { withTranslation }            from 'react-i18next';
import PropTypes                      from 'prop-types';
import { compose }                    from 'redux';
import { connect }                    from 'react-redux';
import ContentHeader                  from '../../components/ContentHeader';
import { transactionsActions }        from '../../state/ducks/transactions';
import { authActions }                from '../../state/ducks/auth';
import './styles.scss';
import { AmountFormat }               from "../../components";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { checkBalance, fetchInfoCash } = this.props;
    checkBalance();
    fetchInfoCash();
  }

  render() {
    const { t, transactions: { balanceData: { balance, currency }, cashclosure } } = this.props;

    return (
      <Fragment>
        <ContentHeader>
          <div className='columns'>
            <div className='column'>
              <h2 className='title-dashboard'><span className='title-1'>{t('welcomeTo')}</span><br />
                <span className='title-2'>POS <br />{t('urbanServices')}</span></h2>
                <div className='card'>
                  <div className='columns is-multiline'>

                    <div className="column is-half">
                      <div className="operating-balance">{`${t('operatingBalance')}: `}</div>
                    </div>
                    <div className="column is-half">
                      <div className='amount'>
                        <AmountFormat
                          name='balance'
                          value={balance}
                          currency={currency}
                          displayType="text"
                        />
                      </div>
                    </div>

                    <div className="column is-half">
                      <div className="operating-balance">{`${t('balanceBox')}: `}</div>
                    </div>
                    <div className="column is-half">
                      <div className='amount'>
                        <AmountFormat
                          name='balance'
                          value={cashclosure.balance}
                          currency={currency}
                          displayType="text"
                        />
                      </div>
                    </div>

                    <div className="column is-half">
                      <div className="operating-balance">{`${t('balanceCash')}: `}</div>
                    </div>
                    <div className="column is-half">
                      <div className='amount'>
                        <AmountFormat
                          name='balance'
                          value={cashclosure.cash}
                          currency={currency}
                          displayType="text"
                        />
                      </div>
                    </div>

                  </div>
                </div>
            </div>
            <div className='column'>
              <img src='/dashboard.png' />
            </div>
          </div>
        </ContentHeader>

      </Fragment>
    );
  }
}

Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
  checkBalance: PropTypes.func.isRequired,
  fetchInfoCash: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ transactions, auth }) => (
  {
    transactions,
    auth,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
    ...authActions,
  }),
  withTranslation(),
)(Dashboard);
