/**
 * Created by Feedback Software on 24/10/18.
 * @param children contain the body of this table, it's important use th or td inside tr
 * @param totalPages if is pass by params it means that must be added the pagination nav
 * @param title it is used by pass by params to the Panel for the title of this
 * @param head contain the head of the table, it's important pass th inside tr
 * @param currentPage manage de actual page in pagination
 * @param onChangePage manage the change of the page in the pagination, must change currentPage
 */

import React, { Component }                             from 'react';
import PropTypes                                        from 'prop-types';
import { compose }                                      from 'redux';
import { connect }                                      from 'react-redux';
import { withTranslation }                              from 'react-i18next';
import { ConfirmAlert, Filter, Loading, PrimaryButton } from '../../components';
import { transactionsActions }                          from '../../state/ducks/transactions';
import { initTimer, stopTimer }                         from '../../util/initTimer';
import { DetailDepositRegister, FormDepositRegister }   from './components';
import { TOAST_CONFIG }                                 from '../../config/constants.js';

import { confirmAlert } from "react-confirm-alert";
import { authActions }  from "../../state/ducks/auth";
import { toast }        from "react-toastify";
import './styles.scss';

class DepositRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'first',
      typeBank: '',
      numberTransc: '',
      amount: '',
      errorDate: '',
      selectedFile: null,
      imagePreviewUrl: '',
    };
  }

  componentDidMount() {
    const { fetchBanks } = this.props;
    fetchBanks();
  }

  componentWillUnmount() {
    stopTimer();
    initTimer(this.props);
  }

  handleVerifyDocument = (e) => {
    e.preventDefault();
    this.handleChangeView('second');
  }

 handleChangeView = (view = 'first') => {

   if(view === 'first') {
       this.setState({
           typeBank: '',
           numberTransc: '',
           amount: '',
           errorDate: '',
           selectedFile: null,
           imagePreviewUrl: '',
           view,
       });
    }
       else{
           this.setState({ view });
       }

 };

  handleInputChange = (event) => {
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    });

  };

  handleChangeUploadFile = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        selectedFile: file,
        imagePreviewUrl: reader.result
      });
    }
    reader.readAsDataURL(file)
  };

  onConfirmDeposit = (e) => {
    e.preventDefault();
    ConfirmAlert(
      { ...this.props },
      () => {
        const { fetchUploadDeposit, auth: { expToken, user: { token } }, showMessage, t } = this.props;
        const { typeBank, numberTransc, amount, selectedFile } = this.state;

        const data = new FormData();

        if (selectedFile !== null && selectedFile !== undefined) {
          data.append("file", selectedFile);
        }

        const formatAmount = amount.replace(/\./g, '').replace(',', '.');

        data.append('id_bank', typeBank);
        data.append('bead_number', numberTransc);
        data.append('deposit_balance', formatAmount);

        fetchUploadDeposit({
          data, token, expToken,
          callback: async (response) => {
            const { success, data } = response;

            if (success) {
              this.setState(() => ({
                message: 'Carga de depósito exitosa',
                messageStyle: 'has-text-success',
              }));
              this.handleSucessLoadDeposit();
            } else if (!success && (data && typeof data.message !== 'undefined')) {
              this.setState(() => ({
                message: 'Error al procear la solicitud. Intentá más tarde.',
                messageStyle: 'has-text-danger',
              }));
              toast.dismiss();
              showMessage({
                message: data.message,
                config: TOAST_CONFIG.ERROR,
              });
            }
          }
        });

      }, 'acceptDepositConfirm');
  };

    handleSucessLoadDeposit = () =>{
        const { t } = this.props;
        const options = {
            customUI: ({onClose}) => (
                <div className="box-success columns is-multiline">
                    <div className="content-box column is-full has-text-centered">

                        <div className="columns is-centered">
                            <div className={`column is-full has-text-centered title-message has-text-success `}>
                                {t('successOperation')}
                            </div>
                        </div>

                        <span className="text-detail">{t('loadDepositText')}</span>
                    </div>
                    <div className="btn-accept column">
                       <PrimaryButton name={t('continueButton')} onClick={() => {

                                    onClose(this.handleChangeView());
                       }}/>

                    </div>
                </div>
            ),
            closeOnEscape: false,
            closeOnClickOutside: false,
        };

    return confirmAlert(options);
  }

  validateEmptyFields = () => {
    const { typeBank, amount, numberTransc } = this.state;
    return (typeBank === '' || amount === '' || numberTransc === '');
  };

  render() {
    const { t, transactions: { loading, banks, balanceData: { currency } } } = this.props;
    const { view, typeBank, amount, numberTransc, selectedFile, imagePreviewUrl } = this.state;

    const disabledForm = this.validateEmptyFields();
      if (view === 'first') {
        stopTimer();
        initTimer(this.props);
        return (
          <section>
            {loading && <Loading />}
              <Filter title={t('funcAdmin')} />
              <FormDepositRegister
                typeBank={typeBank}
                typesBanks={banks}
                amount={amount}
                numberTransc={numberTransc}
                onSubmit={this.handleVerifyDocument}
                onChangeInput={this.handleInputChange}
                disabled={disabledForm}
                handleUploadFile={this.handleUploadFile}
                handleChangeUploadFile={this.handleChangeUploadFile}
                selectedFile={selectedFile}
                imagePreviewUrl={imagePreviewUrl}
              />
          </section>
        );
      }
      if (view === 'second') {
        stopTimer();
        initTimer(this.props);
        const bank = banks.find(item => item.id === typeBank);

        return (
          <section>
            {loading && <Loading />}
            <Filter title={t('funcAdmin')} />
            <DetailDepositRegister
              typeBank={bank.bank_name}
              amount={amount}
              currency={currency}
              numberTransc={numberTransc}
              onConfirm={this.onConfirmDeposit}
              onChangeView={this.handleChangeView}
              view={view}
              selectedFile={selectedFile}
              imagePreviewUrl={imagePreviewUrl}
            />
          </section>
        );
      }
  }
}

DepositRegister.propTypes = {
  t: PropTypes.func.isRequired,
  fetchBanks: PropTypes.func,
};

DepositRegister.defaultProps = {
  showMessage: () => {},
  fetchBanks: () => {},
};

const mapStateToProps = ({ transactions, auth }) => ({
  transactions,
  auth,
});


export default compose(connect(mapStateToProps, {
  ...transactionsActions,
  ...authActions,
}), withTranslation())(DepositRegister);
