const START_FETCH = 'transactions/START_FETCH';
const END_FETCH = 'transactions/END_FETCH';
const END_ERROR = 'transactions/END_ERROR';
const MESSAGE = 'transactions/MESSAGE';
const API_CALL = 'transactions/API_CALL';
const CLEAR = 'transactions/CLEAR';
const CHECK_BALANCE = 'transactions/CHECK_BALANCE';
const UPDATE_BALANCE = 'transactions/UPDATE_BALANCE';
const FETCH_DOCUMENT_TYPE = 'transactions/FETCH_DOCUMENT_TYPE';
const UPDATE_DOCUMENT_TYPE = 'transactions/UPDATE_DOCUMENT_TYPE';
const FETCH_VERIFY_PASSENGER = 'transactions/FETCH_VERIFY_PASSENGER';
const UPDATE_VERIFY_PASSENGER = 'transactions/UPDATE_VERIFY_PASSENGER';
const FETCH_RECHARGE_BALANCE = 'transactions/FETCH_RECHARGE_BALANCE';
const UPDATE_RECHARGE_BALANCE = 'transactions/UPDATE_RECHARGE_BALANCE';
const FETCH_LIST_TRANSACTIONS = 'transaction/FETCH_LIST_TRANSACTIONS';
const UPDATE_LIST_TRANSACTIONS = 'transactions/UPDATE_LIST_TRANSACTIONS';
const FETCH_TRANSACTIONS_TYPES = 'transactions/FETCH_TRANSACTIONS_TYPES';
const UPDATE_TRANSACTIONS_TYPES = 'transactions/UPDATE_TRANSACTIONS_TYPES';
const FETCH_BANKS = 'transactions/FETCH_BANKS';
const UPDATE_BANKS = 'transactions/UPDATE_BANKS';
const FETCH_UPLOAD_DESPOSIT = 'transactions/FETCH_UPLOAD_DESPOSIT';
const UPDATE_UPLOAD_DEPOSIT = 'transactions/UPDATE_UPLOAD_DEPOSIT';
const FETCH_LIST_LOT_CLOSE = 'transactions/FETCH_LIST_LOT_CLOSE';
const UPDATE_LIST_LOT_CLOSE = 'transactions/UPDATE_LIST_LOT_CLOSE';
const FETCH_ADMIN_LIST = 'transactions/FETCH_ADMIN_LIST';
const UPDATE_ADMIN_LIST = 'transactions/UPDATE_ADMIN_LIST';
const FETCH_STATE_LOTS = 'transactions/FETCH_STATE_LOTS';
const UPDATE_STATE_LOTS = 'transactions/UPDATE_STATE_LOTS';
const FETCH_LOTS_LIST = 'transactions/FETCH_LOTS_LIST';
const UPDATE_LOTS_LIST = 'transactions/UPDATE_LOTS_LIST';
const FETCH_INFO_CASH = 'transactions/FETCH_INFO_CASH';
const UPDATE_INFO_CASH = 'transactions/UPDATE_INFO_CASH';
const FETCH_CLOSURE_CASH = 'transactions/FETCH_CLOSURE_CASH';
const UPDATE_CLOSURE_CASH = 'transactions/UPDATE_CLOSURE_CASH';
const FETCH_LOT_CLOSED = 'transactions/FETCH_LOT_CLOSED';
const UPDATE_LOT_CLOSED = 'transactions/UPDATE_LOT_CLOSED';
const FETCH_LOT_LIST_CLOSED = 'transactions/FETCH_LOT_LIST_CLOSED';
const UPDATE_LOT_LIST_CLOSED = 'transactions/UPDATE_LOT_LIST_CLOSED';
const FETCH_TRANSACTIONS_BY_LOT = 'transactions/FETCH_TRANSACTIONS_BY_LOT';
const UPDATE_TRANSACTIONS_BY_LOT = 'transactions/UPDATE_TRANSACTIONS_BY_LOT';
const FETCH_TRANSACTIONS_TYPES_ACCOUNT = 'transactions/FETCH_TRANSACTIONS_TYPES_ACCOUNT';
const UPDATE_TRANSACTIONS_TYPES_ACCOUNT = 'transactions/UPDATE_TRANSACTIONS_TYPES_ACCOUNT';
const FETCH_CHARGE_TRANSFER = 'transactions/FETCH_CHARGE_TRANSFER';
const GET_CHARGE_TRANSFER = 'transactions/GET_CHARGE_TRANSFER';
const SAVE_TRANSACTION = 'transactions/SAVE_TRANSACTION';
const CHECK_CBU = 'transactions/CHECK_CBU';
const UPDATE_CBU = 'transactions/UPDATE_CBU';
const VALIDATE_AMOUNT_LIMITS = 'transactions/VALIDATE_AMOUNT_LIMITS';
const VALIDATE_TIME_TRANSFER = 'transactions/VALIDATE_TIME_TRANSFER';
const FETCH_PDF = 'transactions/FETCH_PDF';
const FETCH_CHARGE_QR = 'transactions/FETCH_CHARGE_QR';
const UPDATE_CHARGE_QR = 'transactions/UPDATE_CHARGE_QR';
const CANCEL_CHARGE_QR = 'transactions/CANCEL_CHARGE_QR';
const FETCH_STATUS_CHARGE_QR = 'transactions/FETCH_STATUS_CHARGE_QR';
const FETCH_VERIFY_EXTRACTION = 'transactions/FETCH_VERIFY_EXTRACTION';
const UPDATE_VERIFY_EXTRACTION = 'transactions/UPDATE_VERIFY_EXTRACTION';
const CANCEL_EXTRACTION = 'transactions/CANCEL_EXTRACTION';
const CONFIRM_EXTRACTION = 'transactions/CONFIRM_EXTRACTION';
const UPDATE_CONFIRM_EXTRACTION = 'transactions/UPDATE_CONFIRM_EXTRACTION';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  MESSAGE,
  CLEAR,
  CHECK_BALANCE,
  UPDATE_BALANCE,
  FETCH_DOCUMENT_TYPE,
  UPDATE_DOCUMENT_TYPE,
  FETCH_VERIFY_PASSENGER,
  UPDATE_VERIFY_PASSENGER,
  FETCH_RECHARGE_BALANCE,
  UPDATE_RECHARGE_BALANCE,
  FETCH_LIST_TRANSACTIONS,
  UPDATE_LIST_TRANSACTIONS,
  FETCH_TRANSACTIONS_TYPES,
  UPDATE_TRANSACTIONS_TYPES,
  FETCH_BANKS,
  UPDATE_BANKS,
  FETCH_UPLOAD_DESPOSIT,
  UPDATE_UPLOAD_DEPOSIT,
  FETCH_LIST_LOT_CLOSE,
  UPDATE_LIST_LOT_CLOSE,
  FETCH_ADMIN_LIST,
  UPDATE_ADMIN_LIST,
  FETCH_STATE_LOTS,
  UPDATE_STATE_LOTS,
  FETCH_LOTS_LIST,
  UPDATE_LOTS_LIST,
  FETCH_INFO_CASH,
  UPDATE_INFO_CASH,
  FETCH_CLOSURE_CASH,
  UPDATE_CLOSURE_CASH,
  FETCH_LOT_CLOSED,
  UPDATE_LOT_CLOSED,
  FETCH_LOT_LIST_CLOSED,
  UPDATE_LOT_LIST_CLOSED,
  FETCH_TRANSACTIONS_BY_LOT,
  UPDATE_TRANSACTIONS_BY_LOT,
  FETCH_TRANSACTIONS_TYPES_ACCOUNT,
  UPDATE_TRANSACTIONS_TYPES_ACCOUNT,
  FETCH_CHARGE_TRANSFER,
  GET_CHARGE_TRANSFER,
  SAVE_TRANSACTION,
  CHECK_CBU,
  UPDATE_CBU,
  VALIDATE_AMOUNT_LIMITS,
  VALIDATE_TIME_TRANSFER,
  FETCH_PDF,
  FETCH_CHARGE_QR,
  UPDATE_CHARGE_QR,
  CANCEL_CHARGE_QR,
  FETCH_STATUS_CHARGE_QR,
  FETCH_VERIFY_EXTRACTION,
  UPDATE_VERIFY_EXTRACTION,
  CANCEL_EXTRACTION,
  CONFIRM_EXTRACTION,
  UPDATE_CONFIRM_EXTRACTION,
};
