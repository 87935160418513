import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { Loading, Filter, ConfirmAlert, PrimaryButton } from '../../components';
import { initTimer, stopTimer } from '../../util/initTimer';
import { transactionsActions } from '../../state/ducks/transactions';
import { authActions } from '../../state/ducks/auth';
import { TOAST_CONFIG } from '../../config/constants';
import { FormRetreats, DetailRetreats, FinalRetreats } from './components';

class Retreats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'first',
      document: '',
      documentType: '',
      codeSecurity: '',
      passengerSelect: '',
      disabled: true,
      numReg: 0,
      balanceBox: ' ',
    };
  }

  componentDidMount() {
    const { t, fetchDocumentType, fetchInfoCash } = this.props;
    fetchDocumentType();
    fetchInfoCash({
      callback(data) {
        const amount = parseFloat(data.cash);
        if (amount === 0 || amount === undefined) {
          return (confirmAlert(options));
        }
      },
    });
    initTimer(this.props);
    const options = {
      customUI: () => (
        <div className="information-alert message columns is-multiline">
          <div className="column is-full has-text-centered text-message">
            {this.message()}
          </div>
          <div className="column is-full has-text-centered">
            <button
              className="button is-outlined"
              onClick={() => {
                setTimeout(() => {
                  window.location.href = '/dashboard';
                }, 1000)
              }}
            >
              {t('accept')}
            </button>
          </div>
        </div>
      ),
      closeOnEscape: false,
      closeOnClickOutside: false,
    };
  }

  message = () => {
    const { t } = this.props;
    return (
      <div className='has-text-centered mt-16px'>
        <span>
          <i className='fas fa-exclamation-triangle has-text-danger' /> {t('messageExtraction')}
        </span>
      </div>
    );
  };

  handleInputChange = (e) => {
    const { target: { value: targetValue, name } } = e;
    this.setState({ [name]: targetValue });
  };

  validateEmptyFields = () => {
    const {
      step, document, documentType, codeSecurity,
    } = this.state;
    if (step === 'first') {
      return (document === '' || documentType === '' || codeSecurity === '');
    }
    return false;
  };

  handleVerifyDocument = (e) => {
    e.preventDefault();
    const { fetchVerifyExtraction, t, showMessage, transactions: { documentTypes } } = this.props;
    const { documentType, document, codeSecurity } = this.state;
    fetchVerifyExtraction({
      data: {
        document_type: documentType,
        document,
        code: codeSecurity,
      }, callback: ({ success, data }) => {
        if (data[0].status === 0) {
          showMessage ({
            message: data[0].message,
            config: TOAST_CONFIG.INFO,
          })
        } else if (data[0].status === 2) {
          showMessage ({
            message: t('extractionSuccess'),
            config: TOAST_CONFIG.INFO,
          })
        } else if (data[0].status === 3) {
          showMessage ({
            message: data[0].message,
            config: TOAST_CONFIG.INFO,
          })
        } else {
          const labelDocumentType = documentTypes.find((item) => item.id === documentType).name;
          this.setState({ labelDocumentType });
          if (data.length === 0) {
            showMessage({
              message: t('notDocRegister'),
              config: TOAST_CONFIG.INFO,
            });
          } else if (data.length === 1) {
            this.setState({
              passengerSelect: data[0],
              disabled: false,
              numReg: 1,
            });
            this.handleChangeView('second');
          }
        }
      }
    });
    toast.dismiss();
  }

  handleChangeView = (step = 'first') => {
    this.setState({ step });
  };

  cancelRetreats = (e, id) => {
    e.preventDefault();
    const { t, showMessage, cancelExtraction } = this.props;
    cancelExtraction ({
      data: {
        id
      }, callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: response.data.message,
            config: TOAST_CONFIG.ERROR,
          });
        } else if (response.success) {
          toast.dismiss();
          showMessage({
            message: response.data.message,
            config: TOAST_CONFIG.INFO,
          });
        }
      },
    })
    setTimeout (() => {
      window.location.reload('/retreats');
    }, 5000);
  }

  confirmRetreats = (e, id) => {
    e.preventDefault();
    const { t, confirmExtraction, showMessage } = this.props;
    confirmExtraction({
      data: {
        id
      }, callback({ success, data }) {
        if (success) {
          setTimeout (() => {
            showMessage ({
              message: t('successOperation'),
              config: TOAST_CONFIG.SUCCESS,
            })
          }, 500);
        } else {
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      }
    });
    toast.dismiss();
    setTimeout(() => {
      this.handleChangeView('third');
    }, 1000);
  }

  resetView = (e) => {
    e.preventDefault();
    setTimeout (() => {
      this.setState({
        documentType: '',
        document: '',
        codeSecurity: ''
      });
      this.handleChangeView('first');
    }, 1000);
  }

  render () {
    const {
      step, document, documentType, codeSecurity, labelDocumentType, passengerSelect,
    } = this.state;
    const { t,
      transactions: {
        documentTypes, loading, rechargeBalancePerson, approveRechargeBalancePerson, voucher
      }
    } = this.props;
    const disabledForm = this.validateEmptyFields();

    if (step === 'first') {
      stopTimer();
      initTimer(this.props);
      return (
        <section>
          { loading && <Loading /> }
          <Filter title={t('retreats')} />
          <FormRetreats
            document={document}
            documentType={documentType}
            documentTypes={documentTypes}
            codeSecurity={codeSecurity}
            onSubmit={this.handleVerifyDocument}
            onChangeInput={this.handleInputChange}
            disabled={disabledForm}
          />
        </section>
      );
    }

    if (step === 'second') {
      stopTimer();
      initTimer(this.props);
      return (
        <section>
          { loading && <Loading /> }
          <Filter title={t('retreats')} />
          <DetailRetreats
            person={passengerSelect}
            document={document}
            documentType={labelDocumentType}
            onCancel={this.cancelRetreats}
            onConfirm={this.confirmRetreats}
          />
        </section>
      );
    }

    if (step === 'third') {
      stopTimer();
      initTimer(this.props);
      return (
        <section>
          { loading && <Loading /> }
          <Filter title={t('retreats')} />
          <FinalRetreats
            person={passengerSelect}
            documentType={labelDocumentType}
            voucher={voucher}
            step={step}
            onResetView={this.resetView}
          />
        </section>
      );
    }

  }
}

Retreats.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  auth: PropTypes.shape({}).isRequired,
  transactions: PropTypes.shape({}).isRequired,
  fetchDocumentType: PropTypes.func,
  fetchVerifyExtraction: PropTypes.func,
};

Retreats.defaultProps = {
    fetchDocumentType: null,
    showMessage: null,
};

const mapStateToProps = ({ transactions, auth }) => ({
  transactions, auth,
});

export default compose(connect(mapStateToProps, {
  ...transactionsActions,
  ...authActions,
}), withTranslation())(Retreats);
