const START_FETCH = 'users/START_FETCH';
const END_FETCH = 'users/END_FETCH';
const END_ERROR = 'users/END_ERROR';
const MESSAGE = 'users/MESSAGE';
const API_CALL = 'users/API_CALL';
const FETCH_OPERATOR_AGENCY = 'users/FETCH_OPERATOR_AGENCY';
const SET_OPERATOR_AGENCY = 'users/SET_OPERATOR_AGENCY';
const ENABLE_DISABLED_OPERATOR = 'users/ENABLE_DISABLED_OPERATOR';
const FETCH_CONTACT_BOOK = 'users/FETCH_CONTACT_BOOK';
const UPDATE_CONTACT_BOOK = 'users/UPDATE_CONTACT_BOOK';
const FETCH_DELETE_CONTACT = 'users/FETCH_DELETE_CONTACT';
const UPDATE_DELETE_CONTACT = 'users/UPDATE_DELETE_CONTACT';

export default {
    START_FETCH,
    END_FETCH,
    END_ERROR,
    MESSAGE,
    API_CALL,
    FETCH_OPERATOR_AGENCY,
    SET_OPERATOR_AGENCY,
    ENABLE_DISABLED_OPERATOR,
    FETCH_CONTACT_BOOK,
    UPDATE_CONTACT_BOOK,
    FETCH_DELETE_CONTACT,
    UPDATE_DELETE_CONTACT,
}
