import React     from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const DefaultButton = ({ name, type, onClick, icon, classButton }) => (
  <button
    type={type}
    onClick={onClick}
    className={`button is-custom-back button-default ${classButton}`}
  >
    {icon && (<i className={`icon ${icon}`} />)}
    {name}
  </button>
);
DefaultButton.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string,
    classButton: PropTypes.string,
};
DefaultButton.defaultProps = {
  type: 'button',
  onClick: null,
  icon: 'fas fa-chevron-left',
  classButton: ''
};

export default DefaultButton;
