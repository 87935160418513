import React                                      from 'react';
import { withTranslation }                        from 'react-i18next';
import { AmountFormat, Form, FormElement, Panel } from '../../../../components';
import PropTypes                                  from "prop-types";

const DetailBalanceCharge = ({
         t,
         onSubmit,
         onChangeInput,
         onChangeAmount,
         amount,
         document,
         documentType,
         onChangeView,
         disabled,
         view,
         message,
         messageStyle,
         person: {
           lastname, name, username,
         },
         approveTransaction: {
           transaction_charge, transaction_code, transaction_date, transaction_balance,
         },
         numReg
       }) => {
    return (
      <Panel headingText={t('balanceChargePassengers')}>
        {message && (
          <div className="columns is-centered">
            <div className={`column is-full has-text-centered title-message ${messageStyle} `}>
              {message}
            </div>
          </div>
        )}
        <Form
          submitText={view === 'third' ? t('continue') : (view === 'fourth' ? t('finish') : null)}
          secondButton={view === 'third' ? t('return') : null}
          onSubmit={onSubmit}
          handleSecondButton={() => onChangeView(view === 'third' && numReg > 1 ? 'second' : (view === 'third' && numReg === 1 ? 'first' : 'first'))}
          className={'form-elements'}
          disabled={disabled}
        >
          {(view === 'fourth' && transaction_date !== null) &&
          <FormElement
            name='date'
            label={`${t('date')}:`}
            value={transaction_date}
            onChange={ev => onChangeInput(ev)}
            typeElement={view === 'third' ? 'amountFormat' : 'text'}
          />}
          {(view === 'fourth' && transaction_code !== null) &&
          <FormElement
            name='transactionCode'
            label={`${t('transactionCode')}:`}
            value={transaction_code}
            onChange={ev => onChangeInput(ev)}
            typeElement={'text'}
          />}
          <FormElement
            name='documentType'
            label={`${t('documentType')}:`}
            value={documentType}
            typeElement={'text'}
          />
          <FormElement
            name='document'
            label={`${t('document')}:`}
            value={document}
            typeElement={'text'}
          />
          <FormElement
            name='fullName'
            label={`${t('fullName')}:`}
            value={`${name} ${lastname}`}
            typeElement={'text'}
          />
          <FormElement
            name='email'
            label={`${t('email')}:`}
            value={username}
            typeElement={'text'}
          />
          <FormElement
            name='amount'
            label={`${t('rechargeAmount')}:`}
            value={(view === 'fourth' ? <AmountFormat
              name='amount'
              value={ amount}
              currency='AR$'
              displayType="text"
            /> : amount)}
            onChange={(e) => onChangeAmount(e)}
            typeElement={view === 'third' ? 'amountFormat' : 'text'}
          />
          {(view === 'fourth' && transaction_charge !== null) &&
          <FormElement
            name='transactionCharge'
            label={`${t('transactionCharge')}:`}
            value={<AmountFormat
              name='charge'
              value={(transaction_charge !== 0) ? transaction_charge : '0'}
              currency='AR$'
              displayType="text"
            />}
            onChange={ev => onChangeInput(ev)}
            typeElement={'text'}
          />}
          {(view === 'fourth' && transaction_charge !== null) &&
          <FormElement
            name='currentBalance'
            label={`${t('currentBalance')}:`}
            value={<AmountFormat
              name='balance'
              value={transaction_balance}
              currency='AR$'
              displayType="text"
              allowNegative
            />}
            onChange={ev => onChangeInput(ev)}
            typeElement={'text'}
          />}
        </Form>
      </Panel>
    )
  }
;

DetailBalanceCharge.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onChangeInput: PropTypes.func,
  onChangeView: PropTypes.func,
  amount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  document: PropTypes.string,
  documentType: PropTypes.string,
  disabled: PropTypes.bool,
  view: PropTypes.string,
  message: PropTypes.string,
  messageStyle: PropTypes.string,
  person: PropTypes.shape({
    lastname: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
  }),
  approveTransaction: PropTypes.shape({
    transaction_charge: PropTypes.string,
    transaction_code: PropTypes.string,
    transaction_date: PropTypes.string,
  })
};

DetailBalanceCharge.defaultProps = {
  onSubmit: null,
  onChangeInput: null,
  onChangeView: null,
  amount: '0',
  document: '',
  documentType: '',
  disabled: true,
  view: 'third',
  message: '',
  messageStyle: '',
  person: {
    lastname: '',
    name: '',
    username: '',
  },
  approveTransaction: {
    transaction_charge: null,
    transaction_code: null,
    transaction_date: null,
  },
};

export default withTranslation()(DetailBalanceCharge);
