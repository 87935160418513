import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { compose } from "redux";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../config/constants";
import {Form, FormElement, Panel, PrimaryButton, ConfirmAlert, AmountFormat, Loading } from '../../components';
import { confirmAlert } from 'react-confirm-alert';
import { transactionsActions } from "../../state/ducks/transactions";
import { Link } from "react-router-dom";
import {initTimer, stopTimer} from "../../util/initTimer";
import { authActions } from "../../state/ducks/auth";
import { OptionLogout } from "../../scenes/layout/components/OptionLogout/index";
import './styles.scss';

class CashClosure extends Component {
    constructor(props) {
        const { logout } = props;
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
        stopTimer();
        initTimer(this.props);
        const { fetchInfoCash } = this.props;
        fetchInfoCash();
    }
    // callApiList = () => {
    //     const { t, fetchClosureCash, showMessage } = this.props;
    //     const { cash_file_url } = this.state;
    //     fetchClosureCash({
    //         data: {
    //             cash_file_url: cash_file_url,
    //         },
    //         callback: (response, success) => {
    //             if (!success) {
    //                 showMessage({
    //                     message: t('connectionError'),
    //                     config: TOAST_CONFIG.ERROR,
    //                 });
    //             }
    //         }
    //     })
    // }

    CloseCash = (e) => {
        e.preventDefault();
        ConfirmAlert(
            { ...this.props },
            () => {
                const {
                  t, logout, showMessage,
                  transactions: {
                    approveClosureCash: { cashClosure } },
                    fetchClosureCash, fetchPdf,
                  } = this.props;
                const {  } = this.state;
                let cash_file_url, operations;
                fetchClosureCash({
                    callback: ({ success, data }) => {
                        cash_file_url = data.cashClosure.cash_file_url;
                        operations = data.cashClosure.operations;

                        if (success) {
                            this.setState(() => ({
                                message: t('finishCashClosure'),
                                messageStyle: 'has-text-success',
                            }));
                            toast.dismiss();
                            showMessage({
                                message: t('finishCashClosure'),
                                config: TOAST_CONFIG.SUCCESS,
                            });
                            fetchPdf({
                              data: {
                                url_file: cash_file_url,
                              }, callback: (success) => {
                                if (!success) {
                                  toast.dismiss();
                                  showMessage({
                                    message: t('connectionError'),
                                    config: TOAST_CONFIG.ERROR,
                                  });
                                }
                              },
                            });
                        } else {
                            this.setState(() => ({
                                message: t('errorCashClosure'),
                                messageStyle: 'has-text-danger',
                            }));
                            toast.dismiss();
                            showMessage({
                                message: t('errorCashClosure'),
                                config: TOAST_CONFIG.ERROR,
                            });
                        }
                    }
                });
                setTimeout(() => {
                  logout();
                }, 3000)
            }, 'acceptCashClosure');
    };
    render () {
      const { t,
        auth: { loading },
        transactions: { cashclosure }
      } = this.props;
      return (
        <div>
         {loading && <Loading />}
         <Fragment>
           <Panel headingText={t('titlecash')}>

             <div className='columns content-cards'>
               <div className='column'>
                 <div className='card-balance'>
                  <div className='balance-box'>{t('balanceBox')}</div>
                  <div className='amount-balance'>
                    <AmountFormat
                      name='balance'
                      value={cashclosure.balance}
                      currency='AR$'
                      displayType="text"
                      allowNegative
                    />
                  </div>
                 </div>
               </div>

               <div className='column'>
                 <div className='card-balance'>
                   <div className='balance-box'>{t('balanceCash')}</div>
                   <div className='amount-balance'>
                     <AmountFormat
                       name='balance'
                       value={cashclosure.cash}
                       currency='AR$'
                       displayType="text"
                       allowNegative
                     />
                   </div>
                 </div>
               </div>
             </div>

             <div className="columns is-centered">
                 <div className={`column is-full has-text-centered title-center`}>{t('detailcash')}</div>
             </div>

             <div className='columns is-centered is-mobile'>
               <div className='column is-6'>
                 <div className='label-box title-left'>{t('openingDate')}:</div>
               </div>
               <div className='column is-6'>
                 <div className='title-right'>{cashclosure.opening_date}</div>
               </div>
             </div>

             <div className='columns is-centered is-mobile'>
               <div className='column is-6'>
                 <div className='label-box title-left'>{t('numberOperations')}:</div>
               </div>
               <div className='column is-6'>
                 <div className='title-right'>{cashclosure.operations}</div>
               </div>
             </div>

             <div className='columns is-centered is-mobile'>
               <div className='column is-6'>
                 <div className='label-box title-left'>{t('moreInformation')}:</div>
               </div>
               <div className='column is-6'>
                 <div className='title-right'>
                   {(cashclosure.operations != 0) &&
                   <Link to={{ pathname: '/detail-cash'}} >
                     <button className="button primary-button button-detailcash">
                       {t('viewDetails')}
                     </button>
                   </Link>}
                 </div>
               </div>
             </div>
             <div className='has-text-centered'>
               <button
                 onClick={this.CloseCash}
                 className='button button-closecash'
                 type='button'
               >
                 {t('closeBoxLogout')}
             </button>
           </div>
         </Panel>
       </Fragment>
      </div>
    );
  }
}

CashClosure.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  login: PropTypes.func.isRequired,
  fetchClosureCash: PropTypes.func,
};

CashClosure.defaultProps = {
  showMessage: () => {},
  fetchClosureCash: () => {},
};

const mapStateToProps = ({ auth, transactions }) => (
  { auth, transactions, }
);
export default compose(connect(mapStateToProps, {
    ...authActions,
    ...transactionsActions,
}),withTranslation())(CashClosure);
