import { toast }             from 'react-toastify';
import { authTypes }         from '../ducks/auth';
import { transactionsTypes } from '../ducks/transactions';
import { TOAST_CONFIG }      from '../../config/constants';
import {usersTypes} from "../ducks/users";

const endFetch = ({ dispatch }) => next => (action) => {
  next(action);

  const types = [
    authTypes.END_FETCH,
    transactionsTypes.END_FETCH,
    usersTypes.END_FETCH,
  ];

  if (!types.includes(action.type) || !action.payload) {
    return;
  }

  const { payload } = action;

  if (payload.hasOwnProperty('data') &&
    payload.data.hasOwnProperty('success') && !payload.data.success) {
    dispatch({
      type: authTypes.MESSAGE,
      payload: {
        message: payload.data.data.message ?? payload.data.data[0].message,
      },
      meta: {
        config: TOAST_CONFIG.INFO,
      },
    });
  }
};

const messages = () => next => (action) => {
  const types = [
    authTypes.MESSAGE,
    transactionsTypes.MESSAGE,
    usersTypes.MESSAGE,
  ];

  if (!types.includes(action.type)) {
    return next(action);
  }

  const { message = 'THERE IS NO MESSAGE' } = action.payload;
  const { config = TOAST_CONFIG.INFO } = action.meta;
  toast(message, config);
};

export default [endFetch, messages];
