import React               from 'react';
import { connect }         from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import {
  AccountDetail,
  AccountList,
  BalanceChargePassengers,
  CashClosure,
  ContactBook,
  Dashboard,
  DepositRegister,
  DetailCash,
  DetailLot,
  DetailMovementsOperators,
  ExitLot,
  Layout,
  ListLot,
  ListMovementsOperators,
  OperatorAgency,
  profile,
  SignIn,
  SignUp,
  StateLotList,
  Transfer,
  ChargeQr,
  Retreats,
}                          from '../scenes';

const mapStateToProps = ({ auth }) => ({
  auth,
});

export const privateRoute = (WrappedComponent) => connect(mapStateToProps)(
  ({ auth, ...rest }) => (auth.logged === true
      ? <WrappedComponent auth={auth} {...rest} />
      : <Redirect to="/login" />
  ),
);

export const RouteWithSubRoutes = (route) => (
  <Route
    path={route.path}
    exact={route.exact}
    render={(props) => (
      <route.component {...props} {...route} />
    )}
  />
);

export const NotFound = () => (
  <Redirect to="/" />
);

const routes = [
  {
    path: '/',
    component: privateRoute(SignIn),
    exact: true,
  }, {
    path: '/login',
    component: SignIn,
    exact: true,
  }, {
    path: '/sign-up',
    component: SignUp,
    exact: true,
  }, {
    path: '/',
    component: privateRoute(Layout),
    routes: [
      {
        path: '/dashboard',
        component: privateRoute(Dashboard),
        exact: true,
      }, {
        path: '/profile',
        component: privateRoute(profile),
        exact: true,
      }, {
        path: '/account-list',
        component: privateRoute(AccountList),
        exact: true,
      }, {
        path: '/account-details',
        component: privateRoute(AccountDetail),
        exact: true,
      }, {
        path: '/balance-charge-passengers',
        component: privateRoute(BalanceChargePassengers),
        exact: true,
      }, {
        path: '/cash-closure',
        component: privateRoute(CashClosure),
        exact: true,
      }, {
        path: '/detail-cash',
        component: privateRoute(DetailCash),
        exact: true,
      },
      {
        path: '/deposit-register',
        component: privateRoute(DepositRegister),
        exact: true,
      }, {
        path: '/list-of-lots-unclosed',
        component: privateRoute(ListLot),
        exact: true,
      }, {
        path: '/lot-closed-successful',
        component: privateRoute(ExitLot),
        exact: true,
      }, {
        path: '/admin-account-list',
        component: privateRoute(ListMovementsOperators),
        exact: true,
      }, {
        path: '/detail-movements-operation',
        component: privateRoute(DetailMovementsOperators),
        exact: true,
      }, {
        path: '/state-lots',
        component: privateRoute(StateLotList),
        exact: true,
      }, {
        path: '/detail-lot',
        component: privateRoute(DetailLot),
        exact: true,
      }, {
        path: '/operators-agency',
        component: privateRoute(OperatorAgency),
        exact: true,
      }, {
        path: '/contact-book',
        component: privateRoute(ContactBook),
        exact: true,
      }, {
        path: '/make-transfer',
        component: privateRoute(Transfer),
        exact: true,
      },
      {
        path: '/charge-qr',
        component: privateRoute(ChargeQr),
        exact: true,
      },
      {
        path: '/retreats',
        component: privateRoute(Retreats),
        exact: true,
      },
    ],
  },
  {
    component: NotFound,
  },
];

export default routes;
