import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    AmountFormat, ContentHeader, DefaultButton, Filter, Loading, TableList, TransactionRow
} from '../../../components';
import { initTimer, stopTimer } from '../../../util/initTimer';
import { transactionsActions } from '../../../state/ducks/transactions';
import {authActions} from "../../../state/ducks/auth";

class DetailCash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            transactionType: '',
            amountTotal: 0.00,
            page: 0,
            month: '',
            dateStart: new Date(),
            dateEnd: new Date(((new Date()).setDate((new Date()).getDate() + 1))),
            operations: '',
        }
    }

    componentDidMount() {
        const { fetchTransactionsTypes } = this.props;
        stopTimer();
        initTimer(this.props);
        fetchTransactionsTypes();
    }
    render() {

        const { page } = this.state;
        const { t,
            transactions: { loading,
                cashclosure: {
                    transactions,
                    operations,  } } } = this.props;
        return (
            <div>
                {loading && <Loading />}
                <Fragment>
                    <div className="wow animated slideInDown">
                        <div className="wow animated slideInDown">

                            <Filter
                                title='Funciones Administrativas: Caja'
                                subtitle='Detalles de caja'
                            >
                            </Filter>
                        </div>
                        <ContentHeader
                            headingText={(
                                <Link to="/cash-closure">
                                    <DefaultButton name={t('goBack')}/>
                                </Link>
                            )}>
                            <TableList
                                tableClasses="floating-table"
                                totalElements={parseFloat(operations)}
                                onChangePage={this.onChangePage}
                                forcePage={page}
                                head={(
                                    <tr>
                                        <th>{t('date')} / {t('hour')}</th>
                                        <th>{t('typeOfTransaction')}</th>
                                        <th>{t('amount')}</th>
                                        <th> {t('status')}</th>
                                    </tr>
                                )}
                                colNumber={5}
                                lengthData={operations || 0}
                            >
                                {transactions && (transactions.map(transaction => (
                                    <TransactionRow
                                        key={transaction.id}
                                        helper='detailReport'
                                    >
                                        <td>{transaction.created_at}</td>
                                        <td>{transaction.transaction_type}</td>
                                        <td>
                                            <AmountFormat
                                                name="balance"
                                                value={transaction.transaction_amount}
                                                currency={transaction.product}
                                                displayType="text"
                                                allowNegative
                                            />
                                        </td>
                                        <td>{t(transaction.status)}</td>
                                    </TransactionRow>
                                )))
                                }
                            </TableList>
                        </ContentHeader>
                    </div>
                </Fragment>
            </div>
        )
    }
}

DetailCash.propTypes = {
    t: PropTypes.func.isRequired,
    showMessage: PropTypes.func,
    fetchTransactionsTypes: PropTypes.func,
    fetchListTransactions: PropTypes.func.isRequired,
};

DetailCash.defaultProps = {
    showMessage: () => {
    },
    fetchTransactionsTypes: () => {},
};

const mapStateToProps = ({ auth, transactions, }) => (
    {
        auth,
        transactions,
    }
);
export default compose(
    connect(mapStateToProps, {
        ...authActions,
        ...transactionsActions,
    }),
    withTranslation(),
)(DetailCash);
