import React                            from 'react';
import PropTypes                        from 'prop-types';
import { DefaultButton, PrimaryButton } from '../index';
import './styles.scss';


const Form = ({
                className,
                onSubmit,
                children,
                submitText,
                disabledSecondButton,
                disabled,
                secondButton,
                handleSecondButton,
              }) => (
  <div className={`Form  ${className}`}>
    <form
      action=""
      onSubmit={onSubmit}
    >
      {children}
      {(submitText || secondButton) && (
        <div className="buttons-action field is-grouped is-jc-center">
          {secondButton && (
            <DefaultButton
              name={secondButton}
              disabled={disabledSecondButton}
              type="button"
              onClick={handleSecondButton}
            />
          )}
          {submitText && (
            <PrimaryButton
              name={submitText}
              disabled={disabled}
              type="submit"
            />
          )}
        </div>
      )}
    </form>
  </div>
);

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  handleSecondButton: PropTypes.func,
  submitText: PropTypes.string,
  secondButton: PropTypes.string,
  buttonClasses: PropTypes.string,
  disabled: PropTypes.bool,
  disabledSecondButton: PropTypes.bool,
};

Form.defaultProps = {
  className: '',
  buttonClasses: '',
  disabled: false,
  disabledSecondButton: false,
  handleSecondButton: null,
  secondButton: '',
  onSubmit: null,
  submitText: '',
};

export default Form;
