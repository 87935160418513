import React from 'react';
import { withTranslation } from 'react-i18next';
import { Panel, Form, FormElement, AmountFormat } from '../../../../components';
import './style.scss';

const FormQr = ({
  t, amount, currency, concept, onSubmit, disabled, handleAmountChange, handleInputChange,
}) => {
  return (
    <Panel headingText={t('paymentQr')}>
      <Form
        submitText={t('continue')}
        onSubmit={e => onSubmit(e)}
        disabled={disabled}
        className={'form-content'}
      >
        <FormElement
          name='amount'
          label={`${t('amountCollect')} ${currency}`}
          value={amount}
          typeElement='amountFormat'
          controlClasses='input-qr'
          onChange={e => handleAmountChange(e)}
        />
        <FormElement
          name='concept'
          label={`${t('collectionConcept')} (${t('optional')})`}
          value={concept}
          typeElement={'input'}
          controlClasses='input-qr'
          onChange={e => handleInputChange(e)}
        />
      </Form>
    </Panel>
  );
}

export default withTranslation()(FormQr);
