import React, { Component, Fragment }                                              from 'react';
import { withTranslation }                                                         from 'react-i18next';
import { toast }                                                                   from 'react-toastify';
import PropTypes                                                                   from 'prop-types';
import { compose }                                                                 from 'redux';
import { connect }                                                                 from 'react-redux';
import { Link }                                                                    from 'react-router-dom';
import { AmountFormat, ContentHeader, Filter, Loading, TableList, TransactionRow } from '../../../components';
import { initTimer, stopTimer }                                                    from '../../../util/initTimer';
import { transactionsActions }                                                     from '../../../state/ducks/transactions';
import { TOAST_CONFIG }                                                            from '../../../config/constants';
import { authActions }                                                             from "../../../state/ducks/auth";

class ExitLot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_lot: '',
    }
  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
    this.callApiList();
  }

  callApiList = () => {
    const {
      t, showMessage, fetchTransactionsByLot,
      transactions: { lotClosed }
    } = this.props;
    const { id_lot } = this.state;
    fetchTransactionsByLot({
      data: {
        id_lot: lotClosed !== undefined ? lotClosed.id_lot : '',
        pageSize: 1000,
      }, callback: (success, data) => {
        if (!success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  render() {
    const {
      t, transactions: {
        transactionsByLot: { list, totalElements },
        lotClosed,
        loading
      }
    } = this.props;
    return (
      <div>
        {loading && <Loading />}
        <Fragment>
          <Filter title={t('funcAdmin')} subtitle={t('lotClosure')} />
          <div className='last-close'>{t('lotSuccess')}</div>
          <div className='date'>{t('lotNumber')}: {lotClosed.lot_number}</div>
          <div className='transacctions-lot'>{t('lotTransactions')}</div>
          <ContentHeader>
            <TableList
              tableClasses="floating-table"
              head={(
                <tr>
                  <th>{t('date')} / {t('hour')}</th>
                  <th>{t('typeOfTransaction')}</th>
                  <th>{t('amount')}</th>
                  <th>{t('commission')}</th>
                </tr>
              )}
              colNumber={4}
              lengthData={totalElements !== undefined ? totalElements : 0}
            >
              {list && (list.map(transaction => (
                <TransactionRow key={transaction.id}>
                  <td>{transaction.date}</td>
                  <td>{transaction.type}</td>
                  <td><AmountFormat
                    name="balance"
                    value={transaction.amount}
                    currency={'AR$'}
                    displayType="text"
                    allowNegative
                  />
                  </td>
                  <td>
                    <AmountFormat
                      name="balance"
                      value={transaction.commission}
                      currency={'AR$'}
                      displayType="text"
                      allowNegative
                    />
                  </td>
                </TransactionRow>
              )))
              }
            </TableList>
            <div className='last-close'>{t('lotResult')}</div>
            <div className='date'>
              <AmountFormat
                name="balance"
                value={lotClosed.lot_amount}
                currency={'AR$'}
                displayType="text"
                allowNegative
              />
            </div>
            <div className='close-lot'>
              <Link to={{ pathname: '/list-of-lots-unclosed', }}>
                <button className='button button-lot is-outlined'>{t('finish')}</button>
              </Link>
            </div>
          </ContentHeader>
        </Fragment>
      </div>
    )
  }
}

ExitLot.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchTransactionsByLot: PropTypes.func,
};

ExitLot.defaultProps = {
  showMessage: () => {},
  fetchTransactionsByLot: () => {},
};

const mapStateToProps = ({ auth, transactions, }) => (
  { auth, transactions }
);
export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
    ...authActions,
  }),
  withTranslation(),
)(ExitLot);
