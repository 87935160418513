
import usersActions   from '../ducks/users/actions';
import usersTypes from "../ducks/users/types";

const fetchOperatorAgency = ({ dispatch }) => next => (action) => {
    next(action);
    if (action.type !== usersTypes.FETCH_OPERATOR_AGENCY) {
        return;
    }
    const {callback } = action.payload || {};

    dispatch({
        type: usersTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: `agency/list-operators`,
            },
            authorization: true,
            onStart: usersActions.startFetch,
            onComplete: ({ data }) => {
                dispatch(usersActions.setOperatorAgency(data));
                callback(data);
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: usersActions.endFetch,
        },
    });
};

const enableDisabledOperator = ({ dispatch }) => next => (action) => {
    next(action);
    if (action.type !== usersTypes.ENABLE_DISABLED_OPERATOR) {
        return;
    }
    const {data, callback} = action.payload || {};

    dispatch({
        type: usersTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/agency/update-user-status',
                data,
            },
            authorization: true,
            onStart: usersActions.startFetch,
            onComplete: (responseData) => {
                callback(responseData.data);
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: usersActions.endFetch,
        },
    });
};

const fetchContactBook = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_CONTACT_BOOK) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/agency/list-contacts`,
        data,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: ({ data: { data} }) => {
        dispatch(usersActions.updateContactBook({ data }))
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
}

const fetchDeleteContact = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_DELETE_CONTACT) {
    return;
  }
  const { data, callback } = action.payload || {};

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/agency/delete-contact`,
        data,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: ({ data: { data } }) => {
        if (data.success) {
          dispatch(usersActions.updateDeleteContact({ data }));
        }
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

export default [
    fetchOperatorAgency,
    enableDisabledOperator,
    fetchContactBook,
    fetchDeleteContact,
];
