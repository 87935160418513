import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {
    Form, Panel, TableList, AmountFormat
} from '../../../../components';

const FinalRetreats = ({
                           t, step, voucher, onResetView, documentType,
                           person: {user, email, document}
                       }) => {
    return (
        <Panel headingText={t('voucher')}>
            <Form
                submitText={step === 'third' ? t('finish') : null}
                onSubmit={(e) => onResetView(e)}
            >
                <TableList tableClasses='floating-table' colNumber={1} lengthData={1}>
                    <tr>
                        <th className='width-th'>
                            <div className='label-th'>{t('date')}:</div>
                        </th>
                        <td>
                            <div className='value-td'>
                                {voucher[0].transaction_date_full}
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <th className='width-th'>
                            <div className='label-th'>{t('passenger')}:</div>
                        </th>
                        <td>
                            <div className='value-td'>{user}</div>
                        </td>
                    </tr>

                    <tr>
                        <th className='width-th'>
                            <div className='label-th'>{t('documentType')}:</div>
                        </th>
                        <td>
                            <div className='value-td'>{documentType}</div>
                        </td>
                    </tr>

                    <tr>
                        <th className='width-th'>
                            <div className='label-th'>{t('document')}:</div>
                        </th>
                        <td>
                            <div className='value-td'>{document}</div>
                        </td>
                    </tr>

                    <tr>
                        <th className='width-th'>
                            <div className='label-th'>{t('typeOfTransaction')}:</div>
                        </th>
                        <td>
                            <div className='value-td'>{voucher[0].transaction_type}</div>
                        </td>
                    </tr>

                    <tr>
                        <th className='width-th'>
                            <div className='label-th'>{t('transactionCode')}:</div>
                        </th>
                        <td>
                            <div className='value-td'>{voucher[0].transaction_code}</div>
                        </td>
                    </tr>

                    <tr>
                        <th className='width-th'>
                            <div className='label-th'>{t('amount')}:</div>
                        </th>
                        <td>
                            <div className='value-td'>
                                <AmountFormat
                                    name='balance'
                                    value={voucher[0].transaction_amount}
                                    currency={'AR$'}
                                    displayType="text"
                                    allowNegative
                                />
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <th className='width-th'>
                            <div className='label-th'>{t('transactionCharge')}:</div>
                        </th>
                        <td>
                            <div className='value-td'>
                                <AmountFormat
                                    name='balance'
                                    value={voucher[0].transaction_charge}
                                    currency={'AR$'}
                                    displayType="text"
                                    allowNegative
                                />
                            </div>
                        </td>
                    </tr>

                </TableList>
            </Form>
        </Panel>
    );
}

export default withTranslation()(FinalRetreats);
