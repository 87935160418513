import React               from 'react';
import { Link }            from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './styles.scss';
import { ConfirmAlert, PrimaryButton } from "../../../../components";

import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import {TOAST_CONFIG} from "../../../../config/constants";


const handleLotsInfo = (props) =>{
  const { t, logout } = props;
  const options = {
    customUI: ({onClose}) => (
        <div className="box-success columns is-multiline">
            <div className="content-box column is-full has-text-centered">
                <div className="columns is-centered">
                    <div>
                        <img height="40" src='./alert.png' alt='alerta' className='alerta'/>
                    </div>
                </div>

                <div className="columns is-centered">
                    <div className={`column is-full has-text-centered title-message`}>
                        {t('logout')}
                    </div>
                </div>
                <div className="columns is-centered">
                    <div className={`has-text-centered`}>
                        {t('closeCash')}
                    </div>
                </div>
            </div>
            <div className="btn-accept column">
                <PrimaryButton name={t('yes')}
                               onClick={() => {
                                   onClose(window.location.href = '/cash-closure');
                               }}/>
            </div>

            <div className="btn-accept column">
                <PrimaryButton name={t('no')} onClick={() => {
                    logout();onClose();
                }}/>

            </div>
        </div>
        /*<div className="box-success columns is-multiline">
          <div className="content-box column is-full has-text-centered">

            <div className="columns is-centered">
              <div className={`column is-full has-text-centered title-message`}>
                {t('logout')}
              </div>
            </div>
            <span className="text-detail">{t('closeCash')}</span>
          </div>
          <div className="btn-accept column">
            <PrimaryButton name={t('yes')} onClick={() => {

              onClose(window.location.href = '/cash-closure');
            }}/>

            <PrimaryButton name={t('no')} onClick={() => {
              logout();
            }}/>

          </div>
        </div>*/
    ),
    closeOnEscape: false,
    closeOnClickOutside: false,
  };

  return confirmAlert(options);
}


const CloseCash = (e, props) => {
  e.preventDefault();
  ConfirmAlert(
      { ...props },
      () => {
        const { t, logout } = props;

        /*fetchClosureCash({
          callback: ({ success, data }) => {

            console.log('data', data);
            cash_file_url = data.cashClosure.cash_file_url;
            operations = data.cashClosure.operations;

            if (success) {
              this.setState(() => ({
                message: t('finishCashClosure'),
                messageStyle: 'has-text-success',
              }));
              toast.dismiss();
              showMessage({
                message: t('finishCashClosure'),
                config: TOAST_CONFIG.SUCCESS,
              });
            } else {
              this.setState(() => ({
                message: t('errorCashClosure'),
                messageStyle: 'has-text-danger',
              }));
              toast.dismiss();
              showMessage({
                message: t('errorCashClosure'),
                config: TOAST_CONFIG.ERROR,
              });
            }
          }
        });*/
        setTimeout(() => {
          logout();
        }, 3000)
      }, 'acceptCashClosure');
};

const OptionLogout = (props) => {

  const { name, logout, toggled, tclass, t } = props;

  return (
    <div className={`boxSettings animated fadeInUp ${tclass} ${(toggled) && 'open'}`}>
      <div className="box">
        <ul className="sort-click">
          <li className="box-icon">
            <Link
              to="/profile"
            >
              <i className="icon fas fa-user" aria-hidden="true" />
              {t('profile')}
            </Link>
          </li>
          <li className="box-icon">
            <Link
              to=''
              //onClick={logout}
                onClick={(event) => {
                  //CloseCash(event, props);
                  handleLotsInfo(props);
                }}
            >
              <i className="icon fas fa-power-off" aria-hidden="true" />
              {t('logout')}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default withTranslation()(OptionLogout);
