import React               from 'react';
import { Form, TextInput } from '../../../../../components';
import './styles.scss';
import { withTranslation } from "react-i18next";
import   Recaptcha         from 'react-recaptcha';
import { KEY_CAPTCHA }     from "../../../../../config/constants";

const FormLogin = (props) => {
  const {
    t,
    handleSubmit,
    options,
    handleInputChange,
    requiredUser,
    requiredPassword,
    verifyCallback,
    callback,
    isCaptcha,
    viewCaptcha,
  } = props;
  return (
    <div className="FormLogin">
      <Form
        className="column"
        submitText='Ingresar'
        onSubmit={handleSubmit}
        buttonClasses="btn-login"
      >
        <TextInput
          tabIndex="1"
          name="username"
          placeholder='Usuario'
          type="text"
          onChange={handleInputChange}
          inputClasses="input-login"
        />
        <div style={requiredUser} className="required req-user">{t('requiredUser')}</div>
        <TextInput
          tabIndex="2"
          placeholder='Contraseña'
          type="password"
          name="password"
          onChange={handleInputChange}
          inputClasses="input-login"
        />
        <div style={requiredPassword} tabIndex="3" className="required req-pass">requiredPassword</div>

        <div style={viewCaptcha}>
          <Recaptcha
              sitekey={ KEY_CAPTCHA }
              render="explicit"
              verifyCallback={verifyCallback}
              onloadCallback={callback}
              hl="es"
          />
          <div style={isCaptcha} className="required req-capt">{t('requiredCaptcha')}</div>
        </div>
      </Form>
    </div>
  );
};

export default withTranslation()(FormLogin);
