import {transactionsTypes} from '../ducks/transactions';
import transactionsActions from '../ducks/transactions/actions';
import {API_URL, HEADERS} from "../../config/constants";
import * as fileSaver from "file-saver";

const checkBalance = ({dispatch}) => next => (action) => {
    next(action);

    if (action.type !== transactionsTypes.CHECK_BALANCE) {
        return;
    }
    const {
        callback = () => {
        },
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: '/agency/balance',
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {success, data}}) => {
                if (success) {
                    dispatch(transactionsActions.updateBalance({data}));
                    callback();
                }
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const fetchListTransactions = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.FETCH_LIST_TRANSACTIONS) {
        return;
    }
    const {data, callback, data: {export: exportExcel}} = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: `/pos/last-transactions`,
                data,
                responseType: exportExcel ? 'blob' : 'json',
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: (responseData) => {
                if (exportExcel) {
                    const blob = responseData.data
                    const file = new Blob([blob], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    fileSaver.saveAs(file, 'Listado de operaciones.xlsx');
                } else {
                    const transactions = responseData.data.data;
                    dispatch(transactionsActions.updateListTransactions({data: transactions}));
                    callback(responseData.data);
                }
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
}

const fetchDocumentType = ({dispatch}) => next => (action) => {
    next(action);

    if (action.type !== transactionsTypes.FETCH_DOCUMENT_TYPE) {
        return;
    }
    const {
        callback = () => {
        },
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: '/document/document-type',
            },
            authorization: false,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {data, success}}) => {
                if (success) {
                    dispatch(transactionsActions.updateDocumentType({data}));
                    callback();
                }
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const fetchVerifyPassenger = ({dispatch}) => next => (action) => {
    next(action);

    if (action.type !== transactionsTypes.FETCH_VERIFY_PASSENGER) {
        return;
    }
    const {
        data,
        callback = () => {
        },
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/pos/validate-data-recharge',
                data,
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {success, data}}) => {
                if (success) {
                    dispatch(transactionsActions.updateVerifyPassenger({data}));
                    callback({success, data});
                }
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};


const fetchRechargeBalance = ({dispatch}) => next => (action) => {
    next(action);

    if (action.type !== transactionsTypes.FETCH_RECHARGE_BALANCE) {
        return;
    }
    const {
        callback = () => {
        },
        data,
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/pos/recharge',
                data,
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {success, data}}) => {
                if (success) {
                    dispatch(transactionsActions.updateRechargeBalance({data}));
                } else {
                    dispatch(transactionsActions.updateRechargeBalance({data: null}));
                }
                callback({success, data});
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const fetchTransactionsTypes = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.FETCH_TRANSACTIONS_TYPES) {
        return;
    }
    const {
        callback = () => {
        }
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: '/agency/transaction-types'
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {data}}) => {
                dispatch(transactionsActions.updateTransactionsTypes({data}));
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
}

const fetchBanks = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.FETCH_BANKS) {
        return;
    }
    const {
        callback = () => {
        }
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: '/agency/banks'
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {data}}) => {
                dispatch(transactionsActions.updateBanks({data}));

            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
}

const fetchUploadDeposit = ({dispatch}) => next => (action) => {
    next(action);

    if (action.type !== transactionsTypes.FETCH_UPLOAD_DESPOSIT) {
        return;
    }
    const {
        callback = () => {
        },
        data, token, expToken
    } = action.payload || {};

    fetch(`${API_URL}agency/load-deposit`, {
        method: 'POST',
        body: data,
        headers: HEADERS({
            token,
            expToken,
        }),
    })
        .then(response => response.json())
        .then((response) => {
            callback(response);
            if (response.success) {
                dispatch(transactionsActions.updateUploadDeposit(response.data));
            } else {
                dispatch(transactionsActions.updateUploadDeposit({data: null}));
            }
        })
        .catch((response) => {
            callback(response);
        });

};

const fetchAdminList = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.FETCH_ADMIN_LIST) {
        return;
    }
    const {data, callback, data: {export: exportExcel}} = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: `/agency/last-transactions`,
                data,
                responseType: exportExcel ? 'blob' : 'json',
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: (responseData) => {
                if (exportExcel) {
                    const blob = responseData.data
                    const file = new Blob([blob], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    fileSaver.saveAs(file, 'Cuenta, listado de operaciones.xlsx');
                } else {
                    const accountList = responseData.data.data;
                    dispatch(transactionsActions.updateAdminList({data: accountList}));
                    callback(responseData.data);
                }
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
}

const fetchListLotClose = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.FETCH_LIST_LOT_CLOSE) {
        return;
    }
    const {
        data, callback = () => {
        }
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/agency/close-lot',
                data
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {success, data}}) => {
                dispatch(transactionsActions.updateListLotClose({data}));
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
}

const fetchLotClosed = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.FETCH_LOT_CLOSED) {
        return;
    }
    const {
        callback = () => {
        }
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/agency/closed-lot'
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data}) => {
                if (data.data.id_lot !== undefined)
                    dispatch(transactionsActions.updateLotClosed(data));
                callback(data)
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
}

const fetchLotListClosed = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.FETCH_LOT_LIST_CLOSED) {
        return;
    }
    const {data, callback} = action.payload || {};

    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: `/agency/lots`,
                data,
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {success, data}}) => {
                dispatch(transactionsActions.updateLotListClosed({data}));
                callback(success, data)
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const fetchTransactionsByLot = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.FETCH_TRANSACTIONS_BY_LOT) {
        return;
    }
    const {data, callback} = action.payload || {};

    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: `/agency/transactions-by-lot`,
                data,
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {success, data}}) => {
                dispatch(transactionsActions.updateTransactionsByLot({data}));
                callback(success, data)
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};
const fetchStateLots = ({dispatch}) => next => (action) => {
    next(action);

    if (action.type !== transactionsTypes.FETCH_STATE_LOTS) {
        return;
    }
    const {
        callback = () => {
        }
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: '/agency/list-status-lots',
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {data, success}}) => {
                if (success) {
                    dispatch(transactionsActions.updateStateLots({data}));
                }
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const fetchLotsList = ({dispatch}) => next => (action) => {
    next(action);

    if (action.type !== transactionsTypes.FETCH_LOTS_LIST) {
        return;
    }
    const {data, callback, data: {export: exportExcel}} = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: 'agency/lots',
                data,
                responseType: exportExcel ? 'blob' : 'json',
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: (responseData) => {
                if (exportExcel) {
                    const blob = responseData.data
                    const file = new Blob([blob], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    fileSaver.saveAs(file, 'Listado de lotes y comisiones.xlsx');
                } else {
                    const lotList = responseData.data.data;
                    dispatch(transactionsActions.updateLotsList({data: lotList}));
                    callback(responseData.data);
                }
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};


const fetchInfoCash = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.FETCH_INFO_CASH) {
        return;
    }
    const {
        callback = () => {
        }
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: '/pos/info-cash'
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {data, success}}) => {
                if (success) {
                    dispatch(transactionsActions.updateInfoCash({data}));
                    callback(data);
                }
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const fetchClosureCash = ({dispatch}) => next => (action) => {
    next(action);

    if (action.type !== transactionsTypes.FETCH_CLOSURE_CASH) {
        return;
    }
    const {
        callback = () => {
        },
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/pos/closure-cash',
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {success, data}}) => {
                if (success) {
                    dispatch(transactionsActions.updateClosureCash({data}));
                } else {
                    dispatch(transactionsActions.updateClosureCash({data: null}));
                }
                callback({success, data});
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const fetchTransactionsTypesAccount = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.FETCH_TRANSACTIONS_TYPES_ACCOUNT) {
        return;
    }
    const {
        callback = () => {
        }
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: '/agency/transaction-types-account'
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {data}}) => {
                dispatch(transactionsActions.updateTransactionsTypesAccount({data}));
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
}

const checkChargeTransfer = ({dispatch}) => next => (action) => {
    next(action);

    if (action.type !== transactionsTypes.FETCH_CHARGE_TRANSFER) {
        return;
    }
    const {data, callback} = action.payload || {};

    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: `api/check-charge-amount?id_person=${data.id_person}&transaction_amount=${data.amount}&account=${data.cbu != undefined ? data.cbu : data.alias}`,
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data}) => {
                if (data.success) {
                    dispatch(transactionsActions.getChargeTransfer({
                        success: data.success,
                        data: data.data,
                    }));
                    callback({success: data.success});
                }
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const saveTransaction = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.SAVE_TRANSACTION) {
        return;
    }
    const {data, callback} = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/api/transaction-bind-cvu',
                data,
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data}) => {
                callback(data);
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const checkCbu = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.CHECK_CBU) {
        return;
    }
    const {data, callback} = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/api/verification-alias-cbu',
                data,
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data}) => {
                console.log('DATA', data.data);
                if (data.hasOwnProperty('data')
                    && data.hasOwnProperty('success') && !data.success) {
                    const str = data.data.message || 'Problemas de conexión, intentá más tarde';
                    const result = str.replace('Alias', 'CBU/CVU o Alias');
                    data.data.message = result;
                }
                callback(data);
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const validateAmountLimits = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.VALIDATE_AMOUNT_LIMITS) {
        return;
    }
    const {data, callback} = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/agency/validate-limits-amount',
                data,
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data}) => {
                callback(data);
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const validateTimeTransfer = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.VALIDATE_TIME_TRANSFER) {
        return;
    }
    const {
        callback = () => {
        }
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: '/agency/validate-schedule',
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data}) => {
                callback(data);
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const fetchPdf = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.FETCH_PDF) {
        return;
    }
    const {data, callback} = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: `/api/download-file`,
                data,
                responseType: data ? 'blob' : 'json',
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: (responseData) => {
                if (responseData) {
                    const blob = responseData.data
                    const pdf = new Blob([blob], {type: 'application/pdf'});
                    var fileURL = URL.createObjectURL(pdf);
                    window.open(fileURL);
                } else {
                    const image = responseData.data.data;
                    // dispatch(transactionsActions.fetchImgSelfie({ data: image }));
                    callback(responseData.data);
                }
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const fetchChargeQr = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.FETCH_CHARGE_QR) {
        return;
    }
    const {data, callback} = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/pos/qr-request',
                data,
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {data, success}}) => {
                dispatch(transactionsActions.updateChargeQr({data}));
                callback({success, data})
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
}

const cancelChargeQr = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.CANCEL_CHARGE_QR) {
        return;
    }
    const {
        callback = () => {
        }
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: '/pos/cancel-qr-request',
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data}) => {
                callback(data);
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const fetchStatusChargeQr = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.FETCH_STATUS_CHARGE_QR) {
        return;
    }
    const {
        callback = () => {
        }
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: '/pos/get-status-qr-request',
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data}) => {
                callback(data);
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const fetchVerifyExtraction = ({dispatch}) => next => (action) => {
    next(action);

    if (action.type !== transactionsTypes.FETCH_VERIFY_EXTRACTION) {
        return;
    }
    const {
        data, callback = () => {
        }
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/pos/validate-data-extraction',
                data,
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {success, data}}) => {
                if (success) {
                    dispatch(transactionsActions.updateVerifyExtraction({data}));
                    callback({success, data});
                }
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const cancelExtraction = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.CANCEL_EXTRACTION) {
        return;
    }
    const {
        data, callback = () => {
        }
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/pos/cancel-extraction',
                data
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data}) => {
                callback(data);
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

const confirmExtraction = ({dispatch}) => next => (action) => {
    next(action);
    if (action.type !== transactionsTypes.CONFIRM_EXTRACTION) {
        return;
    }
    const {
        data, callback = () => {
        }
    } = action.payload || {};
    dispatch({
        type: transactionsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/pos/extraction',
                data
            },
            authorization: true,
            onStart: transactionsActions.startFetch,
            onComplete: ({data: {success, data}}) => {
                if (success) {
                    dispatch(transactionsActions.updateConfirmExtraction({data}));
                    callback({success, data});
                }
            },
            onError: (response) => {
                callback(response);
            },
            onEnd: transactionsActions.endFetch,
        },
    });
};

export default [
    checkBalance,
    fetchDocumentType,
    fetchVerifyPassenger,
    fetchRechargeBalance,
    fetchListTransactions,
    fetchTransactionsTypes,
    fetchInfoCash,
    fetchClosureCash,
    fetchBanks,
    fetchUploadDeposit,
    fetchListLotClose,
    fetchAdminList,
    fetchStateLots,
    fetchLotsList,
    fetchLotClosed,
    fetchLotListClosed,
    fetchTransactionsByLot,
    fetchTransactionsTypesAccount,
    checkChargeTransfer,
    saveTransaction,
    checkCbu,
    validateAmountLimits,
    validateTimeTransfer,
    fetchPdf,
    fetchChargeQr,
    cancelChargeQr,
    fetchStatusChargeQr,
    fetchVerifyExtraction,
    cancelExtraction,
    confirmExtraction,
];
