import React, { Suspense } from 'react';
import ReactDOM            from 'react-dom';
import * as serviceWorker  from './serviceWorker';
import App                 from './App.js';
import './i18n';
import './styles/index.scss';

ReactDOM.render(
  <Suspense fallback={<Loader />}><App /></Suspense>,
  document.getElementById('root')
);

const Loader = () => (
  <div>loading...</div>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
