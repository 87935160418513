/**
 * Created by Feedback Software on 24/10/18.
 * @param children contain the body of this table, it's important use th or td inside tr
 * @param totalPages if is pass by params it means that must be added the pagination nav
 * @param title it is used by pass by params to the Panel for the title of this
 * @param head contain the head of the table, it's important pass th inside tr
 * @param currentPage manage de actual page in pagination
 * @param onChangePage manage the change of the page in the pagination, must change currentPage
 */
import React, { Component }                       from 'react';
import PropTypes                                  from 'prop-types';
import { compose }                                from 'redux';
import { connect }                                from 'react-redux';
import { withTranslation }                        from 'react-i18next';
import { toast }                                  from 'react-toastify';
import { Loading }                                from '../../components';
import { transactionsActions }                    from '../../state/ducks/transactions';
import { initTimer, stopTimer }                   from '../../util/initTimer';
import { TOAST_CONFIG }                           from '../../config/constants';
import { DetailBalanceCharge, FormBalanceCharge, FinalView } from './components';
import { convertDecimalPoint }                    from "../../util";
import SelectPassenger from "./components/selectPassengers";
import {authActions} from "../../state/ducks/auth";
import './styles.scss';

class BalanceChargePassengers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'first',
      document: '',
      documentType: '',
      amount: 0,
      message: '',
      messageStyle: '',
      passengerSelect: '',
      disabled: true,
      numReg: 0,
    };
  }

  componentDidMount() {
    const { fetchDocumentType } = this.props;
    fetchDocumentType();
  }

  onFinish = (ev) => {
    ev.preventDefault();
    const { t, fetchRechargeBalance, showMessage, transactions: { balanceData: { balance } } } = this.props;
    const { amount, passengerSelect } = this.state;
    
    const amountFormat =  amount.replace(/\./g, '').replace(',', '.');
    
    if (parseFloat(amountFormat) > parseFloat(balance)) {
      showMessage({
        message: t('insufficientBalance', { balance }),
        config: TOAST_CONFIG.ERROR,
      });
      return;
    }

    fetchRechargeBalance({
      data: {
        amount: amountFormat,
        receiver: passengerSelect.id,
      },
      callback: ({ success, data }) => {
        if (success) {
          this.setState(() => ({
            message: data.message ?? t('successOperation'),
            messageStyle: 'has-text-success',
          }));
        } else {
          this.setState(() => ({
            message: t('errorOperation'),
            messageStyle: 'has-text-danger',
          }));
        }
        this.handleChangeView('fourth');
      }
    });
  };

  handleVerifyDocument = (ev) => {
    ev.preventDefault();
    const { fetchVerifyPassenger, t, showMessage, transactions: { documentTypes } } = this.props;
    const { documentType, document } = this.state;
    fetchVerifyPassenger({
      data: {
        document_type: documentType,
        document,
      },
      callback: ({ success, data }) => {
        if (!success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        } else {
          const labelDocumentType = documentTypes.find((item) => item.id === documentType).name;
          this.setState({ labelDocumentType });
          if(data.length === 0){
            showMessage({
              message: t('notDocRegister'),
              config: TOAST_CONFIG.INFO,
            });

          }else if(data.length === 1){

            this.setState({
              passengerSelect: data[0],
              disabled: false,
              numReg: 1,
            });
            this.handleChangeView('third');

          }
          else {
            this.setState({
              numReg: data.length,
            });
            this.handleChangeView('second');
          }

        }
      },
    });
    toast.dismiss();
  }

  handleChangeView = (view = 'first') => {
    this.setState({ view });
  };

  handleInputChange = (event) => {
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  handleAmountChange = (e) => {
    const {
      target: {
        value: targetValue, name,
      },
    } = e;


    let str = targetValue

    let amount = str.trim();

    amount = amount.replace(/^0+(?!$)/g, '');

    if(amount.charAt(0) == ','){
      amount = '0'.concat(amount);
    }

    this.setState({
      [name]: amount,
    });

  }

  validateEmptyFields = () => {
    const {
      view, document, documentType, amount,
    } = this.state;
    if (view === 'first') {
      return (document === '' || documentType === '');
    } else if (view === 'second') {
      const formatAmount = parseFloat(convertDecimalPoint(amount));
      return (formatAmount <= 0);
    }
    return false;
  };

  handleResetView = () => {
    this.setState({
      view: 'first',
      document: '',
      documentType: '',
      amount: 0,
      disabled: true,
    });
  }

  handleSelectPassenger = async (ev, index) => {

    const {transactions: {rechargeBalancePerson} }= this.props;

    let passengerSelect = '';
    let disabled = true;

    rechargeBalancePerson.map((item, i) => {
      if (i === index) {
            passengerSelect = item;
            disabled = false;
      }

      });

    this.setState({passengerSelect, disabled})


  }

  render() {
    const {  transactions: { documentTypes, loading, rechargeBalancePerson, approveRechargeBalancePerson } } = this.props;
    const {
      view,
      document,
      documentType,
      amount,
      labelDocumentType,
      message,
      messageStyle,
      passengerSelect,
        disabled,
      numReg,
    } = this.state;

    const disabledForm = this.validateEmptyFields();
    if (view === 'first') {
      stopTimer();
      initTimer(this.props);
      return (
        <section>
          {loading && <Loading />}
          <FormBalanceCharge
            document={document}
            documentType={documentType}
            documentTypes={documentTypes}
            onSubmit={this.handleVerifyDocument}
            onChangeInput={this.handleInputChange}
            disabled={disabledForm}
          />
        </section>
      );
    }
    if (view === 'second') {
      stopTimer();
      initTimer(this.props);
      return (
          <section>
            {loading && <Loading />}
            <SelectPassenger
                passengers={rechargeBalancePerson}
                view={view}
                handleSelectPassenger={this.handleSelectPassenger}
                passengerSelect={passengerSelect}
                onChangeView={this.handleChangeView}
                disabled={disabled}
            />
          </section>
      );
    }
    if (view === 'third') {
      stopTimer();
      initTimer(this.props);
      return (
        <section>
          {loading && <Loading />}
          <DetailBalanceCharge
            person={passengerSelect}
            document={document}
            documentType={labelDocumentType}
            amount={amount}
            onSubmit={this.onFinish}
            onChangeInput={this.handleInputChange}
            onChangeAmount={this.handleAmountChange}
            onChangeView={this.handleChangeView}
            disabled={disabledForm}
            view={view}
            numReg={numReg}
          />
        </section>
      );
    }
    if (view === 'fourth') {
      stopTimer();
      initTimer(this.props);
      return (
        <section>
          {loading && <Loading />}
          <FinalView
            person={passengerSelect}
            document={document}
            documentType={labelDocumentType}
            amount={amount}
            message={message}
            messageStyle={messageStyle}
            approveTransaction={approveRechargeBalancePerson}
            view={view}
            onSubmit={this.handleResetView}
            disabled={false}
            numReg={numReg}
          />
        </section>
      );
    }
  }
}

BalanceChargePassengers.propTypes = {
  t: PropTypes.func.isRequired,
  fetchRechargeBalance: PropTypes.func,
  fetchDocumentType: PropTypes.func,
  fetchVerifyPassenger: PropTypes.func,
  showMessage: PropTypes.func,
  auth: PropTypes.shape({}).isRequired,
  transactions: PropTypes.shape({}).isRequired,
};

BalanceChargePassengers.defaultProps = {
  fetchRechargeBalance: null,
  fetchDocumentType: null,
  fetchVerifyPassenger: null,
  showMessage: null,
};

const mapStateToProps = ({ transactions, auth }) => ({
  transactions,
  auth,
});

export default compose(connect(mapStateToProps, {
  ...transactionsActions,
  ...authActions,
}), withTranslation())(BalanceChargePassengers);
