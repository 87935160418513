import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AmountFormat, Form, Panel, TableList } from '../../../../components';
import { toast } from 'react-toastify';
import './style.scss';

const FinalView = ({
       t,
       onSubmit,
       onChangeInput,
       amount,
       document,
       documentType,
       onChangeView,
       disabled,
       view,
       message,
       messageStyle,
       person: {
         lastname, name, username,
       },
       approveTransaction: {
         transaction_charge, transaction_code, transaction_date_full, transaction_balance,
       },
       numReg
     }) => {
       const notify = () => {
         toast.dismiss();
         toast.success(message, {
           position: toast.POSITION.TOP_CENTER
         });
       }
    return (
      <Fragment>
        <Panel headingText={t('balanceChargePassengers')}>
          {notify()}
          <Form
            submitText={view === 'fourth' ? t('finish') : null}
            onSubmit={onSubmit}
            handleSecondButton={() => onChangeView(view === 'third' && numReg > 1 ? 'second' : (view === 'third' && numReg === 1 ? 'first' : 'first'))}
            disabled={disabled}
            >
          <TableList tableClasses="floating-table" colNumber={1} lengthData={1}>
              <tr>
                <th className='width-th'>
                  <div className='label-th'>{t('date')}:</div>
                </th>
                <td >
                  <div className='value-td'>{transaction_date_full}</div>
                </td>
              </tr>

              <tr>
                <th className='width-th'>
                  <div className='label-th'>{t('transactionCode')}:</div>
                </th>
                <td>
                  <div className='value-td'>{transaction_code}</div>
                </td>
              </tr>

              <tr>
                <th className='width-th'>
                  <div className='label-th'>{t('documentType')}:</div>
                </th>
                <td>
                  <div className='value-td'>{documentType}</div>
                </td>
              </tr>

              <tr>
                <th className='width-th'>
                  <div className='label-th'>{t('document')}:</div>
                </th>
                <td>
                  <div className='value-td'>{document}</div>
                </td>
              </tr>

              <tr>
                <th className='width-th'>
                  <div className='label-th'>{t('fullName')}:</div>
                </th>
                <td>
                  <div className='value-td'>{`${name} ${lastname}`}</div>
                </td>
              </tr>

              <tr>
                <th className='width-th'>
                  <div className='label-th'>{t('email')}:</div>
                </th>
                <td>
                  <div className='value-td'>{username}</div>
                </td>
              </tr>

              <tr>
                <th className='width-th'>
                  <div className='label-th'>{t('rechargeAmount')}:</div>
                </th>
                <td>
                  <div className='value-td'>
                    <AmountFormat
                      name='comition'
                      value={(amount !== 0) ? amount : '0'}
                      currency='AR$'
                      displayType="text"
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <th className='width-th'>
                  <div className='label-th'>{t('transactionCharge')}:</div>
                </th>
                <td>
                  <div className='value-td'>
                    <AmountFormat
                      name='comition'
                      value={(transaction_charge !== 0) ? transaction_charge : '0'}
                      currency='AR$'
                      displayType="text"
                    />
                  </div>
                </td>
              </tr>

          </TableList>
          </Form>
        </Panel>
      </Fragment>
    )
  }
;

FinalView.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onChangeInput: PropTypes.func,
  onChangeView: PropTypes.func,
  amount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  document: PropTypes.string,
  documentType: PropTypes.string,
  disabled: PropTypes.bool,
  view: PropTypes.string,
  message: PropTypes.string,
  messageStyle: PropTypes.string,
  person: PropTypes.shape({
    lastname: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
  }),
  approveTransaction: PropTypes.shape({
    transaction_charge: PropTypes.string,
    transaction_code: PropTypes.string,
    transaction_date: PropTypes.string,
  })
};

FinalView.defaultProps = {
  onSubmit: null,
  onChangeInput: null,
  onChangeView: null,
  amount: '0',
  document: '',
  documentType: '',
  view: 'fourth',
  message: '',
  messageStyle: '',
  person: {
    lastname: '',
    name: '',
    username: '',
  },
  approveTransaction: {
    transaction_charge: null,
    transaction_code: null,
    transaction_date: null,
  },
};

export default withTranslation()(FinalView);
