import React                          from 'react';
import PropTypes                      from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { withTranslation }             from 'react-i18next';
import { Portal }                     from 'react-overlays';
import es                             from 'date-fns/locale/es';
import { IMG }                        from '../../config/constants';
import './styles.scss';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('es', es);

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal');
  return (
    <Portal container={el}>
      {children}
    </Portal>
  );
};
const Datepicker = (props) => {
  const {
    t,
    onChange,
    selected,
    dateFormat,
    maxDate,
    minDate,
    maxDetail,
    showMonthYearPicker,
    showTimeSelect,
    isClearable,
    placeholder,
  } = props;
  return (
    <DatePicker
      onChange={onChange}
      selected={selected}
      locale={es}
      isClearable={isClearable}
      calendarIcon={( <img src={IMG.calendar} alt={t('calendar')} /> )}
      clearIcon={( <img rc={IMG.clear} alt='Calendario' /> )}
      maxDate={maxDate}
      minDate={minDate}
      className="calendar input"
      maxDetail={maxDetail}
      popperContainer={CalendarContainer}
      timeInputLabel='Tiempo'
      timeCaption='Tiempo'
      timeFormat="h:mm aa"
      placeholderText={placeholder}
      dateFormat={dateFormat}
      showMonthYearPicker={showMonthYearPicker}
      showTimeSelect={showTimeSelect}
    />
  )
};

Datepicker.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  selected: PropTypes.instanceOf(Date),
  dateFormat: PropTypes.string,
  maxDetail: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  showMonthYearPicker: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
};
Datepicker.defaultProps = {
  onChange: () => {
  },
  selected: null,
  dateFormat: 'dd/MM/yyyy',
  maxDate: new Date(),
  minDate: null,
  maxDetail: 'year',
  showTimeSelect: false,
  showMonthYearPicker: false,
  placeholder: '',
  isClearable: true,
};
export default withTranslation()(Datepicker);
