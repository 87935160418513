import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import TextInput            from '../TextInput';

class CbuFormat extends Component {
    constructor(props) {
        super(props);
        this.handlerInputChanged = this.handlerInputChanged.bind(this);
    }

    handlerInputChanged = (ev) => {
        const { target: { value } } = ev;
        const { validateField, onChange } = this.props;
        ev.target.value = value.replace(/[\n\t ]{1,}/gi, '');
        onChange(ev);
        if (/^[0-9]{22}$/gm.exec(ev.target.value)) {
            validateField('cbu');
        } else if (/^[a-zA-Z0-9\.\-]{6,20}$/gm.exec(ev.target.value)) {
            validateField('alias');
        } else {
            validateField(null);
        }
    };

    render() {
        const {
            value, name, label, controlClasses, displayType, helper, helpText, fieldClasses, disabled
        } = this.props;
        const newValue = value || '';
        if (displayType === 'input') {
            return (
                <TextInput
                    value={newValue}
                    name={name}
                    label={label}
                    onChange={(ev) => {
                        this.handlerInputChanged(ev);
                    }}
                    controlClasses={controlClasses}
                    fieldClasses={fieldClasses}
                    displayType={displayType}
                    helper={helper}
                    helpText={helpText}
                    disabled={disabled}
                />
            );
        } else {
            return (
                <span>{newValue}</span>
            );
        }
    }
}

CbuFormat.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    validateField: PropTypes.func,
    controlClasses: PropTypes.string,
    displayType: PropTypes.string,
    helper: PropTypes.string,
    disabled: PropTypes.bool,
};
CbuFormat.defaultProps = {
    label: '',
    onChange: () => {},
    validateField: () => {},
    controlClasses: '',
    helper: '',
    displayType: 'input',
    disabled: false,
};

export default CbuFormat;
