import React, { Fragment } from 'react';
import PropTypes           from 'prop-types';
import './styles.scss';

const Switch = ({
                    name,
                    id,
                    value,
                    defaultValue,
                    onChange,
                    disabled,
                    checked,
                    htmlFor,
                }) => (
    <div className="button-switch">
        <input
            type="checkbox"
            name={name}
            id={id}
            className="switch switch-orange"
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            checked={checked}
            disabled={disabled}
        />
        <label htmlFor={htmlFor} className="lbl-off">Off</label>
        <label htmlFor={htmlFor} className="lbl-on">On</label>
    </div>
);

Switch.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.bool.isRequired,
    defaultValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
    ]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    htmlFor: PropTypes.string,
};

Switch.defaultProps = {
    value: false,
    defaultValue: true,
    onChange: () => {},
    disabled: false,
    checked: false,
};

export default Switch;
