import React, { Fragment } from 'react';
import PropTypes           from 'prop-types';
import { ContentHeader }   from '../index';
import './styles.scss';

/**
 * Created by Feedback Software on 25/10/18.
 * @param headingText is the title of the panel
 * @param children contain the elements inside the panel body
 * @returns {*}
 * @constructor
 */
const Panel = ({ headingText, children }) => (
  <Fragment>
    <div className="Panel">
      <div
        className="columns is-centered"
        style={{ animationDelay: '1s' }}
      >
        <div className="column is-full wow animated flipInX">
          <div className="panel panel-default panel-table shadas">
            {headingText !== '' && (
              <div className="title panel-header bg-panel text-white mb-0">
                {headingText}
              </div>
            )}
            <div className="panel-body">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element, PropTypes.array,
  ]).isRequired,
  headingText: PropTypes.string,
  headingSubText: PropTypes.string,
  titleContentHeader: PropTypes.string,
  viewBalance: PropTypes.bool,
};

Panel.defaultProps = {
  headingText: '',
  headingSubText: '',
  titleContentHeader: '',
  viewBalance: true,
};

export default Panel;
