import React, { Component }      from 'react';
import { Redirect }              from 'react-router-dom';
import { connect }               from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { detect }                from 'detect-browser';
import { compose }               from 'redux';
import { withTranslation }       from 'react-i18next';
import FormLogin                 from './components/FormLogin';
import { authActions }           from '../../../state/ducks/auth';
import { Loading }               from '../../../components';
import { TOAST_CONFIG }          from '../../../config/constants';
import translation               from '../../../i18n';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import {stopTimer} from "../../../util/initTimer";

class SignIn extends Component {

  constructor(props, context) {
    super(props, context);
    const browser = detect();
    this.state = {
      user_type: 'core',
      password: '',
      username: '',
      token_firebase: 'hoasofj',
      device_name: browser.name,
      device_number: browser.version,
      isCaptcha: false,
      isVerified: false,
      requiredUser: false,
      requiredPassword: false,
    };

    this.verifyCallback = this.verifyCallback.bind(this);
    this.callback = this.callback.bind(this);
  }

  componentDidMount() {
    stopTimer();

  }

  handleInputChange = (event) => {
    const {
      target: {
        type, checked, value: targetValue, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    this.setState({
      isVerified: true,
    });

    console.log('recaptchaToken', recaptchaToken);
  };

  callback = () => {
    console.log('Done!!!!');
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.username === '') {
      this.setState({ requiredUser: true });
    } else if (this.state.password === '') {
      this.setState({ requiredPassword: true });
    }  else if (this.state.isVerified !== true && process.env.NODE_ENV !== 'development') {
      this.setState({ isCaptcha: true });
    } else {

      const { signIn, showMessage, t } = this.props;
      const { ...data } = this.state;
      signIn({
        data,
        callback: (response) => {
         if (response.error) {
            toast.dismiss();
            showMessage({
              message: translation.t('userDataIncorrect'),
              config: TOAST_CONFIG.INFO,
            });
          } else if (!('success' in response)) {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        },
      });
    }
    toast.dismiss();
  };

  render() {
    const requiredUser = {
      display: this.state.requiredUser ? 'block' : 'none',
    };
    const requiredPassword = {
      display: this.state.requiredPassword ? 'block' : 'none',
    };
    const isCaptcha = {
      display: this.state.isCaptcha ? 'block' : 'none',
    };
    const viewCaptcha = {
      display: (process.env.NODE_ENV === 'development') ? 'none' : 'block',
    };
    const { auth: { logged, loading }, t } = this.props;

    if (logged === true) {
      return <Redirect to="/dashboard" />;
    }

    console.log('captcha', viewCaptcha)

    return (
      <div className='SignIn'>
        <ToastContainer />
        {loading && <Loading />}
        <section className='card-login'>
          <div>
            <div className='text-center'>
              <img className='image-logo' src='./logo-xx.png' alt='logo' />
            </div>
            <FormLogin
              handleSubmit={this.handleSubmit}
              handleInputChange={this.handleInputChange}
              requiredUser={requiredUser}
              requiredPassword={requiredPassword}
              isCaptcha={isCaptcha}
              viewCaptcha={viewCaptcha}
              verifyCallback={this.verifyCallback}
              callback={this.callback}
            />
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => (
  {
    auth,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withTranslation()
)(SignIn);
