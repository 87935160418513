import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  AmountFormat, ContentHeader, Filter, FormElement, Loading, TableList, TransactionRow,
  ExportExcel
} from '../../../components';
import { initTimer, stopTimer } from '../../../util/initTimer';
import { transactionsActions } from '../../../state/ducks/transactions';
import { TOAST_CONFIG, SIZE_PER_PAGE } from '../../../config/constants';
import {authActions} from "../../../state/ducks/auth";
import { formatDate } from '../../../util';
import './styles.scss';

class AccountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      transactionType: '',
      amountTotal: 0.00,
      page: 0,
      month: '',
      dateStart: new Date(),
      dateEnd: new Date(((new Date()).setDate((new Date()).getDate() + 1))),
    }
  }

  componentDidMount() {
    const { fetchTransactionsTypes } = this.props;
    stopTimer();
    initTimer(this.props);
    fetchTransactionsTypes();
    this.callApiList();
  }

  handleInputChange = (event) => {
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  callApiList = (getExcel = false) => {
    const {
      t,
      fetchListTransactions,
      showMessage,
      transactions: { accountDetail: { countTransactions }, transactionsTypes },
    } = this.props;
    const { id, transactionType, page, dateStart, dateEnd } = this.state;
    const type = transactionsTypes.find(item => item.value === transactionType);

    fetchListTransactions({
      data: {
        pageSize: !getExcel ? SIZE_PER_PAGE : countTransactions,
        page: getExcel ? 0 : page,
        export: getExcel,
        id: id,
        typeTransaction: type!== undefined ? type.id : '',
       date_from: formatDate(dateStart),
       date_to: formatDate(dateEnd),
      }, callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({
      [name]: dateValue,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.onChangePage();
  };

  render() {
    const {
      t,
      transactions: {
        loading,
        transactionsTypes,
        accountDetail: {
          list,
          countTransactions,
        },
      },
    } = this.props;
    const { transactionType, page, dateStart, dateEnd } = this.state;
    return (
      <div>
        {loading && <Loading />}
        <Fragment>
          <div className="wow animated slideInDown">
            <div className="wow animated slideInDown">
              <Filter title='Detalle de operaciones' onSubmit={(ev) => this.onSubmit(ev)}>
                <FormElement
                  name='dateStart'
                  onChange={ev => this.onChangeDate(ev, 'dateStart')}
                  label={t('dateStart')}
                  value={dateStart}
                  typeElement="datepicker"
                  maxDate={dateEnd || new Date()}
                />
                <FormElement
                  name='dateEnd'
                  onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                  value={dateEnd}
                  label={t('dateEnd')}
                  typeElement="datepicker"
                  maxDate={new Date()}
                  minDate={dateStart}
                />
                <FormElement
                  name="transactionType"
                  onChange={this.handleInputChange}
                  value={transactionType}
                  label={t('selectTypeTransactions')}
                  optionsSelect={transactionsTypes}
                  placeholder={t('all')}
                  typeElement="select"
                  controlClasses="select"
                />
              </Filter>
            </div>
            <ContentHeader
              headingRightText='Total de operaciones'
              headingRightSubText={`${countTransactions}`}
            >
              <ExportExcel onclick={() => { this.callApiList(true) }}/>
              <TableList
                tableClasses="floating-table"
                totalElements={parseFloat(countTransactions)}
                onChangePage={this.onChangePage}
                forcePage={page}
                head={(
                  <tr>
                    <th>{t('date')} / {t('hour')}</th>
                    <th>{t('typeOfTransaction')}</th>
                    <th>{t('amount')}</th>
                    <th> {t('status')}</th>
                    <th>{t('actions')}</th>
                  </tr>
                )}
                colNumber={5}
                lengthData={countTransactions !== undefined ? countTransactions : 0}
              >
                {list && (list.map(transactions => (
                  <TransactionRow key={transactions.id} helper='detailReport'>
                    <td>{transactions.transaction_date}</td>
                    <td>{transactions.transaction_name}</td>
                    <td>
                      <AmountFormat
                        name="balance"
                        value={transactions.transaction_amount}
                        currency={transactions.product}
                        displayType="text"
                        allowNegative
                      />
                    </td>
                    <td>{transactions.transaction_status}</td>
                    <td>
                      <Link to={{
                        pathname: '/account-details',
                        state: { transaction: transactions }
                      }}
                      >
                        <button
                          className="button is-outlined is-small">{t('showTransacction')}</button>
                      </Link>
                    </td>
                  </TransactionRow>
                )))}
              </TableList>
            </ContentHeader>
          </div>
        </Fragment>
      </div>
    )
  }
}

AccountList.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchTransactionsTypes: PropTypes.func,
  fetchListTransactions: PropTypes.func.isRequired,
};

AccountList.defaultProps = {
  showMessage: () => {
  },
  fetchTransactionsTypes: () => {},
};

const mapStateToProps = ({ auth, transactions, }) => (
  { auth, transactions }
);
export default compose(
  connect(mapStateToProps, {
    ...authActions,
    ...transactionsActions,
  }),
  withTranslation(),
)(AccountList);
