import React                        from 'react';
import { withTranslation }          from 'react-i18next';
import {DefaultButton,
    Panel, PrimaryButton,
    TableList,
    TransactionRow}                 from '../../../../components';
import PropTypes                    from "prop-types";
import ReactTooltip                 from "react-tooltip";

const SelectPassenger = ({
                             t,
                             handleSelectPassenger,
                             view,
                            passengers,
                             passengerSelect,
                             onChangeView,
                             disabled,
                         }) => {
    return (
        <Panel headingText={t('balanceChargePassengers')}>
                <div className="columns is-centered is-multiline">
                    <div className={`column is-full has-text-centered title-message`}>
                        {t('selectPassenger')}
                    </div>
                    <div className={`column is-full`}>
                        <TableList
                            tableClasses="floating-table"
                            totalElements={passengers.length}
                            hidePagination
                            head={(
                                <tr>
                                    <th className="checkItem" />
                                    <th>{t('name')}</th>
                                    <th>{t('lastName')}</th>
                                    <th>{t('username')}</th>
                                </tr>
                            )}
                            colNumber={4}
                            lengthData={passengers.length}
                        >
                            {passengers && (passengers.map((passenger, index) => (
                                <TransactionRow
                                    key={passenger.id}
                                    id={index}
                                    helper={t('detailReport')}
                                >
                                    <td className="checkItem">
                                        <i
                                            id={`icon-${passenger.id}`}
                                            className="is-hidden has-text-danger fas fa-exclamation-triangle icon"
                                            data-for="tooltip"
                                            data-tip=""
                                        >
                                            <ReactTooltip
                                                id="tooltip"
                                                type="error"
                                                effect="solid"
                                            >
                                                {t('selectPassenger')}
                                            </ReactTooltip>
                                        </i> {console.log('index--', index)}
                                        <input
                                            type="checkbox"
                                            checked={passengerSelect !== undefined && passenger.id === passengerSelect.id ? true : false}
                                            onChange={(ev) => {
                                                handleSelectPassenger(ev, index);
                                            }}
                                            value={passenger.id}
                                            name="check"
                                        />
                                    </td>
                                    <td>{passenger.name}</td>
                                    <td>{passenger.lastname}</td>
                                    <td>{passenger.username}</td>

                                </TransactionRow>
                            ))
                            )}
                        </TableList>
                    </div>
                    <div className="column is-grouped-centered field is-grouped is-space-evenly">
                        <DefaultButton
                            name={t('return')}
                            type="button"
                            onClick={() => onChangeView()}
                        />
                        <PrimaryButton
                            name={t('continue')}
                            disabled={disabled}
                            type="submit"
                            onClick={() => onChangeView('third')}
                        />

                    </div>
                </div>

        </Panel>
    )
}

SelectPassenger.propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    onChangeInput: PropTypes.func,
    onChangeView: PropTypes.func,
    view: PropTypes.string,


};

SelectPassenger.defaultProps = {
    onSubmit: null,
    onChangeInput: null,
    onChangeView: null,
    view: 'second',

};


export default withTranslation()(SelectPassenger);