import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { authActions } from '../../state/ducks/auth';
import { transactionsActions } from '../../state/ducks/transactions';
import { Loading, } from '../../components';
import { FormQr, DetailQr } from './components';
import { convertDecimalPoint } from '../../util';
import { initTimer, stopTimer } from '../../util/initTimer';
import { TOAST_CONFIG } from '../../config/constants';

class ChargeQr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'first',
      amount: 0,
      concept: '',
    }
  }

  handleChangeView = (step = 'first') => {
    this.setState({ step });
  };

  validateEmptyFields = () => {
    const { step, amount } = this.state;
    if (step === 'first') {
      const formatAmount = parseFloat(convertDecimalPoint(amount));
      return (formatAmount <= 0);
    }
    return false;
  };

  handleInputChange = (e) => {
    const { target: { value: targetValue, name } } = e;
    this.setState({ [name]: targetValue });
  };

  handleAmountChange = (e) => {
    const { target: { value: targetValue, name } } = e;
    let str = targetValue
    let amount = str.trim();
    amount = amount.replace(/^0+(?!$)/g, '');

    if (amount.charAt(0) == ',') {
      amount = '0'.concat(amount);
    }

    this.setState({ [name]: amount });
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { t, showMessage, fetchChargeQr } = this.props;
    const { amount, concept } = this.state;

    fetchChargeQr ({
      data: {
        amount: parseFloat(amount.toString().replace(/\./g, '').replace(',', '.')),
        concept: concept
      }, callback: ({ success }) => {
        if (success) {
          const options = {
            customUI: () => (
              <div className="box-alert columns is-multiline">
                <div className="column is-full has-text-centered">
                  <p>{t('processingRequest')}</p>
                </div>
                <div className="column has-text-centered">
                  <button className="button is-outlined" onClick={(e) => this.cancelPaymentQr(e)}>
                    {t('cancelPayment')}
                  </button>
                </div>
              </div>
            ),
            closeOnEscape: false,
            closeOnClickOutside: false,
          };
          return (confirmAlert(options));
        }
      },
    })
    this.intervalID = setInterval(this.getStatusChargeQr, 10000);
  }

  cancelPaymentQr = () => {
    const { t, showMessage, cancelChargeQr } = this.props;
    cancelChargeQr ({
      callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        } else if (response.success) {
          toast.dismiss();
          showMessage({
            message: response.data.message,
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    })
    setTimeout (() => {
      window.location.reload();
    }, 3000);
  }

  getStatusChargeQr = () => {
    const { t, showMessage, fetchStatusChargeQr } = this.props;
    fetchStatusChargeQr ({
      callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        } else if (response.data.statusTxt === 'Cancelado') {
          toast.dismiss();
          showMessage({
            message: response.data.message,
            config: TOAST_CONFIG.ERROR,
          });
          setTimeout (() => {
            this.setState({ amount: 0, concept: ''});
            this.handleChangeView('first');
          }, 1000);
        } else if (response.data.statusTxt === 'Expirado') {
          toast.dismiss();
          showMessage({
            message: response.data.message,
            config: TOAST_CONFIG.ERROR,
          });
          setTimeout (() => {
            this.setState({ amount: 0, concept: ''});
            window.location.reload();
          }, 1000);
        } else if (response.data.statusTxt === 'Pagado') {
          this.handleChangeView('second');
          clearInterval(this.intervalID);
          const options = {
            customUI: ({ onClose }) => (
              <div className="box-alert columns is-multiline">
                <div className="column is-full has-text-centered">
                  <p>{t('paymentSuccess')}</p>
                </div>
                <div className="column has-text-centered">
                  <button className="button is-outlined" onClick={() => onClose()}>
                    {t('close')}
                  </button>
                </div>
              </div>
            ),
            closeOnEscape: false,
            closeOnClickOutside: false,
          };
          return (confirmAlert(options));
        }
      }
    });
  }

  finishPaymentQr = (e) => {
    e.preventDefault();
    window.location.reload();
  }

  render () {
    const { t, auth: { loading } } = this.props;
    const { step, amount, concept } = this.state;
    const disabledForm = this.validateEmptyFields();

    if (step === 'first') {
      stopTimer();
      initTimer(this.props);
      return (
        <section>
          { loading && <Loading /> }
          <FormQr
            formData={{ ...this.state }}
            disabled={disabledForm}
            handleInputChange={this.handleInputChange}
            handleAmountChange={this.handleAmountChange}
            onSubmit={this.onSubmit}
            amount={amount}
            currency={'AR$'}
            concept={concept}
          />
        </section>
      );
    }
    if (step === 'second') {
      stopTimer();
      initTimer(this.props);
      return (
        <section>
          { loading && <Loading /> }
          <DetailQr
            amount={amount}
            currency={'AR$'}
            onFinish={this.finishPaymentQr}
          />
        </section>
      );
    }
  }
}

ChargeQr.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.shape({}).isRequired,
  transactions: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ auth, transactions }) => (
  { auth, transactions }
);

export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
    ...authActions,
  }), withTranslation())(ChargeQr);
